import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// Async thunks
export const fetchDashboardStats = createAsyncThunk(
  'admin/fetchDashboardStats',
  async () => {
    const response = await axios.get('/api/admin/dashboard/stats');
    return response.data;
  }
);

export const fetchUsers = createAsyncThunk(
  'admin/fetchUsers',
  async ({ page = 1, limit = 10, search = '' }) => {
    const response = await axios.get(`/api/admin/users?page=${page}&limit=${limit}&search=${search}`);
    return response.data;
  }
);

export const fetchUserDetails = createAsyncThunk(
  'admin/fetchUserDetails',
  async (userId) => {
    const response = await axios.get(`/api/admin/users/${userId}`);
    return response.data;
  }
);

export const fetchApplications = createAsyncThunk(
  'admin/fetchApplications',
  async ({ page = 1, limit = 10, search = '' } = {}) => {
    const response = await axios.get(`/api/admin/applications?page=${page}&limit=${limit}&search=${search}`);
    return response.data;
  }
);

export const fetchApplicationDetails = createAsyncThunk(
  'admin/fetchApplicationDetails',
  async (id) => {
    const response = await axios.get(`/api/admin/applications/${id}`);
    return response.data;
  }
);

export const approveApplication = createAsyncThunk(
  'admin/approveApplication',
  async (id) => {
    const response = await axios.post(`/api/admin/applications/${id}/approve`);
    return response.data;
  }
);

export const rejectApplication = createAsyncThunk(
  'admin/rejectApplication',
  async ({ id, reason }) => {
    const response = await axios.post(`/api/admin/applications/${id}/reject`, { reason });
    return response.data;
  }
);

const initialState = {
  dashboardStats: null,
  users: [],
  selectedUser: null,
  applications: [],
  selectedApplication: null,
  loading: false,
  error: null,
  pagination: {
    total: 0,
    page: 1,
    totalPages: 1
  }
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearUserDetails: (state) => {
      state.selectedUser = null;
    },
    clearApplicationDetails: (state) => {
      state.selectedApplication = null;
    }
  },
  extraReducers: (builder) => {
    // Dashboard Stats
    builder
      .addCase(fetchDashboardStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardStats.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardStats = action.payload.data;
      })
      .addCase(fetchDashboardStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    // Users
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    // User Details
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedUser = action.payload.data;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    // Applications
    builder
      .addCase(fetchApplications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.applications = action.payload.applications;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchApplications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    // Application Details
    builder
      .addCase(fetchApplicationDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchApplicationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedApplication = action.payload.data;
      })
      .addCase(fetchApplicationDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    // Approve Application
    builder
      .addCase(approveApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveApplication.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(approveApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

    // Reject Application
    builder
      .addCase(rejectApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rejectApplication.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(rejectApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { clearUserDetails, clearApplicationDetails } = adminSlice.actions;
export default adminSlice.reducer;
