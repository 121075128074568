import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

const initialState = {
  appointments: [],
  selectedAppointment: null,
  loading: false,
  error: null,
  success: false
};

// Kullanıcının randevularını getir
export const fetchUserAppointments = createAsyncThunk(
  'appointments/fetchUserAppointments',
  async (_, { rejectWithValue }) => {
    try {
      console.log('\n=== Kullanıcı Randevuları İsteği Gönderiliyor ===');
      const response = await axiosInstance.get('/api/appointments');
      console.log('API Yanıtı:', response.data);
      console.log('====================\n');
      return response.data;
    } catch (error) {
      console.error('\n=== Randevu Yükleme Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('Status:', error.response?.status);
      console.error('====================\n');
      return rejectWithValue(error.response?.data?.message || 'Randevular yüklenirken bir hata oluştu');
    }
  }
);

// Psikoloğun randevularını getir
export const fetchPsychologistAppointments = createAsyncThunk(
  'appointments/fetchPsychologistAppointments',
  async (_, { rejectWithValue }) => {
    try {
      console.log('\n=== Psikolog Randevuları İsteği Gönderiliyor ===');
      const response = await axiosInstance.get('/api/appointments/psychologist');
      console.log('API Yanıtı:', response.data);
      console.log('====================\n');
      return response.data;
    } catch (error) {
      console.error('\n=== Randevu Yükleme Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('Status:', error.response?.status);
      console.error('====================\n');
      return rejectWithValue(error.response?.data?.message || 'Randevular yüklenirken bir hata oluştu');
    }
  }
);

// Yeni randevu oluştur
export const createAppointment = createAsyncThunk(
  'appointments/create',
  async (appointmentData, { rejectWithValue }) => {
    try {
      console.log('\n=== Randevu Oluşturma İsteği Gönderiliyor ===');
      console.log('Data:', appointmentData);
      const response = await axiosInstance.post('/api/appointments', appointmentData);
      console.log('API Yanıtı:', response.data);
      console.log('====================\n');
      return response.data;
    } catch (error) {
      console.error('\n=== Randevu Oluşturma Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('Status:', error.response?.status);
      console.error('====================\n');
      return rejectWithValue(error.response?.data?.message || 'Randevu oluşturulurken bir hata oluştu');
    }
  }
);

// Randevu durumu güncelle (psikolog)
export const updateAppointmentStatusByPsychologist = createAsyncThunk(
  'appointments/updateStatusByPsychologist',
  async ({ appointmentId, status }, { rejectWithValue }) => {
    try {
      console.log('\n=== Psikolog Randevu Durumu Güncelleme İsteği ===');
      console.log('ID:', appointmentId);
      console.log('Status:', status);
      console.log('====================\n');

      const response = await axiosInstance.patch(`/api/appointments/${appointmentId}/status`, { status });
      return response.data;
    } catch (error) {
      console.error('\n=== Psikolog Randevu Durumu Güncelleme Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('Status:', error.response?.status);
      console.error('====================\n');
      return rejectWithValue(error.response?.data?.message || 'Randevu durumu güncellenirken bir hata oluştu');
    }
  }
);

// Alternatif tarih öner (psikolog)
export const suggestAlternativeDates = createAsyncThunk(
  'appointments/suggestAlternative',
  async ({ appointmentId, dates }, { rejectWithValue }) => {
    try {
      console.log('\n=== Alternatif Tarih Önerme İsteği ===');
      console.log('ID:', appointmentId);
      console.log('Dates:', dates);
      
      const response = await axiosInstance.post(`/api/appointments/${appointmentId}/suggest`, { dates });
      console.log('API Yanıtı:', response.data);
      console.log('====================\n');
      return response.data;
    } catch (error) {
      console.error('\n=== Alternatif Tarih Önerme Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('Status:', error.response?.status);
      console.error('====================\n');
      return rejectWithValue(error.response?.data?.message || 'Alternatif tarihler önerilirken bir hata oluştu');
    }
  }
);

// Alternatif tarih kabul et (kullanıcı)
export const acceptAlternativeDate = createAsyncThunk(
  'appointments/acceptAlternative',
  async ({ appointmentId, date }, { rejectWithValue }) => {
    try {
      console.log('\n=== Alternatif Tarih Kabul İsteği ===');
      console.log('ID:', appointmentId);
      console.log('Date:', date);
      
      const response = await axiosInstance.post(`/api/appointments/${appointmentId}/accept-alternative`, { date });
      console.log('API Yanıtı:', response.data);
      console.log('====================\n');
      return response.data;
    } catch (error) {
      console.error('\n=== Alternatif Tarih Kabul Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('Status:', error.response?.status);
      console.error('====================\n');
      return rejectWithValue(error.response?.data?.message || 'Alternatif tarih kabul edilirken bir hata oluştu');
    }
  }
);

// Randevu güncelle
export const updateAppointment = createAsyncThunk(
  'appointments/update',
  async ({ id, appointmentData }, { rejectWithValue }) => {
    try {
      console.log('\n=== Randevu Güncelleme İsteği Gönderiliyor ===');
      console.log('ID:', id);
      console.log('Data:', appointmentData);
      
      let endpoint;
      if (appointmentData.date && !appointmentData.status) {
        // Alternatif tarih kabul etme
        endpoint = `/api/appointments/${id}/accept-alternative`;
      } else {
        // Normal durum güncellemesi
        endpoint = `/api/appointments/${id}/status`;
      }
      
      const response = await axiosInstance.put(endpoint, appointmentData);
      console.log('API Yanıtı:', response.data);
      console.log('====================\n');
      return response.data;
    } catch (error) {
      console.error('\n=== Randevu Güncelleme Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('Status:', error.response?.status);
      console.error('====================\n');
      return rejectWithValue(error.response?.data?.message || 'Randevu güncellenirken bir hata oluştu');
    }
  }
);

// Randevu iptal et
export const cancelAppointment = createAsyncThunk(
  'appointments/delete',
  async (id, { rejectWithValue }) => {
    try {
      console.log('\n=== Randevu İptal İsteği Gönderiliyor ===');
      console.log('ID:', id);
      const response = await axiosInstance.put(`/api/appointments/delete/${id}`);
      console.log('API Yanıtı:', response.data);
      console.log('====================\n');
      return id;
    } catch (error) {
      console.error('\n=== Randevu İptal Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('Status:', error.response?.status);
      console.error('====================\n');
      return rejectWithValue(error.response?.data?.message || 'Randevu iptal edilirken bir hata oluştu');
    }
  }
);

// Seans başlat
export const startSession = createAsyncThunk(
  'appointments/startSession',
  async (appointmentId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/api/appointments/${appointmentId}/start-session`);
      
      if (response.data.success) {
        const { redirectUrl } = response.data.data;
        // Yönlendirme URL'ini döndür
        return { ...response.data, redirectUrl };
      }
      
      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const appointmentSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    clearAppointments: (state) => {
      state.appointments = [];
      state.selectedAppointment = null;
      state.error = null;
      state.success = false;
    },
    clearAppointmentStatus: (state) => {
      state.error = null;
      state.success = false;
    },
    setSelectedAppointment: (state, action) => {
      state.selectedAppointment = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearSuccess: (state) => {
      state.success = false;
    }
  },
  extraReducers: (builder) => {
    builder
      // Kullanıcı Randevuları
      .addCase(fetchUserAppointments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserAppointments.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload.data;
        state.error = null;
      })
      .addCase(fetchUserAppointments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Randevular yüklenirken bir hata oluştu';
      })

      // Psikolog Randevuları
      .addCase(fetchPsychologistAppointments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPsychologistAppointments.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload.data;
        state.error = null;
      })
      .addCase(fetchPsychologistAppointments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Randevular yüklenirken bir hata oluştu';
      })

      // Randevu Oluşturma
      .addCase(createAppointment.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(createAppointment.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments.push(action.payload);
        state.error = null;
        state.success = true;
      })
      .addCase(createAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Randevu oluşturulurken bir hata oluştu';
        state.success = false;
      })

      // Randevu Durumu Güncelleme
      .addCase(updateAppointmentStatusByPsychologist.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(updateAppointmentStatusByPsychologist.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.appointments = state.appointments.map(appointment =>
          appointment._id === action.payload.data._id ? action.payload.data : appointment
        );
      })
      .addCase(updateAppointmentStatusByPsychologist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Alternatif Tarih Önerme
      .addCase(suggestAlternativeDates.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(suggestAlternativeDates.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.appointments = state.appointments.map(appointment =>
          appointment._id === action.payload.data._id ? action.payload.data : appointment
        );
      })
      .addCase(suggestAlternativeDates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Alternatif Tarih Kabul Etme
      .addCase(acceptAlternativeDate.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(acceptAlternativeDate.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.appointments = state.appointments.map(appointment =>
          appointment._id === action.payload.data._id ? action.payload.data : appointment
        );
      })
      .addCase(acceptAlternativeDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Randevu Güncelleme
      .addCase(updateAppointment.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(updateAppointment.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = state.appointments.map(apt => 
          apt._id === action.payload._id ? action.payload : apt
        );
        state.error = null;
        state.success = true;
      })
      .addCase(updateAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Randevu güncellenirken bir hata oluştu';
        state.success = false;
      })

      // Randevu İptal
      .addCase(cancelAppointment.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(cancelAppointment.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = state.appointments.filter(apt => apt._id !== action.payload);
        state.error = null;
        state.success = true;
      })
      .addCase(cancelAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Randevu iptal edilirken bir hata oluştu';
        state.success = false;
      })

      // Seans Başlat
      .addCase(startSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(startSession.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.appointments.findIndex(
          (apt) => apt._id === action.payload.data._id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload.data;
        }
      })
      .addCase(startSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Seans başlatılırken bir hata oluştu';
      });
  }
});

export const { 
  clearAppointments, 
  clearAppointmentStatus, 
  setSelectedAppointment, 
  clearError, 
  clearSuccess 
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
