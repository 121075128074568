import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { IS_DEVELOPMENT } from '../../config';

const initialState = {
  specialties: [],
  loading: false,
  error: null,
  lastFetch: null
};

// Tüm uzmanlık alanlarını getir
export const getAllSpecialties = createAsyncThunk(
  'specialty/getAll',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const lastFetch = state.specialty.lastFetch;
      const now = Date.now();
      
      // Son 5 dakika içinde veri çekildiyse ve veriler varsa, tekrar çekme
      if (lastFetch && now - lastFetch < 5 * 60 * 1000 && state.specialty.specialties.length > 0) {
        return state.specialty.specialties;
      }

      const response = await axiosInstance.get('/api/specialties');
      
      if (IS_DEVELOPMENT) {
        console.log('[getAllSpecialties] Response:', response.data);
      }
      
      if (!response.data?.data || !Array.isArray(response.data.data)) {
        throw new Error('Geçersiz API yanıtı: Uzmanlık alanları dizi formatında değil');
      }
      
      return response.data.data;
    } catch (error) {
      if (IS_DEVELOPMENT) {
        console.error('[getAllSpecialties] Error:', error);
      }
      return rejectWithValue(error.response?.data || { 
        message: 'Uzmanlık alanları getirilemedi',
        error: error.message 
      });
    }
  }
);

const specialtySlice = createSlice({
  name: 'specialty',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSpecialties.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllSpecialties.fulfilled, (state, action) => {
        state.loading = false;
        state.specialties = action.payload;
        state.lastFetch = Date.now();
      })
      .addCase(getAllSpecialties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Bir hata oluştu';
        if (IS_DEVELOPMENT) {
          console.error('[specialtySlice] Error:', action.payload);
        }
      });
  }
});

export const { clearError } = specialtySlice.actions;

export default specialtySlice.reducer;
