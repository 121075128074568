import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Stack,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Avatar,
  IconButton,
  ButtonGroup,
  CircularProgress,
  Alert,
  TableContainer,
  Paper,
  Pagination,
  useTheme
} from '@mui/material';
import {
  Search as SearchIcon,
  Visibility as ViewIcon
} from '@mui/icons-material';
import { fetchUsers } from '../../store/slices/adminSlice';
import moment from 'moment';
import 'moment/locale/tr';
moment.locale('tr');

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { users, loading, error, pagination } = useSelector((state) => ({
    users: state.admin.users || [],
    loading: state.admin.loading,
    error: state.admin.error,
    pagination: state.admin.pagination || {}
  }));

  useEffect(() => {
    dispatch(fetchUsers({ page: currentPage, search: searchTerm }));
  }, [dispatch, currentPage, searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleViewProfile = (userId) => {
    navigate(`/admin/users/${userId}`);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const getUserTypeChip = (userType) => {
    let color = 'default';
    let label = 'Kullanıcı';

    switch (userType) {
      case 'admin':
        color = 'error';
        label = 'Admin';
        break;
      case 'psychologist':
        color = 'primary';
        label = 'Psikolog';
        break;
      case 'user':
        color = 'success';
        label = 'Kullanıcı';
        break;
      default:
        break;
    }

    return <Chip label={label} color={color} size="small" />;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        {error}
      </Alert>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Kullanıcılar
      </Typography>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack spacing={3}>
            <TextField
              fullWidth
              placeholder="Kullanıcı ara..."
              value={searchTerm}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Stack>
        </CardContent>
      </Card>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Kullanıcı</TableCell>
              <TableCell>E-posta</TableCell>
              <TableCell>Tip</TableCell>
              <TableCell>Kayıt Tarihi</TableCell>
              <TableCell>Son Giriş</TableCell>
              <TableCell>İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={user.avatar} alt={user.name}>
                      {user.name?.charAt(0)}
                    </Avatar>
                    <Typography variant="body2">
                      {user.name}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{getUserTypeChip(user.type)}</TableCell>
                <TableCell>
                  {moment(user.createdAt).format('LLL')}
                </TableCell>
                <TableCell>
                  {user.lastLogin ? moment(user.lastLogin).fromNow() : 'Hiç giriş yapmadı'}
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => handleViewProfile(user._id)}
                  >
                    <ViewIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination.totalPages > 1 && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{ mt: 3 }}
        >
          <Pagination
            count={pagination.totalPages}
            page={currentPage}
            onChange={(event, value) => handlePageChange(value)}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Stack>
      )}
    </Box>
  );
};

export default Users;
