import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../store/slices/authSlice';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useChat } from '../contexts/ChatContext';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { socket, unreadCount } = useWebSocket();
  const { conversations } = useChat();
  const [unreadMessages, setUnreadMessages] = useState(0);

  // Mobil menüyü kapat
  const closeMobileMenu = () => {
    setIsOpen(false);
  };

  // Sayfa değiştiğinde menüleri kapat
  useEffect(() => {
    setIsOpen(false);
    setIsProfileOpen(false);
  }, [location.pathname]);

  // Socket olaylarını dinle
  useEffect(() => {
    if (socket) {
      // Genel bildirimler için
      socket.on('notification', (notification) => {
        if (notificationRef.current) {
          notificationRef.current.classList.add('animate-bounce');
          setTimeout(() => {
            notificationRef.current.classList.remove('animate-bounce');
          }, 2000);
        }
      });

      // Mesaj bildirimleri için
      socket.on('messageNotification', (data) => {
        // Mesaj sayfasında değilsek veya aktif konuşmada değilse sayacı artır
        setUnreadMessages(prev => prev + 1);
      });

      // Mesaj okundu bildirimi için
      socket.on('messagesRead', (data) => {
        setUnreadMessages(prev => Math.max(0, prev - 1));
      });
    }

    return () => {
      if (socket) {
        socket.off('notification');
        socket.off('messageNotification');
        socket.off('messagesRead');
      }
    };
  }, [socket]);

  useEffect(() => {
    // Bildirim sayısı değiştiğinde animasyon ekle
    if (notificationRef.current && unreadCount > 0) {
      notificationRef.current.classList.add('notification-animation');
      setTimeout(() => {
        if (notificationRef.current) {
          notificationRef.current.classList.remove('notification-animation');
        }
      }, 1000);
    }
  }, [unreadCount]);

  // Okunmamış mesaj sayısını hesapla
  useEffect(() => {
    if (!conversations) return;

    const count = conversations.reduce((acc, conv) => {
      const lastMessage = conv.lastMessage;
      if (lastMessage && !lastMessage.read && lastMessage.sender._id !== user?._id) {
        return acc + 1;
      }
      return acc;
    }, 0);

    setUnreadMessages(count);
  }, [conversations, user]);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const isActive = (path) => {
    return location.pathname === path ? 'border-primary-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-primary-500 hover:text-gray-700';
  };

  const getProfileImageUrl = (imagePath) => {
    if (!imagePath) return '/default-avatar.png';
    const baseUrl = process.env.REACT_APP_API_URL || window.location.origin;
    return `${baseUrl}/api/uploads/${imagePath}`;
  };

  return (
    <nav className="bg-white shadow fixed top-0 w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="text-2xl font-bold text-primary-600">
                Terapiyo
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              <Link to="/" className={`${isActive('/')} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                Ana Sayfa
              </Link>
              <Link to="/psychologists" className={`${isActive('/psychologists')} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                Psikologlar
              </Link>
              <Link to="/about" className={`${isActive('/about')} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                Hakkımızda
              </Link>
              <Link to="/contact" className={`${isActive('/contact')} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                İletişim
              </Link>
              {isAuthenticated && (
                <>
                  {(user?.role === 'psychologist' || user?.psychologist) && (
                    <>
                      <Link to="/psychologist/appointments" className={`${isActive('/psychologist/appointments')} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                        Randevu Yönetimi
                      </Link>
                      <Link to="/psychologist/content" className={`${isActive('/psychologist/content')} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                        İçerik Yönetimi
                      </Link>
                    </>
                  )}
                  <Link to="/appointments" className={`${isActive('/appointments')} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                    Randevularım
                  </Link>
                </>
              )}
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            {isAuthenticated && (
              <>
                {/* Mesaj ikonu */}
                <div className="relative mr-4">
                  <button
                    className="p-2 text-gray-600 hover:text-gray-900 focus:outline-none"
                    onClick={() => navigate('/messages')}
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"></path>
                    </svg>
                    {unreadMessages > 0 && (
                      <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full">
                        {unreadMessages}
                      </span>
                    )}
                  </button>
                </div>

                {/* Zil ikonu */}
                <div className="relative">
                  <button
                    ref={notificationRef}
                    className="p-2 text-gray-600 hover:text-gray-900 focus:outline-none notification-bell"
                    onClick={() => navigate('/notifications')}
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
                    </svg>
                    {unreadCount > 0 && (
                      <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full">
                        {unreadCount}
                      </span>
                    )}
                  </button>
                </div>
                <div className="ml-3 relative">
                  <div>
                    <button
                      onClick={() => setIsProfileOpen(!isProfileOpen)}
                      className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      id="user-menu"
                      aria-haspopup="true"
                    >
                      <span className="sr-only">Profil menüsünü aç</span>
                      <img
                        className="h-8 w-8 rounded-full object-cover"
                        src={getProfileImageUrl(user?.profileImage) || '/assets/images/default-avatar.png'}
                        alt={user?.user?.name || 'Profil'}
                      />
                    </button>
                  </div>
                  {isProfileOpen && (
                    <div
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <Link
                        to="/profile"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        onClick={() => setIsProfileOpen(false)}
                      >
                        Profilim
                      </Link>
                      <button
                        onClick={() => {
                          handleLogout();
                          setIsProfileOpen(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Çıkış Yap
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
            {!isAuthenticated && (
              <div className="flex items-center space-x-4">
                <Link
                  to="/login"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Giriş Yap
                </Link>
                <Link
                  to="/register"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-primary-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Kayıt Ol
                </Link>
              </div>
            )}
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
              aria-expanded="false"
            >
              <span className="sr-only">Ana menüyü aç</span>
              <svg
                className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg
                className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobil menü */}
      {isOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            <Link
              to="/"
              className={`${isActive('/')} block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
              onClick={closeMobileMenu}
            >
              Ana Sayfa
            </Link>
            <Link
              to="/psychologists"
              className={`${isActive('/psychologists')} block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
              onClick={closeMobileMenu}
            >
              Psikologlar
            </Link>
            <Link
              to="/about"
              className={`${isActive('/about')} block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
              onClick={closeMobileMenu}
            >
              Hakkımızda
            </Link>
            <Link
              to="/contact"
              className={`${isActive('/contact')} block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
              onClick={closeMobileMenu}
            >
              İletişim
            </Link>
            {isAuthenticated && (
              <>
                {(user?.role === 'psychologist' || user?.psychologist) && (
                  <>
                    <Link
                      to="/psychologist/appointments"
                      className={`${isActive('/psychologist/appointments')} block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                      onClick={closeMobileMenu}
                    >
                      Randevu Yönetimi
                    </Link>
                    <Link
                      to="/psychologist/content"
                      className={`${isActive('/psychologist/content')} block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                      onClick={closeMobileMenu}
                    >
                      İçerik Yönetimi
                    </Link>
                  </>
                )}
                <Link
                  to="/appointments"
                  className={`${isActive('/appointments')} block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                  onClick={closeMobileMenu}
                >
                  Randevularım
                </Link>
                <Link
                  to="/messages"
                  className={`${isActive('/messages')} block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                  onClick={closeMobileMenu}
                >
                  Mesajlar {unreadMessages > 0 && `(${unreadMessages})`}
                </Link>
              </>
            )}
          </div>
          {isAuthenticated ? (
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={getProfileImageUrl(user?.profileImage)}
                    alt={user?.name}
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">{user?.name}</div>
                  <div className="text-sm font-medium text-gray-500">{user?.email}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <Link
                  to="/profile"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  onClick={closeMobileMenu}
                >
                  Profilim
                </Link>
                <button
                  onClick={() => {
                    handleLogout();
                    closeMobileMenu();
                  }}
                  className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                >
                  Çıkış Yap
                </button>
              </div>
            </div>
          ) : (
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="space-y-1">
                <Link
                  to="/login"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  onClick={closeMobileMenu}
                >
                  Giriş Yap
                </Link>
                <Link
                  to="/register"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  onClick={closeMobileMenu}
                >
                  Kayıt Ol
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
