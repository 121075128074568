import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Box,
  Switch,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
  Typography,
  Grid,
  Collapse,
  IconButton,
  Stack,
  useTheme,
  Paper,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  ExpandMore as ChevronDownIcon,
  ExpandLess as ChevronUpIcon
} from '@mui/icons-material';
import axios from '../utils/axios';

const DAYS_OF_WEEK = [
  { id: 1, name: 'Pazartesi' },
  { id: 2, name: 'Salı' },
  { id: 3, name: 'Çarşamba' },
  { id: 4, name: 'Perşembe' },
  { id: 5, name: 'Cuma' },
  { id: 6, name: 'Cumartesi' },
  { id: 7, name: 'Pazar' }
];

const StyledTimeInput = styled('input')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main
  }
}));

const generateTimeSlots = (startTime, endTime) => {
  const slots = [];
  const start = new Date(`2000-01-01T${startTime}`);
  const end = new Date(`2000-01-01T${endTime}`);
  
  let current = new Date(start);
  while (current <= end) {
    slots.push({
      time: current.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' }),
      isAvailable: true
    });
    current.setMinutes(current.getMinutes() + 50);
  }
  
  return slots;
};

const PsychologistSchedule = () => {
  const { token, user } = useSelector((state) => state.auth);
  const [schedule, setSchedule] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [expandedDay, setExpandedDay] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    if (!token || !user || user.role !== 'psychologist') {
      setError('Bu sayfaya erişim yetkiniz yok');
      setLoading(false);
      return;
    }
    fetchSchedule();
  }, [token, user]);

  const fetchSchedule = async () => {
    try {
      const response = await axios.get('/api/psychologists/my-schedule');
      
      if (!response.data.schedule) {
        const defaultSchedule = {
          details: DAYS_OF_WEEK.map(day => ({
            dayOfWeek: day.id,
            startTime: '09:00',
            endTime: '17:00',
            isAvailable: false,
            slots: generateTimeSlots('09:00', '17:00')
          }))
        };
        setSchedule(defaultSchedule);
      } else {
        const backendSchedule = response.data.schedule;
        const updatedSchedule = {
          ...backendSchedule,
          details: DAYS_OF_WEEK.map(day => {
            const existingDay = backendSchedule.details.find(d => d.dayOfWeek === day.id);

            if (existingDay) {
              return {
                ...existingDay,
                dayOfWeek: day.id,
                slots: existingDay.slots && Array.isArray(existingDay.slots)
                  ? existingDay.slots
                  : generateTimeSlots(existingDay.startTime, existingDay.endTime)
              };
            }

            return {
              dayOfWeek: day.id,
              startTime: '09:00',
              endTime: '17:00',
              isAvailable: false,
              slots: generateTimeSlots('09:00', '17:00')
            };
          })
        };
        setSchedule(updatedSchedule);
      }
      setLoading(false);
    } catch (err) {
      console.error('Program yüklenirken hata:', err);
      setError('Program yüklenirken bir hata oluştu');
      setLoading(false);
    }
  };

  const handleDayToggle = (dayId) => {
    setSchedule(prev => ({
      ...prev,
      details: prev.details.map(day =>
        day.dayOfWeek === dayId
          ? { ...day, isAvailable: !day.isAvailable }
          : day
      )
    }));
  };

  const handleTimeChange = (dayId, field, value) => {
    setSchedule(prev => ({
      ...prev,
      details: prev.details.map(day =>
        day.dayOfWeek === dayId
          ? {
              ...day,
              [field]: value,
              slots: generateTimeSlots(
                field === 'startTime' ? value : day.startTime,
                field === 'endTime' ? value : day.endTime
              )
            }
          : day
      )
    }));
  };

  const handleSlotToggle = async (dayId, slotTime) => {
    setSelectedSlot({ dayId, slotTime });
    setDialogOpen(true);
  };

  const handleConfirmJoin = async () => {
    if (!selectedSlot) return;

    try {
      const { dayId, slotTime } = selectedSlot;
      
      // Randevu onaylandı, odaya katıl
      setSchedule(prev => ({
        ...prev,
        details: prev.details.map(day =>
          day.dayOfWeek === dayId
            ? {
                ...day,
                slots: day.slots.map(slot =>
                  slot.time === slotTime
                    ? { ...slot, isAvailable: !slot.isAvailable }
                    : slot
                )
              }
            : day
        )
      }));
      
      setDialogOpen(false);
      // Odaya yönlendir
      window.location.href = `/videocall/${dayId}-${slotTime}`;
    } catch (error) {
      console.error('Seans başlatılırken hata:', error);
      setError('Seans başlatılırken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  const handleExpandClick = (dayId) => {
    setExpandedDay(expandedDay === dayId ? null : dayId);
  };

  const handleSave = async () => {
    try {
      const formattedSchedule = {
        details: schedule.details.map(day => ({
          dayOfWeek: day.dayOfWeek,
          startTime: day.startTime,
          endTime: day.endTime,
          isAvailable: day.isAvailable,
          slots: day.slots.map(slot => ({
            time: slot.time,
            isAvailable: slot.isAvailable
          }))
        })),
        isActive: true
      };

      const response = await axios.put('/api/psychologists/my-schedule', {
        schedule: formattedSchedule
      });

      if (response.data.status === 'success') {
        setSuccess(true);
        await fetchSchedule();
        setTimeout(() => setSuccess(false), 3000);
      } else {
        setError(response.data.message || 'Program kaydedilirken bir hata oluştu');
        setTimeout(() => setError(null), 3000);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Program kaydedilirken bir hata oluştu');
      setTimeout(() => setError(null), 3000);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress size={40} />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Alert severity="error">
          {error}
        </Alert>
      </Container>
    );
  }

  if (!schedule) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Alert severity="warning">
          Program bilgisi bulunamadı
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Program Yönetimi</Typography>
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Program başarıyla kaydedildi!
        </Alert>
      )}
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
        <Stack spacing={2}>
          {DAYS_OF_WEEK.map((day) => {
            const daySchedule = schedule?.details?.find(d => d.dayOfWeek === day.id);
            if (!daySchedule) return null;

            return (
              <Paper
                key={day.id}
                elevation={0}
                variant="outlined"
                sx={{
                  p: 2,
                  cursor: daySchedule.isAvailable ? "pointer" : "default",
                  '&:hover': daySchedule.isAvailable ? {
                    bgcolor: 'action.hover'
                  } : {}
                }}
                onClick={() => daySchedule.isAvailable && handleExpandClick(day.id)}
              >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center" flex={1}>
                    <Switch
                      checked={daySchedule.isAvailable}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleDayToggle(day.id);
                        if (!e.target.checked && expandedDay === day.id) {
                          setExpandedDay(null);
                        }
                      }}
                      onClick={(e) => e.stopPropagation()}
                      sx={{ mr: 2 }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: expandedDay === day.id ? 600 : 400,
                        color: daySchedule.isAvailable ? 'text.primary' : 'text.disabled'
                      }}
                    >
                      {day.name}
                    </Typography>
                    {daySchedule.isAvailable && (
                      <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                        {daySchedule.startTime} - {daySchedule.endTime}
                      </Typography>
                    )}
                  </Box>
                  {daySchedule.isAvailable && (
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleExpandClick(day.id);
                      }}
                    >
                      {expandedDay === day.id ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </IconButton>
                  )}
                </Box>

                <Collapse in={expandedDay === day.id && daySchedule.isAvailable}>
                  <Box sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body2" sx={{ mb: 1 }}>Başlangıç Saati</Typography>
                        <StyledTimeInput
                          type="time"
                          value={daySchedule.startTime}
                          onChange={(e) => handleTimeChange(day.id, 'startTime', e.target.value)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" sx={{ mb: 1 }}>Bitiş Saati</Typography>
                        <StyledTimeInput
                          type="time"
                          value={daySchedule.endTime}
                          onChange={(e) => handleTimeChange(day.id, 'endTime', e.target.value)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Grid>
                    </Grid>

                    <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>Saat Dilimleri</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {daySchedule.slots && daySchedule.slots.map((slot, index) => (
                        <Button
                          key={index}
                          variant={slot.isAvailable ? "contained" : "outlined"}
                          color={slot.isAvailable ? "primary" : "error"}
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSlotToggle(day.id, slot.time);
                          }}
                          sx={{ minWidth: 120 }}
                        >
                          {slot.time}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                </Collapse>
              </Paper>
            );
          })}
        </Stack>
      </Paper>
      
      {/* Onay Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Seans Onayı
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Bu seansa katılmak istediğinizden emin misiniz? Onayladığınızda görüntülü görüşme odasına yönlendirileceksiniz.
          </Typography>
          <Alert severity="info" sx={{ mb: 2 }}>
            <AlertTitle>Önemli Bilgiler</AlertTitle>
            - Görüşme başlamadan önce kamera ve mikrofonunuzun çalıştığından emin olun<br />
            - Stable bir internet bağlantısına sahip olduğunuzdan emin olun<br />
            - Görüşme sırasında sessiz bir ortamda olmanız önerilir
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="inherit">
            İptal
          </Button>
          <Button onClick={handleConfirmJoin} variant="contained" color="primary">
            Seansa Katıl
          </Button>
        </DialogActions>
      </Dialog>
      
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          size="large"
          onClick={handleSave}
        >
          Programı Kaydet
        </Button>
      </Box>
    </Container>
  );
};

export default PsychologistSchedule;
