import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

const initialState = {
  user: null,
  token: localStorage.getItem('token'),
  refreshToken: localStorage.getItem('refreshToken'),
  loading: false,
  error: null,
  isAuthenticated: !!localStorage.getItem('token'),
  status: null,
};

// Sayfa yüklendiğinde token'ı kontrol et ve güncelle
if (localStorage.getItem('token')) {
  const token = localStorage.getItem('token');
  const tokenWithBearer = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
  axiosInstance.defaults.headers.common['Authorization'] = tokenWithBearer;
}

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      console.log('\n=== Giriş İsteği ===');
      console.log('Credentials:', { email: credentials.email });

      const response = await axiosInstance.post('/api/auth/login', credentials);

      console.log('Login Response:', response.data);
      console.log('====================\n');

      // Token'ı hem localStorage'a hem de state'e kaydet
      if (response.data?.data?.token) {
        const { token, refreshToken, user } = response.data.data;
        // Token'ı Bearer prefix'i ile kaydet
        const tokenWithBearer = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
        localStorage.setItem('token', tokenWithBearer);
        if (refreshToken) {
          localStorage.setItem('refreshToken', refreshToken);
        }
        
        // Axios default header'ını güncelle
        axiosInstance.defaults.headers.common['Authorization'] = tokenWithBearer;
        
        return { token: tokenWithBearer, refreshToken, user };
      } else {
        throw new Error('Token alınamadı');
      }
    } catch (error) {
      console.error('\n=== Giriş Hatası ===');
      console.error('Error:', error.response?.data || error.message);
      console.error('====================\n');
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (userData, { rejectWithValue }) => {
    try {
      console.log('\n=== Kayıt İsteği ===');
      console.log('User Data:', { email: userData.email });

      const response = await axiosInstance.post('/api/auth/register', userData);

      console.log('Register Response:', response.data);
      console.log('====================\n');

      return response.data;
    } catch (error) {
      console.error('\n=== Kayıt Hatası ===');
      console.error('Error:', error.response?.data || error.message);
      console.error('====================\n');
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      console.log('\n=== Çıkış İsteği ===');

      await axiosInstance.post('/api/auth/logout');
      
      // Local storage'dan token'ları temizle
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');

      console.log('Çıkış başarılı');
      console.log('====================\n');

      return null;
    } catch (error) {
      console.error('\n=== Çıkış Hatası ===');
      console.error('Error:', error.response?.data || error.message);
      console.error('====================\n');
      
      // Hata olsa bile token'ları temizle
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  'auth/getCurrentUser',
  async (_, { getState, rejectWithValue }) => {
    try {
      console.log('\n=== Kullanıcı Bilgileri İsteği ===');
      const { token } = getState().auth;
      
      if (!token) {
        throw new Error('Token bulunamadı');
      }

      const response = await axiosInstance.get('/api/auth/current-user');
      
      console.log('Response:', response.data);
      console.log('====================\n');
      
      return response.data.data;
    } catch (error) {
      console.error('\n=== Kullanıcı Bilgileri Hatası ===');
      console.error('Error:', error.response?.data || error);
      console.error('====================\n');
      
      if (error.response?.status === 401) {
        // Token geçersiz ise kullanıcıyı çıkış yaptır
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        return rejectWithValue({ message: 'Oturum süresi doldu' });
      }
      
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  'auth/updateUserProfile',
  async (userData, { rejectWithValue }) => {
    try {
      console.log('Redux: Kullanıcı profili güncelleniyor:', userData);
      return userData;
    } catch (error) {
      console.error('Redux: Kullanıcı profili güncelleme hatası:', error);
      return rejectWithValue(error.response?.data?.message || 'Profil güncellenirken bir hata oluştu');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, token, refreshToken } = action.payload;
      
      console.log('\n=== 🔐 Redux Auth - setCredentials ===');
      console.log('User:', user);
      console.log('Token:', token ? 'Mevcut' : 'Yok');
      console.log('Refresh Token:', refreshToken ? 'Mevcut' : 'Yok');
      console.log('====================\n');
      
      if (token) {
        state.token = token;
        state.isAuthenticated = true;
        
        if (refreshToken) {
          state.refreshToken = refreshToken;
        }
      }
      
      if (user) {
        state.user = user;
      }
    },
    clearCredentials: (state) => {
      console.log('\n=== 🔐 Redux Auth - clearCredentials ===');
      console.log('Clearing all credentials');
      console.log('====================\n');
      
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      
      // Local storage'ı temizle
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      
      // Axios headers'ı temizle
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log('\n=== Login Fulfilled ===');
        console.log('Payload:', action.payload);
        console.log('User:', action.payload.user);
        console.log('====================\n');

        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.refreshToken = action.payload.refreshToken;
        state.isAuthenticated = true;
        state.error = null;

        // Token'ı local storage'a kaydet
        localStorage.setItem('token', action.payload.token);
        if (action.payload.refreshToken) {
          localStorage.setItem('refreshToken', action.payload.refreshToken);
        }

        // Axios header'ını güncelle
        axiosInstance.defaults.headers.common['Authorization'] = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Giriş başarısız';
        state.isAuthenticated = false;
      })

      // Register
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Kayıt başarısız';
      })

      // Logout
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.token = null;
        state.refreshToken = null;
        state.isAuthenticated = false;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.token = null;
        state.refreshToken = null;
        state.isAuthenticated = false;
        state.error = action.payload?.message || 'Çıkış yapılırken bir hata oluştu';
      })

      // Get Current User
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.loading = false;
        if (action.payload?.message === 'Oturum süresi doldu') {
          state.user = null;
          state.token = null;
          state.refreshToken = null;
          state.isAuthenticated = false;
          
          // Token'ları temizle
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          
          // Axios headers'ı temizle
          delete axiosInstance.defaults.headers.common['Authorization'];
        }
        state.error = action.payload?.message || 'Kullanıcı bilgileri alınamadı';
      })

      // Update Profile
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        console.log('Redux: Kullanıcı profili güncellendi:', action.payload);
        state.user = action.payload;
        state.status = 'succeeded';
        state.loading = false;
        state.error = null;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        console.error('Redux: Kullanıcı profili güncelleme başarısız:', action.payload);
        state.status = 'failed';
        state.error = action.payload;
        state.loading = false;
      });
  }
});

// Seçiciler
export const selectToken = (state) => state.auth.token;
export const selectRefreshToken = (state) => state.auth.refreshToken;

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;
