import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { IS_DEVELOPMENT } from '../../config';

const initialState = {
  featuredPsychologists: [],
  psychologists: [],
  psychologist: null,
  selectedPsychologist: null,
  loading: false,
  error: null,
  lastFetch: null,
  lastFeaturedFetch: null
};

// Featured Psikologları getir
export const fetchFeaturedPsychologists = createAsyncThunk(
  'psychologist/fetchFeatured',
  async (_, { rejectWithValue }) => {
    try {
      console.log('\n=== Öne Çıkan Psikologlar Yükleniyor ===');
      const response = await axiosInstance.get('/api/psychologists/featured');
      console.log('Response:', response.data);
      console.log('====================\n');
      
      if (response.data?.data) {
        return response.data.data;
      } else if (Array.isArray(response.data)) {
        return response.data;
      }
      
      console.error('API yanıt yapısı beklendiği gibi değil:', response.data);
      return rejectWithValue('Psikolog verileri yanlış formatta');
    } catch (error) {
      console.error('\n=== Öne Çıkan Psikologlar Yükleme Hatası ===');
      console.error('Error:', error.response?.data || error.message);
      console.error('====================\n');
      return rejectWithValue(error.response?.data?.message || 'Sunucu hatası oluştu');
    }
  }
);

// Tüm Psikologları getir
export const fetchAllPsychologists = createAsyncThunk(
  'psychologist/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      console.log('\n=== Tüm Psikologlar Yükleniyor ===');
      const response = await axiosInstance.get('/api/psychologists');
      console.log('Response:', response.data);
      console.log('====================\n');
      
      if (response.data?.data) {
        return response.data.data;
      } else if (Array.isArray(response.data)) {
        return response.data;
      }
      
      console.error('API yanıt yapısı beklendiği gibi değil:', response.data);
      return rejectWithValue('Psikolog verileri yanlış formatta');
    } catch (error) {
      console.error('\n=== Tüm Psikologlar Yükleme Hatası ===');
      console.error('Error:', error.response?.data || error.message);
      console.error('====================\n');
      return rejectWithValue(error.response?.data?.message || 'Sunucu hatası oluştu');
    }
  }
);

// Tek Psikolog getir
export const fetchPsychologistDetails = createAsyncThunk(
  'psychologist/fetchDetails',
  async (id, { rejectWithValue }) => {
    try {
      console.log('\n=== Psikolog Detayları Yükleniyor ===');
      console.log('Psikolog ID:', id);
      const response = await axiosInstance.get(`/api/psychologists/${id}`);
      console.log('Response:', response.data);
      console.log('====================\n');
      
      if (response.data?.data) {
        return response.data.data;
      } else if (response.data && typeof response.data === 'object') {
        return response.data;
      }
      
      console.error('API yanıt yapısı beklendiği gibi değil:', response.data);
      return rejectWithValue('Psikolog detayları yanlış formatta');
    } catch (error) {
      console.error('\n=== Psikolog Detayları Yükleme Hatası ===');
      console.error('Error:', error.response?.data || error.message);
      console.error('====================\n');
      return rejectWithValue(error.response?.data?.message || 'Sunucu hatası oluştu');
    }
  }
);

const psychologistSlice = createSlice({
  name: 'psychologist',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearPsychologist(state) {
      state.psychologist = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Featured Psikologlar
      .addCase(fetchFeaturedPsychologists.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFeaturedPsychologists.fulfilled, (state, action) => {
        state.loading = false;
        state.featuredPsychologists = action.payload;
        state.lastFeaturedFetch = Date.now();
      })
      .addCase(fetchFeaturedPsychologists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Öne çıkan psikologlar getirilemedi';
      })
      
      // Tüm Psikologlar
      .addCase(fetchAllPsychologists.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllPsychologists.fulfilled, (state, action) => {
        state.loading = false;
        state.psychologists = action.payload;
        state.lastFetch = Date.now();
      })
      .addCase(fetchAllPsychologists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Psikologlar getirilemedi';
      })
      
      // Psikolog Detayları
      .addCase(fetchPsychologistDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPsychologistDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.psychologist = action.payload;
      })
      .addCase(fetchPsychologistDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Psikolog detayları getirilemedi';
      });
  }
});

export const { clearError, clearPsychologist } = psychologistSlice.actions;
export default psychologistSlice.reducer;
