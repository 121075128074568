import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import {
  Box,
  Stack,
  Typography,
  Icon,
  Divider,
  Container,
  IconButton,
  useTheme
} from '@mui/material';
import {
  Home as HomeIcon,
  People as PeopleIcon,
  Description as DescriptionIcon,
  Menu as MenuIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  width: '100%'
}));

const MenuItem = ({ icon, children, to, isActive }) => {
  const theme = useTheme();

  return (
    <StyledLink to={to}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          px: 2,
          py: 1.5,
          borderRadius: 1,
          cursor: 'pointer',
          bgcolor: isActive ? 'primary.light' : 'transparent',
          color: isActive ? 'primary.main' : 'text.primary',
          '&:hover': {
            bgcolor: isActive ? 'primary.light' : 'action.hover',
          },
          transition: 'all 0.2s'
        }}
      >
        <Icon
          component={icon}
          sx={{
            fontSize: 24,
            color: isActive ? 'primary.main' : 'text.secondary',
            '&:hover': {
              color: isActive ? 'primary.main' : 'text.primary',
            }
          }}
        />
        <Typography
          variant="body2"
          sx={{
            color: isActive ? 'primary.main' : 'text.primary',
            fontWeight: isActive ? 600 : 400
          }}
        >
          {children}
        </Typography>
      </Stack>
    </StyledLink>
  );
};

const AdminLayout = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const theme = useTheme();

  const menuItems = [
    { icon: HomeIcon, text: 'Dashboard', path: '/admin' },
    { icon: PeopleIcon, text: 'Kullanıcılar', path: '/admin/users' },
    { icon: DescriptionIcon, text: 'Başvurular', path: '/admin/applications' }
  ];

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'background.default' }}>
      {/* Sidebar */}
      <Box
        component="nav"
        sx={{
          width: { sm: 240 },
          flexShrink: { sm: 0 },
          display: { xs: isMobileMenuOpen ? 'block' : 'none', sm: 'block' },
          bgcolor: 'background.paper',
          borderRight: 1,
          borderColor: 'divider'
        }}
      >
        <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
            Admin Panel
          </Typography>
          <Divider />
          <Stack spacing={1}>
            {menuItems.map((item) => (
              <MenuItem
                key={item.path}
                icon={item.icon}
                to={item.path}
                isActive={location.pathname === item.path}
              >
                {item.text}
              </MenuItem>
            ))}
          </Stack>
        </Stack>
      </Box>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - 240px)` }
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Container maxWidth="lg">
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default AdminLayout;
