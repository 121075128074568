import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPsychologistDetails } from "../store/slices/psychologistSlice";
import {
  fetchPsychologistPublicContents,
  incrementViews,
  toggleLike,
} from "../store/slices/contentSlice";
import { createAppointment } from "../store/slices/appointmentSlice";
import axiosInstance from "../utils/axios";
import { format, addDays } from "date-fns";
import { tr } from "date-fns/locale";
import { toast } from "react-hot-toast";
import {
  FaHeart,
  FaRegUser,
  FaGraduationCap,
  FaBriefcase,
  FaMoneyBillWave,
  FaStar,
  FaQuoteLeft,
  FaAward,
  FaCalendarAlt,
  FaClock,
  FaMapMarkerAlt,
  FaLanguage,
  FaCheckCircle,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { BsArrowUpCircle } from "react-icons/bs";
import { useInView } from "react-intersection-observer";
import {
  FiPlay,
  FiFileText,
  FiVideo,
  FiInbox,
  FiChevronUp,
  FiChevronDown,
  FiEye,
} from "react-icons/fi";
import { useWebSocket } from "../contexts/WebSocketContext";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { HiCheckCircle } from "react-icons/hi";
import ReactModal from "react-modal";
import ReviewSection from "../components/ReviewSection";
import axios from "../utils/axios";

const PsychologistDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { psychologist, loading: psychologistLoading } = useSelector(
    (state) => state.psychologist
  );
  const { publicContents, loading: contentsLoading } = useSelector(
    (state) => state.content
  );
  const { user } = useSelector((state) => state.auth);
  const { token } = useSelector((state) => state.auth);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 7 });
  const [availableDates, setAvailableDates] = useState([]);
  const [expandedContents, setExpandedContents] = useState(new Map());
  const [activeTab, setActiveTab] = useState("about"); // ['about', 'education', 'experience', 'schedule', 'contents']
  const [isLoading, setIsLoading] = useState(false);
  const scrollContainerRef = useRef(null);
  const { socket } = useWebSocket();
  // const { showLoading, hideLoading } = useLoading();

  const [showScrollTop, setShowScrollTop] = useState(false);
  const [activeSection, setActiveSection] = useState("profile");
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [comment, setComment] = useState("");
  const [reviews, setReviews] = useState([]);

  const getProfileImageUrl = (path) => {
    if (!path) return "/default-avatar.png";
    const baseUrl = process.env.REACT_APP_API_URL || window.location.origin;
    console.log("API URL Image Path:", `${baseUrl}/api/uploads/${path}`);
    return `${baseUrl}/api/uploads/${path}`;
  };

  const getContentMediaUrl = (path) => {
    if (!path) return null;
    if (path.startsWith("http")) return path;
    const baseUrl = process.env.REACT_APP_API_URL || window.location.origin;
    // Eğer path zaten /api ile başlıyorsa, olduğu gibi kullan
    if (path.startsWith("/api")) {
      return `${baseUrl}${path}`;
    }
    // Değilse /api/uploads/ ekle
    return `${baseUrl}/api/${path}`;
  };

  const handleVideoClick = (content) => {
    // Video izlenmeye başlandığında görüntüleme sayısını artır
    if (!selectedVideo) {
      dispatch(incrementViews(content._id));
    }
    setSelectedVideo(content._id);
  };

  const handleLikeClick = (contentId) => {
    dispatch(toggleLike(contentId));
  };

  const renderContentPreview = (content) => {
    switch (content.type) {
      case "video":
        return (
          <div className="relative w-full aspect-video rounded-lg overflow-hidden bg-gray-100">
            {selectedVideo === content._id ? (
              <div className="w-full h-full">
                <video
                  src={getContentMediaUrl(content.videoFile)}
                  controls
                  autoPlay
                  className="absolute inset-0 w-full h-full object-contain bg-black"
                  controlsList="nodownload"
                  playsInline
                />
              </div>
            ) : (
              <>
                {content.thumbnail && (
                  <img
                    src={getContentMediaUrl(content.thumbnail)}
                    alt={content.title}
                    className="absolute inset-0 w-full h-full object-contain"
                    loading="lazy"
                  />
                )}
                {content.videoFile && (
                  <div
                    className="absolute inset-0 flex items-center justify-center cursor-pointer bg-black/30 hover:bg-black/40 transition-colors"
                    onClick={() => handleVideoClick(content)}
                  >
                    <div className="w-16 h-16 rounded-full bg-white/90 flex items-center justify-center">
                      <FiPlay className="w-8 h-8 text-blue-600" />
                    </div>
                  </div>
                )}
              </>
            )}

            {/* İstatistikler */}
            <div className="absolute bottom-4 right-4 flex items-center gap-4 text-white text-sm bg-black/50 px-3 py-1.5 rounded-full">
              <div className="flex items-center gap-1">
                <FiEye className="w-4 h-4" />
                <span>{content.views || 0}</span>
              </div>
              <div className="flex items-center gap-1">
                <button
                  onClick={() => handleLikeClick(content._id)}
                  className="flex items-center gap-1 hover:text-blue-400 transition-colors"
                >
                  {content.isLiked ? (
                    <FaHeart className="w-4 h-4 fill-current" />
                  ) : (
                    <FaHeart className="w-4 h-4" />
                  )}
                  <span>{content.likes?.length || 0}</span>
                </button>
              </div>
            </div>
          </div>
        );
      case "article":
        return (
          <div className="relative aspect-video rounded-lg overflow-hidden bg-gray-100">
            <img
              src={
                getContentMediaUrl(content.thumbnail) ||
                "/article-placeholder.jpg"
              }
              alt={content.title}
              className="absolute inset-0 w-full h-full object-contain"
              loading="lazy"
            />
          </div>
        );
      default:
        return null;
    }
  };

  const toggleContent = useCallback((contentId) => {
    setExpandedContents((prev) => {
      const next = new Map(prev);
      next.set(contentId, !prev.get(contentId));
      return next;
    });
  }, []);

  const truncateText = useCallback((text, maxLength = 300) => {
    if (!text) return "";
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength).trim() + "...";
  }, []);

  useEffect(() => {
    const getAvailableDates = async () => {
      try {
        console.log("Fetching available dates...");
        const response = await axiosInstance.get(
          `/api/psychologists/${id}/available-dates`
        );
        console.log("Available dates response:", response.data);

        if (Array.isArray(response.data)) {
          const dates = response.data.map((date) => {
            const d = new Date(date);
            return {
              date,
              dayName: format(d, "EEE", { locale: tr }),
              dayOfMonth: format(d, "d"),
              month: format(d, "MMM", { locale: tr }),
            };
          });
          setAvailableDates(dates);
          // İlk tarihi otomatik seç
          if (dates.length > 0) {
            handleDateSelect(dates[0].date);
          }
        }
      } catch (error) {
        console.error(
          "Error fetching available dates:",
          error.response?.data || error.message
        );
        toast.error("Tarihler yüklenirken bir hata oluştu");
      }
    };

    if (id) {
      getAvailableDates();
    }
  }, [id]);

  useEffect(() => {
    const getTimeSlots = async () => {
      if (!selectedDate) {
        setTimeSlots([]);
        return;
      }

      try {
        setIsLoading(true);
        const response = await axiosInstance.get(
          `/api/psychologists/${id}/slots`,
          {
            params: { date: selectedDate },
          }
        );

        // API'den gelen time slots'ları direkt al
        setTimeSlots(response.data.timeSlots || []);
      } catch (error) {
        console.error("Error fetching time slots:", error);
        toast.error("Saatler yüklenirken bir hata oluştu");
        setTimeSlots([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedDate) {
      getTimeSlots();
    }
  }, [id, selectedDate]);

  const handleDateSelect = useCallback((date) => {
    console.log("Tarih seçildi:", date);
    setSelectedDate(date);
    setSelectedSlot(null);
  }, []);

  const handleSlotSelect = useCallback((slot) => {
    console.log("Seçilen slot:", slot);
    setSelectedSlot(slot);
  }, []);

  const handleNext = useCallback(() => {
    console.log("İleri butonu tıklandı");
    if (visibleRange.end >= availableDates.length) return;

    setVisibleRange((prev) => ({
      start: prev.start + 7,
      end: Math.min(prev.end + 7, availableDates.length),
    }));
  }, [availableDates.length, visibleRange]);

  const handlePrevious = useCallback(() => {
    console.log("Geri butonu tıklandı");
    if (visibleRange.start === 0) return;

    setVisibleRange((prev) => ({
      start: Math.max(prev.start - 7, 0),
      end: prev.end - 7,
    }));
  }, [visibleRange]);

  const handleCreateAppointment = async () => {
    // Giriş kontrolü
    if (!user?._id) {
      toast.error("Randevu alabilmek için giriş yapmalısınız", {
        icon: "🔒",
        duration: 3000
      });
      navigate("/login", { state: { from: `/psychologist/${id}` } });
      return;
    }

    if (!selectedDate || !selectedSlot) {
      toast.error("Lütfen bir tarih ve saat seçiniz", {
        icon: "⏰",
        duration: 3000
      });
      return;
    }

    try {
      setIsLoading(true);
      const formattedDate = format(new Date(selectedDate), "yyyy-MM-dd");

      const appointmentData = {
        psychologistId: id,
        appointmentDate: formattedDate,
        appointmentTime: selectedSlot.time,
        userId: user?._id,
      };

      const response = await axiosInstance.post(
        "/api/appointments",
        appointmentData
      );

      if (response.data && response.data.status === "pending") {
        // Debug: Konsola bilgi bas
        console.log("Randevu başarılı, modal açılacak");
        console.log("Appointment Details:", {
          psychologistName: psychologist.user.name,
          date: format(new Date(formattedDate), "d MMMM yyyy"),
          time: selectedSlot.time,
          status: "Onay Bekliyor"
        });

        // Modal state'lerini güncelle
        setAppointmentDetails({
          psychologistName: psychologist.user.name,
          date: format(new Date(formattedDate), "d MMMM yyyy"),
          time: selectedSlot.time,
          status: "Onay Bekliyor"
        });

        // Kısa bir gecikmeyle modal'ı aç
        setTimeout(() => {
          setShowSuccessModal(true);
        }, 100);
      }
    } catch (error) {
      console.error("Randevu oluşturma hatası:", error);

      // Detaylı hata yönetimi
      const errorMessage = error.response?.data?.message;
      switch (error.response?.status) {
        case 401:
          toast.error("Randevu alabilmek için giriş yapmalısınız", {
            icon: "🔒",
            duration: 3000
          });
          navigate("/login", { state: { from: `/psychologist/${id}` } });
          break;
        case 409:
          toast.error("Seçilen randevu saati maalesef doldu. Lütfen başka bir saat seçiniz.", {
            icon: "⚠️",
            duration: 4000
          });
          break;
        case 403:
          toast.error("Bu psikolog ile randevu alamazsınız.", {
            icon: "🚫",
            duration: 3000
          });
          break;
        default:
          toast.error(
            errorMessage || "Randevu oluşturulurken bir hata oluştu. Lütfen daha sonra tekrar deneyin.", 
            {
              icon: "❌",
              duration: 4000
            }
          );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const visibleDates = useMemo(() => {
    return availableDates.slice(visibleRange.start, visibleRange.end);
  }, [availableDates, visibleRange]);

  useEffect(() => {
    if (id) {
      dispatch(fetchPsychologistDetails(id));
      dispatch(fetchPsychologistPublicContents(id));
    }
  }, [dispatch, id]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);

  const SuccessModal = ({ isOpen, onClose, appointmentDetails }) => {
    const navigate = useNavigate();

    const handleClose = () => {
      onClose();
    };

    const handleViewAppointments = () => {
      navigate('/appointments');
      handleClose();
    };

    if (!isOpen || !appointmentDetails) return null;

    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={handleClose}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Yüksek z-index
          },
          content: {
            position: 'absolute', // Mutlak konumlandırma
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '500px',
            width: '90%',
            padding: '2rem',
            borderRadius: '12px',
            boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1)',
          }
        }}
        contentLabel="Randevu Başarılı"
      >
        <div className="text-center">
          <HiCheckCircle className="mx-auto h-16 w-16 text-green-500" />
          <h2 className="text-2xl font-bold text-gray-900 mt-4 mb-2">
            Randevu Talebiniz Alındı
          </h2>
          <div className="bg-green-50 border border-green-200 rounded-lg p-4 my-4">
            <div className="flex justify-between mb-2">
              <span className="font-medium text-gray-700">Psikolog:</span>
              <span className="font-semibold text-gray-900">
                {appointmentDetails?.psychologistName}
              </span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="font-medium text-gray-700">Tarih:</span>
              <span className="font-semibold text-gray-900">
                {appointmentDetails?.date}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-700">Saat:</span>
              <span className="font-semibold text-gray-900">
                {appointmentDetails?.time}
              </span>
            </div>
          </div>
          <p className="text-gray-600 mb-6">
            Randevu talebiniz psikolog tarafından onaylandıktan sonra kesinleşecektir. 
            Size en kısa sürede bilgilendirme yapılacaktır.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={handleViewAppointments}
              className="w-full sm:w-auto px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Randevularımı Görüntüle
            </button>
            <button
              onClick={handleClose}
              className="w-full sm:w-auto px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
            >
              Kapat
            </button>
          </div>
        </div>
      </ReactModal>
    );
  };

  useEffect(() => {
    ReactModal.setAppElement("#root");
  }, []);

  const fetchReviews = async () => {
    try {
      const { data } = await axios.get(`/api/reviews/psychologist/${id}`);
      setReviews(data.data);
    } catch (error) {
      console.error("Reviews fetch error:", error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [id]);

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      toast.error("Değerlendirme yapmak için giriş yapmalısınız");
      return;
    }

    try {
      await axios.post("/api/reviews", {
        psychologistId: id,
        rating,
        comment,
      });

      toast.success("Değerlendirmeniz başarıyla eklendi");
      setRating(0);
      setComment("");
      fetchReviews();
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Değerlendirme eklenirken bir hata oluştu"
      );
    }
  };

  if (psychologistLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!psychologist) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            Bir hata oluştu
          </h2>
          <p className="text-gray-600">Psikolog bulunamadı</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 relative z-0">
      {/* Modal'ı en üst seviyede render et */}
      <SuccessModal 
        isOpen={showSuccessModal}
        onClose={() => {
          console.log("Modal kapatılıyor");
          setShowSuccessModal(false);
        }}
        appointmentDetails={appointmentDetails}
      />

      {/* Kapak Fotoğrafı ve Profil */}
      <div className="relative">
        <div className="h-64 bg-gradient-to-r from-blue-600 via-purple-500 to-pink-500">
          <div className="absolute inset-0 bg-black/10"></div>
        </div>

        <div className="max-w-6xl mx-auto px-4">
          <div className="relative -mt-20 sm:flex sm:items-end sm:space-x-5 pb-4 border-b border-gray-200">
            <div className="relative">
              <img
                src={
                  getProfileImageUrl(psychologist?.user?.profileImage) ||
                  "/default-avatar.png"
                }
                alt={psychologist?.user?.name}
                className="h-40 w-40 rounded-xl bg-white p-1 shadow-xl sm:h-44 sm:w-44"
              />
              <div className="absolute -bottom-2 -right-2 bg-green-500 w-6 h-6 rounded-full border-4 border-white"></div>
            </div>

            <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="sm:hidden md:block min-w-0 flex-1">
                <h1 className="text-2xl font-bold text-gray-900 truncate">
                  {psychologist?.user?.name}
                </h1>
                <p className="text-gray-600">{psychologist?.expertise}</p>
              </div>
              <div className="flex flex-col sm:flex-row mt-4 sm:mt-0 sm:flex-wrap sm:space-x-3">
                <button
                  onClick={() =>
                    document
                      .getElementById("randevu")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                  className="inline-flex justify-center px-6 py-2.5 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
                >
                  Randevu Al
                </button>
              </div>
              <div className="flex flex-col sm:flex-row mt-4 sm:mt-0 sm:flex-wrap sm:space-x-3">
                <button
                  onClick={() =>
                    document
                      .getElementById("paylasimlar")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                  className="inline-flex justify-center px-6 py-2.5 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
                >
                  Paylaşımlar
                </button>
              </div>

              <div className="flex flex-col sm:flex-row mt-4 sm:mt-0 sm:flex-wrap sm:space-x-3">
                <button
                  onClick={() => navigate('/psychologist-reviews/' + psychologist?._id)}
                  className="inline-flex justify-center px-6 py-2.5 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
                >
                  Değerlendirmeler
                </button>
              </div>
            </div>
          </div>

          {/* Ana İçerik */}
          <div className="flex flex-col lg:flex-row gap-8 py-8">
            {/* Sol Kolon - Profil Bilgileri */}
            <div className="w-full lg:w-1/3 space-y-6">
              {/* İstatistikler */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="grid grid-cols-3 gap-4">
                  <div className="text-center">
                    <div className="text-2xl font-bold text-blue-600">
                      {psychologist?.rating?.toFixed(1) || "0.0"}
                    </div>
                    <div className="text-sm text-gray-500">Puan</div>
                  </div>
                  <div className="text-center">
                    <div className="text-2xl font-bold text-purple-600">
                      {psychologist?.experience || 0}
                    </div>
                    <div className="text-sm text-gray-500">Yıl Deneyim</div>
                  </div>
                  <div className="text-center">
                    <div className="text-2xl font-bold text-pink-600">
                      {psychologist?.hourlyRate || 0}₺
                    </div>
                    <div className="text-sm text-gray-500">Seans</div>
                  </div>
                </div>
              </div>

              {/* Hakkında */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="font-medium text-gray-900 mb-4">Hakkında</h3>
                <p className="text-gray-600">
                  {psychologist?.about || "Henüz bir biyografi eklenmemiş."}
                </p>
              </div>

              {/* Uzmanlık Alanları */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="font-medium text-gray-900 mb-4">
                  Uzmanlık Alanları
                </h3>
                <div className="flex flex-wrap gap-2">
                  {psychologist?.specialties?.map((specialty, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-blue-50 text-blue-700 rounded-lg text-sm"
                    >
                      {specialty.name}
                    </span>
                  ))}
                </div>
              </div>

              {/* Eğitim */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="font-medium text-gray-900 mb-4">Eğitim</h3>
                <div className="space-y-4">
                  {psychologist?.education?.map((edu, index) => (
                    <div key={index} className="flex gap-3">
                      <FaGraduationCap className="w-5 h-5 text-blue-600 mt-1 flex-shrink-0" />
                      <div>
                        <p className="font-medium text-gray-900">
                          {edu.institution}
                        </p>
                        <p className="text-sm text-gray-600">
                          {edu.degree} - {edu.field}
                        </p>
                        <p className="text-sm text-gray-500">
                          {new Date(edu.startDate).toLocaleDateString("tr-TR")}{" "}
                          -
                          {edu.endDate
                            ? new Date(edu.endDate).toLocaleDateString("tr-TR")
                            : "Devam Ediyor"}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Deneyim */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="font-medium text-gray-900 mb-4">Deneyim</h3>
                <div className="space-y-4">
                  {psychologist?.experiences?.map((exp, index) => (
                    <div key={index} className="flex gap-3">
                      <FaBriefcase className="w-5 h-5 text-blue-600 mt-1 flex-shrink-0" />
                      <div>
                        <p className="font-medium text-gray-900">{exp.title}</p>
                        <p className="text-sm text-gray-750">{exp.company}</p>
                        <p className="text-sm text-gray-600">
                          {exp.description}
                        </p>
                        <p className="text-sm text-gray-500">
                          {new Date(exp.startDate).toLocaleDateString("tr-TR")}{" "}
                          -
                          {exp.endDate
                            ? new Date(exp.endDate).toLocaleDateString("tr-TR")
                            : "Devam Ediyor"}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Sağ Kolon - Randevu ve İçerikler */}
            <div className="w-full lg:w-2/3 space-y-6">
              {/* Randevu Bölümü */}
              <div
                id="randevu"
                className="bg-white rounded-lg shadow-sm p-6 relative z-10"
              >
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-bold text-gray-900">
                    Randevu Al
                  </h2>
                  <div className="text-sm text-gray-500">
                    {selectedDate && selectedSlot ? (
                      <span>
                        Seçilen:{" "}
                        {format(new Date(selectedDate), "dd MMM yyyy", {
                          locale: tr,
                        })}{" "}
                        - {selectedSlot.time}
                      </span>
                    ) : (
                      <span>Tarih ve saat seçiniz</span>
                    )}
                  </div>
                </div>

                <div className="space-y-6">
                  {/* Tarih Seçici */}
                  <div className="mt-6">
                    <div className="flex items-center justify-between mb-4 relative z-10">
                      <button
                        type="button"
                        onClick={handlePrevious}
                        disabled={visibleRange.start === 0}
                        className={`p-2 rounded-full ${
                          visibleRange.start === 0
                            ? "text-gray-400 cursor-not-allowed"
                            : "text-blue-600 hover:bg-blue-50"
                        }`}
                      >
                        <BiChevronLeft className="w-6 h-6" />
                      </button>

                      <div className="flex space-x-2 overflow-x-auto relative z-10">
                        {visibleDates.map((date) => (
                          <button
                            type="button"
                            key={date.date}
                            onClick={() => handleDateSelect(date.date)}
                            className={`flex flex-col items-center p-2 rounded-lg transition-colors duration-200 min-w-[80px] ${
                              selectedDate === date.date
                                ? "bg-blue-600 text-white"
                                : "bg-white hover:bg-gray-50 border border-gray-200"
                            }`}
                          >
                            <span className="text-sm font-medium">
                              {date.dayName}
                            </span>
                            <span className="text-2xl font-bold">
                              {date.dayOfMonth}
                            </span>
                            <span className="text-sm">{date.month}</span>
                          </button>
                        ))}
                      </div>

                      <button
                        type="button"
                        onClick={handleNext}
                        disabled={visibleRange.end >= availableDates.length}
                        className={`p-2 rounded-full ${
                          visibleRange.end >= availableDates.length
                            ? "text-gray-400 cursor-not-allowed"
                            : "text-blue-600 hover:bg-blue-50"
                        }`}
                      >
                        <BiChevronRight className="w-6 h-6" />
                      </button>
                    </div>

                    {/* Saat Seçici */}
                    {selectedDate && (
                      <div className="mt-4">
                        <h4 className="text-lg font-medium mb-3">
                          Müsait Saatler
                        </h4>
                        {isLoading ? (
                          <div className="text-center py-4">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
                            <div className="mt-2">Yükleniyor...</div>
                          </div>
                        ) : timeSlots.length > 0 ? (
                          <div className="grid grid-cols-4 gap-2">
                            {timeSlots.map((slot, index) => (
                              <button
                                type="button"
                                key={index}
                                onClick={() => handleSlotSelect(slot)}
                                disabled={!slot.available}
                                className={`p-2 text-center rounded-lg transition-colors duration-200 ${
                                  selectedSlot &&
                                  selectedSlot.time === slot.time
                                    ? "bg-blue-600 text-white"
                                    : slot.available
                                    ? "bg-white hover:bg-blue-50 border border-gray-200"
                                    : "bg-red-100 cursor-not-allowed"
                                }`}
                              >
                                {slot.time}
                              </button>
                            ))}
                          </div>
                        ) : (
                          <div className="text-center py-4 text-gray-500">
                            Bu tarih için müsait saat bulunmamaktadır
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {/* Randevu Butonu */}
                  {selectedDate && selectedSlot && (
                    <div className="mt-6">
                      <button
                        onClick={handleCreateAppointment}
                        disabled={isLoading}
                        className="w-full py-3 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {isLoading ? (
                          <span>İşleniyor...</span>
                        ) : (
                          <>
                            <span>Randevu Al</span>
                            <span className="text-sm">
                              (
                              {format(new Date(selectedDate), "dd MMMM yyyy", {
                                locale: tr,
                              })}{" "}
                              - {selectedSlot.time})
                            </span>
                          </>
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {/* İçerik Akışı */}
              <div
              id="paylasimlar" 
              className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="p-6 border-b border-gray-200">
                  <h2 className="text-xl font-bold text-gray-900">
                    Paylaşımlar
                  </h2>
                </div>

                <div className="p-4">
                  {contentsLoading ? (
                    <div className="py-8 text-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
                      <p className="text-gray-500 mt-3">
                        İçerikler yükleniyor...
                      </p>
                    </div>
                  ) : (
                    <div className="space-y-6">
                      {[...(publicContents || [])]
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        )
                        .map((content) => (
                          <div
                            key={content._id}
                            className="border-b border-gray-100 last:border-0 pb-6 last:pb-0"
                          >
                            <div className="flex items-center gap-3 mb-3">
                              <img
                                src={getProfileImageUrl(
                                  psychologist?.user?.profileImage
                                )}
                                alt={psychologist?.user?.name}
                                className="w-10 h-10 rounded-full"
                              />
                              <div>
                                <h3 className="font-medium text-gray-900">
                                  {psychologist?.user?.name}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {format(
                                    new Date(content.createdAt),
                                    "dd MMMM yyyy",
                                    { locale: tr }
                                  )}
                                </p>
                              </div>
                            </div>

                            <h4 className="text-lg font-medium text-gray-900 mb-2">
                              {content.title}
                            </h4>

                            {/* İçerik Önizleme */}
                            {renderContentPreview(content)}

                            {/* İçerik Detayı */}
                            <div className="mt-3">
                              {content.type === "article" && (
                                <div
                                  className={`prose prose-sm max-w-none ${
                                    !expandedContents.get(content._id)
                                      ? "line-clamp-3"
                                      : ""
                                  }`}
                                >
                                  {content.content}
                                </div>
                              )}

                              {content.content && (
                                <button
                                  onClick={() => toggleContent(content._id)}
                                  className="mt-2 text-blue-600 hover:text-blue-700 text-sm font-medium flex items-center gap-1"
                                >
                                  {expandedContents.get(content._id) ? (
                                    <>
                                      Daha az göster
                                      <FiChevronUp className="w-4 h-4" />
                                    </>
                                  ) : (
                                    <>
                                      Devamını oku
                                      <FiChevronDown className="w-4 h-4" />
                                    </>
                                  )}
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      {(!publicContents || publicContents.length === 0) && (
                        <div className="text-center py-8 text-gray-500">
                          <FiInbox className="w-12 h-12 mx-auto mb-3 text-gray-400" />
                          <p>Henüz içerik paylaşılmamış</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default PsychologistDetail;
