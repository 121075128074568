import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdEdit, MdSave, MdClose, MdPerson, MdEmail, MdDescription, 
         MdSchool, MdWork, MdLocationOn, MdPhone, MdLanguage, MdSchedule,
         MdAddAPhoto, MdAdd } from 'react-icons/md';
import { Toaster, toast } from 'react-hot-toast';
import { updateUserProfile } from '../store/slices/authSlice';
import { fetchUserAppointments, fetchPsychologistAppointments } from '../store/slices/appointmentSlice';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import { DatePicker, ConfigProvider } from 'antd';
import locale from 'antd/locale/tr_TR';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

const Profile = () => {
  const { user } = useSelector((state) => state.auth);
  const { appointments, loading, error } = useSelector((state) => state.appointments);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  // CSS stilleri
  const buttonStyles = {
    base: `
      inline-flex items-center px-3 py-2 border text-sm leading-4 font-medium 
      rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
      transition-colors duration-200
    `,
    primary: `
      text-white bg-blue-600 hover:bg-blue-700 border-transparent
    `,
    secondary: `
      text-gray-700 bg-white hover:bg-gray-50 border-gray-300
    `,
    schedule: `
      text-white bg-green-600 hover:bg-green-700 border-transparent ml-2
    `
  };

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    about: '',
    location: '',
    education: '',
    experience: '',
    languages: '',
    specializations: [],
    preferences: {
      therapyTypes: [],
      moodState: 'neutral'
    }
  });

  const [psychologistData, setPsychologistData] = useState({
    hourlyRate: '',
    sessionFee: '',
    about: '',
    expertise: '',
    education: [],
    experiences: [],
    specialties: [],
    isActive: false
  });

  const [availableSpecialties, setAvailableSpecialties] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.role === 'psychologist') {
        dispatch(fetchPsychologistAppointments());
      } else {
        dispatch(fetchUserAppointments());
      }
    }
  }, [dispatch, user]);

  // Form verilerini kullanıcı bilgileriyle doldur
  useEffect(() => {
    if (user) {
      console.log('Kullanıcı bilgileri yükleniyor:', user);
      setFormData({
        name: user.name || '',
        email: user.email || '',
        phone: user.phone || '',
        about: user.about || '',
        location: user.location || '',
        education: user.education || '',
        experience: user.experience || '',
        languages: user.languages || '',
        specializations: user.specializations || [],
        preferences: {
          therapyTypes: user.preferences?.therapyTypes || [],
          moodState: user.preferences?.moodState || 'neutral'
        }
      });
    }
  }, [user]);

  // Psikolog verilerini yükle
  useEffect(() => {
    if (user && user.role === 'psychologist') {
      const fetchPsychologistData = async () => {
        try {
          const response = await axios.get('/api/psychologists/me/profile');
          if (response.data) {
            setPsychologistData({
              hourlyRate: response.data.hourlyRate || '',
              sessionFee: response.data.sessionFee || '',
              about: response.data.about || '',
              expertise: response.data.expertise || '',
              education: response.data.education || [],
              experiences: response.data.experiences || [],
              specialties: Array.isArray(response.data.specialties) ? response.data.specialties : [],
              isActive: response.data.isActive || false
            });
          }
        } catch (error) {
          console.error('Psikolog verileri yüklenirken hata:', error);
          toast.error('Psikolog verileri yüklenemedi');
          setPsychologistData(prev => ({ ...prev, specialties: [] })); // Hata durumunda boş dizi
        }
      };
      fetchPsychologistData();
    }
  }, [user]);

  // Tüm uzmanlık alanlarını getir
  useEffect(() => {
    const fetchSpecialties = async () => {
      try {
        const response = await axios.get('/api/specialties');
        console.log('Gelen uzmanlık alanları:', response.data);
        if (response.data?.data) {
          setAvailableSpecialties(response.data.data);
        }
      } catch (error) {
        console.error('Uzmanlık alanları yüklenirken hata:', error);
        toast.error('Uzmanlık alanları yüklenemedi: ' + error.message);
        setAvailableSpecialties([]);
      }
    };
    fetchSpecialties();
  }, []);

  // İstatistikleri hesapla
  const calculateStats = () => {
    if (!appointments) return {
      total: 0,
      completed: 0,
      pending: 0,
      cancelled: 0
    };

    return {
      total: appointments.length,
      completed: appointments.filter(a => a.status === 'completed').length,
      pending: appointments.filter(a => a.status === 'pending').length,
      cancelled: appointments.filter(a => a.status === 'cancelled').length
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Önce bir yükleniyor toast'u göster
    const loadingToast = toast.loading('Profil güncelleniyor...', {
      position: 'top-center'
    });
    
    setIsLoading(true);

    try {
      const formattedData = {
        hourlyRate: parseFloat(psychologistData.hourlyRate) || 0,
        sessionFee: parseFloat(psychologistData.sessionFee) || 0,
        about: psychologistData.about || '',
        expertise: psychologistData.expertise || '',
        education: (psychologistData.education || []).map(edu => ({
          institution: edu.institution,
          degree: edu.degree,
          field: edu.field,
          startDate: edu.startDate,
          endDate: edu.endDate
        })),
        experiences: (psychologistData.experiences || []).map(exp => ({
          title: exp.title,
          company: exp.company,
          description: exp.description || '',
          startDate: exp.startDate,
          endDate: exp.endDate,
          current: exp.current || false
        })),
        specialties: (psychologistData.specialties || []).map(s => s._id),
        isActive: psychologistData.isActive || false
      };

      const response = await axios.put('/api/psychologists/me/profile', formattedData);
      
      if (response.data) {
        // Loading toast'u başarılı olarak güncelle
        toast.success('Profil başarıyla güncellendi! ✅', {
          id: loadingToast,
          duration: 3000
        });

        // Profil verilerini yeniden yükle
        const updatedProfile = await axios.get('/api/psychologists/me/profile');
        if (updatedProfile.data) {
          setPsychologistData(prev => ({
            ...prev,
            ...updatedProfile.data,
            specialties: updatedProfile.data.specialties || [],
            education: updatedProfile.data.education || [],
            experiences: updatedProfile.data.experiences || []
          }));
        }
      } else {
        // Loading toast'u hata olarak güncelle
        toast.error('Sunucudan geçerli bir yanıt alınamadı', {
          id: loadingToast,
          duration: 3000
        });
      }
    } catch (error) {
      console.error('Profil güncellenirken hata:', error);
      
      let errorMessage = 'Profil güncellenirken bir hata oluştu';
      
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }

      // Loading toast'u hata olarak güncelle
      toast.error(`Hata: ${errorMessage} ❌`, {
        id: loadingToast,
        duration: 3000
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Psikolog verilerini güncelle
  const handlePsychologistDataUpdate = async () => {
    try {
      // Eğitim ve deneyim verilerini doğrula
      const validEducation = psychologistData.education.every(edu => 
        edu.institution && edu.degree && edu.field && edu.startDate && edu.endDate
      );

      const validExperiences = psychologistData.experiences.every(exp => 
        exp.title && exp.company && exp.startDate
      );

      if (!validEducation) {
        toast.error('Lütfen tüm eğitim bilgilerini doldurun');
        return;
      }

      if (!validExperiences) {
        toast.error('Lütfen tüm deneyim bilgilerini doldurun');
        return;
      }

      // Verileri formatlayarak gönder
      const formattedData = {
        hourlyRate: parseFloat(psychologistData.hourlyRate) || 0,
        sessionFee: parseFloat(psychologistData.sessionFee) || 0,
        about: psychologistData.about,
        expertise: psychologistData.expertise,
        education: psychologistData.education.map(edu => ({
          ...edu,
          startDate: edu.startDate,
          endDate: edu.endDate
        })),
        experiences: psychologistData.experiences.map(exp => ({
          ...exp,
          startDate: exp.startDate,
          endDate: exp.endDate
        })),
        specialties: psychologistData.specialties.map(s => s._id)
      };

      const response = await axios.put('/api/psychologists/me/profile', formattedData);
      
      if (response.data) {
        toast.success('Psikolog bilgileri başarıyla güncellendi');
      }
    } catch (error) {
      console.error('Psikolog bilgileri güncellenirken hata:', error);
      toast.error('Psikolog bilgileri güncellenemedi');
    }
  };

  const handlePsychologistDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPsychologistData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleAddEducation = () => {
    setPsychologistData(prev => ({
      ...prev,
      education: [...prev.education || [], {
        institution: '',
        degree: '',
        field: '',
        startDate: '',
        endDate: ''
      }]
    }));
  };

  const handleEducationChange = (index, field, value) => {
    setPsychologistData(prev => ({
      ...prev,
      education: prev.education.map((edu, i) => {
        if (i === index) {
          if (field === 'startDate' || field === 'endDate') {
            // Eğer value bir dayjs objesi ise (DatePicker'dan geliyorsa)
            const dateValue = value ? value.format('YYYY-MM-DD') : null;
            return { ...edu, [field]: dateValue };
          }
          return { ...edu, [field]: value };
        }
        return edu;
      })
    }));
  };

  const handleAddExperience = () => {
    setPsychologistData(prev => ({
      ...prev,
      experiences: [...prev.experiences, {
        title: '',
        company: '',
        startDate: '',
        endDate: '',
        current: false,
        description: ''
      }]
    }));
  };

  const handleExperienceChange = (index, field, value) => {
    setPsychologistData(prev => ({
      ...prev,
      experiences: prev.experiences.map((exp, i) => {
        if (i === index) {
          if (field === 'startDate' || field === 'endDate') {
            // Eğer value bir dayjs objesi ise (DatePicker'dan geliyorsa)
            const dateValue = value ? value.format('YYYY-MM-DD') : null;
            return { ...exp, [field]: dateValue };
          }
          return { ...exp, [field]: value };
        }
        return exp;
      })
    }));
  };

  // Uzmanlık alanı ekleme/çıkarma
  const handleSpecialtyChange = (specialtyId) => {
    setPsychologistData(prev => {
      const isSelected = prev.specialties.some(s => s._id === specialtyId);
      const specialty = availableSpecialties.find(s => s._id === specialtyId);
      
      if (isSelected) {
        // Uzmanlık alanını çıkar
        return {
          ...prev,
          specialties: prev.specialties.filter(s => s._id !== specialtyId)
        };
      } else {
        // Uzmanlık alanını ekle
        return {
          ...prev,
          specialties: [...prev.specialties, specialty]
        };
      }
    });
  };

  // Eğitim bilgisi silme
  const handleRemoveEducation = (index) => {
    setPsychologistData(prev => ({
      ...prev,
      education: prev.education.filter((_, i) => i !== index)
    }));
  };

  // Deneyim bilgisi silme
  const handleRemoveExperience = (index) => {
    setPsychologistData(prev => ({
      ...prev,
      experiences: prev.experiences.filter((_, i) => i !== index)
    }));
  };

  // Devam ediyor durumunu yönet
  const handleCurrentChange = (index, checked) => {
    setPsychologistData(prev => ({
      ...prev,
      experiences: prev.experiences.map((exp, i) => {
        if (i === index) {
          return {
            ...exp,
            current: checked,
            endDate: checked ? null : exp.endDate // Eğer devam ediyorsa endDate'i null yap
          };
        }
        return exp;
      })
    }));
  };

  // Profil resmi URL'sini al
  const getProfileImageUrl = (imagePath) => {
    if (!imagePath) return '/default-avatar.png';
    const baseUrl = process.env.REACT_APP_API_URL || window.location.origin;
    console.log('API URL Image Path:', `${baseUrl}/api/uploads/${imagePath}`);
    return `${baseUrl}/api/uploads/${imagePath}`;
  };

  // Profil resmi yükleme işlemi
  const handleProfileImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Dosya türü kontrolü
    if (!file.type.startsWith('image/')) {
      toast.error('Lütfen geçerli bir resim dosyası seçin');
      return;
    }

    // Dosya boyutu kontrolü (5MB)
    if (file.size > 5 * 1024 * 1024) {
      toast.error('Resim dosyası 5MB\'dan küçük olmalıdır');
      return;
    }

    const formData = new FormData();
    formData.append('profileImage', file);

    try {
      const response = await axios.post('/api/users/profile/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.status === 'success') {
        toast.success('Profil resmi başarıyla güncellendi');
        dispatch(updateUserProfile(response.data.data.user));
      }
    } catch (error) {
      console.error('Profil resmi yükleme hatası:', error);
      toast.error(error.response?.data?.message || 'Profil resmi yüklenirken bir hata oluştu');
    }
  };

  const renderUserStats = () => {
    if (loading) {
      return (
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          <div className="bg-gray-50 p-4 rounded-lg animate-pulse">
            <div className="h-6 bg-gray-200 rounded w-24 mb-2"></div>
            <div className="h-8 bg-gray-200 rounded w-12"></div>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg animate-pulse">
            <div className="h-6 bg-gray-200 rounded w-24 mb-2"></div>
            <div className="h-8 bg-gray-200 rounded w-12"></div>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg animate-pulse">
            <div className="h-6 bg-gray-200 rounded w-24 mb-2"></div>
            <div className="h-8 bg-gray-200 rounded w-12"></div>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg animate-pulse">
            <div className="h-6 bg-gray-200 rounded w-24 mb-2"></div>
            <div className="h-8 bg-gray-200 rounded w-12"></div>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-6">
          <p className="font-medium">Randevu bilgileri yüklenirken bir hata oluştu</p>
          <p className="text-sm">{error}</p>
        </div>
      );
    }

    const stats = calculateStats();

    return (
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-blue-50 p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-blue-800">Toplam</h3>
          <p className="text-2xl font-bold text-blue-900">{stats.total}</p>
        </div>
        <div className="bg-green-50 p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-green-800">Tamamlanan</h3>
          <p className="text-2xl font-bold text-green-900">{stats.completed}</p>
        </div>
        <div className="bg-yellow-50 p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-yellow-800">Bekleyen</h3>
          <p className="text-2xl font-bold text-yellow-900">{stats.pending}</p>
        </div>
        <div className="bg-red-50 p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-red-800">İptal Edilen</h3>
          <p className="text-2xl font-bold text-red-900">{stats.cancelled}</p>
        </div>
      </div>
    );
  };

  const renderAdminFields = () => {
    if (!user?.isAdmin) return null;

    return (
      <div className="bg-gray-50 p-4 rounded-lg mb-6">
        <h3 className="text-lg font-semibold text-gray-800 mb-4">Admin İstatistikleri</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-purple-50 p-4 rounded-lg">
            <h4 className="text-sm font-medium text-purple-800">Toplam Kullanıcı</h4>
            <p className="text-2xl font-bold text-purple-900">150</p>
          </div>
          <div className="bg-indigo-50 p-4 rounded-lg">
            <h4 className="text-sm font-medium text-indigo-800">Toplam Psikolog</h4>
            <p className="text-2xl font-bold text-indigo-900">25</p>
          </div>
          <div className="bg-pink-50 p-4 rounded-lg">
            <h4 className="text-sm font-medium text-pink-800">Toplam Randevu</h4>
            <p className="text-2xl font-bold text-pink-900">320</p>
          </div>
        </div>
      </div>
    );
  };

  const renderPsychologistFields = () => {
    if (!user?.psychologistId) return null;

    return (
      <>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div>
            <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
              <MdSchool className="mr-2" /> Eğitim
            </label>
            <textarea
              name="education"
              rows="3"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={formData.education}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
              <MdWork className="mr-2" /> Deneyim
            </label>
            <textarea
              name="experience"
              rows="3"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={formData.experience}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div>
            <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
              <MdLanguage className="mr-2" /> Diller
            </label>
            <input
              type="text"
              name="languages"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={formData.languages}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
              <MdWork className="mr-2" /> Uzmanlık Alanları
            </label>
            <input
              type="text"
              name="specializations"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              value={formData.specializations.join(', ')}
              onChange={(e) => handleChange({
                target: {
                  name: 'specializations',
                  value: e.target.value.split(',').map(item => item.trim())
                }
              })}
              disabled={!isEditing}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Toaster position="top-center" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            {/* Header */}
            <div className="px-4 py-5 sm:px-6 flex justify-between items-center border-b border-gray-200">
              <h2 className="text-2xl font-bold text-gray-900">Profil Bilgileri</h2>
              <div className="profile-actions flex space-x-2">
                {isEditing ? (
                  <>
                    <button 
                      onClick={handleSubmit} 
                      className={`${buttonStyles.base} ${buttonStyles.primary}`}
                    >
                      <MdSave className="mr-2" /> Kaydet
                    </button>
                    <button 
                      onClick={() => setIsEditing(false)} 
                      className={`${buttonStyles.base} ${buttonStyles.secondary}`}
                    >
                      <MdClose className="mr-2" /> İptal
                    </button>
                  </>
                ) : (
                  <>
                    <button 
                      onClick={() => setIsEditing(true)} 
                      className={`${buttonStyles.base} ${buttonStyles.primary}`}
                    >
                      <MdEdit className="mr-2" /> Düzenle
                    </button>
                    {user.role === 'psychologist' && (
                      <button 
                        onClick={() => navigate('/psychologist/schedule')} 
                        className={`${buttonStyles.base} ${buttonStyles.schedule}`}
                      >
                        <MdSchedule className="mr-2" /> Program Yönetimi
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>

            {/* İstatistikler */}
            {renderUserStats()}
            {renderAdminFields()}

            {/* Profil Formu */}
            <div className="px-4 py-5 sm:p-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Profil Resmi ve Temel Bilgiler */}
                <div className="mb-8 flex flex-col md:flex-row items-center gap-8">
                  {/* Profil Resmi */}
                  <div className="flex justify-center mb-6">
                    <div className="relative">
                      <img
                        src={getProfileImageUrl(user?.profileImage)}
                        alt={user?.name || 'Profil Resmi'}
                        className="w-32 h-32 rounded-full object-cover border-4 border-gray-200"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/default-avatar.png';
                        }}
                      />
                      <label
                        htmlFor="profile-image"
                        className="absolute bottom-0 right-0 bg-blue-600 rounded-full p-2 cursor-pointer hover:bg-blue-700 transition-colors duration-200"
                      >
                        <MdAddAPhoto className="text-white" />
                      </label>
                      <input
                        type="file"
                        id="profile-image"
                        ref={fileInputRef}
                        className="hidden"
                        accept="image/*"
                        onChange={handleProfileImageUpload}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
                        <MdPerson className="mr-2" /> Ad Soyad
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        value={formData.name}
                        onChange={handleChange}
                        disabled={!isEditing}
                      />
                    </div>
                    <div>
                      <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
                        <MdEmail className="mr-2" /> E-posta
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        value={formData.email}
                        onChange={handleChange}
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                </div>

                {renderPsychologistFields()}

                {/* Psikolog Özel Bilgileri */}
                {user && user.role === 'psychologist' && (
                  <div className="bg-white shadow rounded-lg p-6 mb-6">
                    <h2 className="text-2xl font-semibold mb-4">Psikolog Bilgileri</h2>
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Seans Ücreti (TL)</label>
                        <input
                          type="number"
                          name="sessionFee"
                          value={psychologistData.hourlyRate}
                          onChange={handlePsychologistDataChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">Hakkında</label>
                        <textarea
                          name="about"
                          value={psychologistData.about}
                          onChange={handlePsychologistDataChange}
                          rows="4"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                          placeholder="Kendinizi tanıtın..."
                        />
                      </div>

                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <label className="block text-lg font-medium text-gray-700">Uzmanlık Alanları</label>
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                          {Array.isArray(availableSpecialties) && availableSpecialties.map(specialty => (
                            <div key={specialty._id} className="relative flex items-start p-4 border rounded-lg hover:bg-gray-50">
                              <div className="flex items-center h-5">
                                <input
                                  id={`specialty-${specialty._id}`}
                                  type="checkbox"
                                  checked={Array.isArray(psychologistData.specialties) && 
                                    psychologistData.specialties.some(s => s._id === specialty._id)}
                                  onChange={() => handleSpecialtyChange(specialty._id)}
                                  className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-3">
                                <label htmlFor={`specialty-${specialty._id}`} className="font-medium text-gray-700 cursor-pointer">
                                  {specialty.name}
                                </label>
                                {specialty.description && (
                                  <p className="text-sm text-gray-500 mt-1">{specialty.description}</p>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Eğitim Bilgileri */}
                      <div>
                        <div className="flex justify-between items-center mb-4">
                          <label className="block text-lg font-medium text-gray-700">Eğitim Bilgileri</label>
                          <button
                            type="button"
                            onClick={handleAddEducation}
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Eğitim Ekle
                          </button>
                        </div>
                        
                        {psychologistData.education.map((edu, index) => (
                          <div key={index} className="border rounded-lg p-4 mb-4">
                            <div className="flex justify-between mb-4">
                              <h3 className="text-lg font-medium">Eğitim #{index + 1}</h3>
                              <button
                                type="button"
                                onClick={() => handleRemoveEducation(index)}
                                className="text-red-600 hover:text-red-800"
                              >
                                <MdClose className="h-5 w-5" />
                              </button>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              <div>
                                <label className="block text-sm font-medium text-gray-700">Okul/Üniversite</label>
                                <input
                                  type="text"
                                  value={edu.institution}
                                  onChange={(e) => handleEducationChange(index, 'institution', e.target.value)}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                              </div>
                              <div>
                                <label className="block text-sm font-medium text-gray-700">Derece</label>
                                <select
                                  value={edu.degree}
                                  onChange={(e) => handleEducationChange(index, 'degree', e.target.value)}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                >
                                  <option value="">Seçiniz</option>
                                  <option value="Lisans">Lisans</option>
                                  <option value="Yüksek Lisans">Yüksek Lisans</option>
                                  <option value="Doktora">Doktora</option>
                                  <option value="Uzmanlık">Uzmanlık</option>
                                </select>
                              </div>
                              <div>
                                <label className="block text-sm font-medium text-gray-700">Alan</label>
                                <input
                                  type="text"
                                  value={edu.field}
                                  onChange={(e) => handleEducationChange(index, 'field', e.target.value)}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                              </div>
                              <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                                <div className="flex-1">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">Başlangıç Tarihi</label>
                                  <ConfigProvider locale={locale}>
                                    <DatePicker
                                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                      value={edu.startDate ? dayjs(edu.startDate) : null}
                                      onChange={(date) => handleEducationChange(index, 'startDate', date)}
                                      format="DD/MM/YYYY"
                                      placeholder="Tarih seçin"
                                      showToday
                                      allowClear
                                    />
                                  </ConfigProvider>
                                </div>
                                <div className="flex-1">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">Bitiş Tarihi</label>
                                  <ConfigProvider locale={locale}>
                                    <DatePicker
                                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                      value={edu.endDate ? dayjs(edu.endDate) : null}
                                      onChange={(date) => handleEducationChange(index, 'endDate', date)}
                                      format="DD/MM/YYYY"
                                      placeholder="Tarih seçin"
                                      showToday
                                      allowClear
                                    />
                                  </ConfigProvider>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* İş Deneyimi */}
                      <div>
                        <div className="flex justify-between items-center mb-4">
                          <label className="block text-lg font-medium text-gray-700">İş Deneyimi</label>
                          <button
                            type="button"
                            onClick={handleAddExperience}
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Deneyim Ekle
                          </button>
                        </div>
                        
                        {psychologistData.experiences.map((experience, index) => (
                          <div key={index} className="space-y-4 p-4 bg-white rounded-lg shadow">
                            <div className="flex flex-col space-y-4">
                              <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                                <div className="flex-1">
                                  <label className="block text-sm font-medium text-gray-700">Ünvan</label>
                                  <input
                                    type="text"
                                    value={experience.title || ''}
                                    onChange={(e) => handleExperienceChange(index, 'title', e.target.value)}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                  />
                                </div>
                                <div className="flex-1">
                                  <label className="block text-sm font-medium text-gray-700">Kurum</label>
                                  <input
                                    type="text"
                                    value={experience.company || ''}
                                    onChange={(e) => handleExperienceChange(index, 'company', e.target.value)}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                                <div className="flex-1">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">Başlangıç Tarihi</label>
                                  <ConfigProvider locale={locale}>
                                    <DatePicker
                                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                      value={experience.startDate ? dayjs(experience.startDate) : null}
                                      onChange={(date) => handleExperienceChange(index, 'startDate', date)}
                                      format="DD/MM/YYYY"
                                      placeholder="Tarih seçin"
                                      showToday
                                      allowClear
                                    />
                                  </ConfigProvider>
                                </div>
                                {!experience.current && (
                                  <div className="flex-1">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Bitiş Tarihi</label>
                                    <ConfigProvider locale={locale}>
                                      <DatePicker
                                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                        value={experience.endDate ? dayjs(experience.endDate) : null}
                                        onChange={(date) => handleExperienceChange(index, 'endDate', date)}
                                        format="DD/MM/YYYY"
                                        placeholder="Tarih seçin"
                                        showToday
                                        allowClear
                                      />
                                    </ConfigProvider>
                                  </div>
                                )}
                              </div>

                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  checked={experience.current || false}
                                  onChange={(e) => handleExperienceChange(index, 'current', e.target.checked)}
                                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                />
                                <label className="ml-2 block text-sm text-gray-700">
                                  Halen devam ediyor
                                </label>
                              </div>

                              <div className="flex-1">
                                <label className="block text-sm font-medium text-gray-700">Açıklama</label>
                                <textarea
                                  value={experience.description || ''}
                                  onChange={(e) => handleExperienceChange(index, 'description', e.target.value)}
                                  rows={3}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                              </div>

                              <div className="flex justify-end">
                                <button
                                  type="button"
                                  onClick={() => handleRemoveExperience(index)}
                                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                  <MdClose className="mr-2 -ml-0.5 h-4 w-4" /> Deneyimi Sil
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                          type="submit"
                          disabled={isLoading}
                          className={`rounded-md bg-blue-600 px-6 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ${
                            isLoading ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                        >
                          {isLoading ? (
                            <span className="flex items-center">
                              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                              Güncelleniyor...
                            </span>
                          ) : (
                            'Tüm Bilgileri Güncelle'
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
