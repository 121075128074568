import axios from 'axios';
import store from '../store';

// API URL'sini ortam değişkeninden al
const API_URL = import.meta.env.VITE_API_URL || 'https://terapiyo.com';

// Axios instance oluştur
const instance = axios.create({
  baseURL: API_URL + '/api',
  timeout: 10000,
  headers: {
    'Accept': 'application/json'
  }
});

// İstek öncesi çalışacak interceptor
instance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const { token, user } = state.auth;

    console.log('\n=== Axios Request Interceptor ===');
    console.log('URL:', config.url);
    console.log('Method:', config.method);
    console.log('Headers:', config.headers);
    console.log('Data:', config.data);
    console.log('====================\n');

    if (token) {
      // Token'ın başında Bearer yoksa ekle
      const tokenWithBearer = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
      config.headers.Authorization = tokenWithBearer;
    }

    return config;
  },
  (error) => {
    console.error('\n=== Axios Request Error ===');
    console.error('Error:', error);
    console.error('====================\n');
    return Promise.reject(error);
  }
);

// İstek sonrası çalışacak interceptor
instance.interceptors.response.use(
  (response) => {
    console.log('\n=== Axios Response Interceptor ===');
    console.log('Status:', response.status);
    console.log('Data:', response.data);
    console.log('====================\n');
    return response;
  },
  (error) => {
    console.error('\n=== Axios Response Error ===');
    console.error('Status:', error.response?.status);
    console.error('Data:', error.response?.data);
    console.error('Error:', error.message);
    console.error('====================\n');
    return Promise.reject(error);
  }
);

export default instance;
