import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Context Providers
import { AuthProvider } from './contexts/AuthContext';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { ChatProvider } from './contexts/ChatContext';

// Redux Actions
import { getCurrentUser } from './store/slices/authSlice';

// Routes
import AppRoutes from './routes/index';

// Material UI tema ayarları
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3182CE',
    },
    background: {
      default: '#F7FAFC',
    },
  },
  typography: {
    fontFamily: 'inherit',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
          borderRadius: '0.5rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
          borderRadius: '0.5rem',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            fontWeight: 600,
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
          },
          '& .MuiTableCell-body': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
          },
        },
      },
    },
  },
});

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('\n=== App.jsx Token Kontrolü ===');
    console.log('Token:', token ? 'Mevcut' : 'Yok');
    
    if (token) {
      dispatch(getCurrentUser());
    }
    
    console.log('====================\n');
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HelmetProvider>
        <Router>
          <AuthProvider>
            <WebSocketProvider>
              <ChatProvider>
                <AppRoutes />
                <ToastContainer />
              </ChatProvider>
            </WebSocketProvider>
          </AuthProvider>
        </Router>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default App;
