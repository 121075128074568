import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Rating,
  Box,
  Typography,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { FaUserTie, FaComments, FaBrain, FaStar } from 'react-icons/fa';
import axios from '../utils/axios';

const RatingSection = ({ title, value, icon: Icon, onChange }) => (
  <div className="bg-gray-50 rounded-xl p-4">
    <label className="flex items-center text-sm font-medium text-gray-700 mb-3">
      <Icon className="text-blue-600 mr-2" size={20} />
      {title}
    </label>
    <div className="flex items-center justify-between">
      <Rating
        value={value}
        onChange={(_, newValue) => onChange(newValue)}
        size="large"
        precision={0.5}
      />
      <span className="ml-3 text-sm font-medium text-gray-600">
        {value > 0 ? `${value}/5` : 'Puan verin'}
      </span>
    </div>
  </div>
);

const ReviewEditDialog = ({ open, onClose, review, onReviewUpdate }) => {
  const [ratings, setRatings] = useState({
    professionalism: review?.rating?.professionalism || 0,
    communication: review?.rating?.communication || 0,
    expertise: review?.rating?.expertise || 0,
    overall: review?.rating?.overall || 0
  });

  const [comment, setComment] = useState(review?.comment || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    const { professionalism, communication, expertise } = ratings;
    const average = ((professionalism + communication + expertise) / 3).toFixed(1);
    setRatings(prev => ({ ...prev, overall: Number(average) }));
  }, [ratings.professionalism, ratings.communication, ratings.expertise]);

  useEffect(() => {
    if (open && review) {
      setRatings({
        professionalism: review.rating?.professionalism || 0,
        communication: review.rating?.communication || 0,
        expertise: review.rating?.expertise || 0,
        overall: review.rating?.overall || 0
      });
      setComment(review.comment || '');
    }
  }, [open, review]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.put(`/api/reviews/${review._id}`, {
        rating: ratings,
        comment,
        isAnonymous: false
      });

      if (response.data.success) {
        setSnackbar({
          open: true,
          message: 'Değerlendirme başarıyla güncellendi',
          severity: 'success'
        });
        onReviewUpdate(response.data.data);
        onClose();
      }
    } catch (error) {
      console.error('Review update error:', error);
      setError(error.response?.data?.message || 'Değerlendirme güncellenirken bir hata oluştu');
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'Değerlendirme güncellenirken bir hata oluştu',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Değerlendirmeyi Düzenle</Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <div className="space-y-4 mb-6">
            <RatingSection 
              title="Profesyonellik" 
              value={ratings.professionalism}
              icon={FaUserTie}
              onChange={(value) => setRatings(prev => ({ ...prev, professionalism: value }))}
            />
            <RatingSection 
              title="İletişim" 
              value={ratings.communication}
              icon={FaComments}
              onChange={(value) => setRatings(prev => ({ ...prev, communication: value }))}
            />
            <RatingSection 
              title="Uzmanlık" 
              value={ratings.expertise}
              icon={FaBrain}
              onChange={(value) => setRatings(prev => ({ ...prev, expertise: value }))}
            />
          </div>

          <div className="bg-gray-50 rounded-xl p-4">
            <label className="flex items-center text-sm font-medium text-gray-700 mb-3">
              <FaStar className="text-blue-600 mr-2" size={20} />
              Genel Değerlendirme
            </label>
            <div className="flex items-center justify-between">
              <Rating
                value={ratings.overall}
                readOnly
                size="large"
                precision={0.5}
              />
              <span className="ml-3 text-sm font-medium text-gray-600">
                {ratings.overall > 0 ? `${ratings.overall}/5` : 'Diğer puanları girin'}
              </span>
            </div>
          </div>

          <div className="bg-gray-50 rounded-xl p-4 mt-4">
            <label className="flex items-center text-sm font-medium text-gray-700 mb-3">
              <FaComments className="text-blue-600 mr-2" size={20} />
              Yorumunuz
            </label>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Deneyiminizi paylaşın..."
              variant="outlined"
              sx={{
                backgroundColor: 'white',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgb(229, 231, 235)',
                  },
                }
              }}
            />
          </div>
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Button 
            onClick={onClose}
            variant="outlined"
            color="inherit"
            sx={{ borderRadius: 2 }}
          >
            İptal
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading || !Object.values(ratings).every(r => r > 0) || !comment.trim()}
            sx={{ 
              borderRadius: 2,
              backgroundColor: 'rgb(59, 130, 246)',
              '&:hover': {
                backgroundColor: 'rgb(29, 78, 216)',
              }
            }}
          >
            {loading ? 'Güncelleniyor...' : 'Güncelle'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ReviewEditDialog;