import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { FaStar, FaArrowLeft, FaUserTie, FaComments, FaBrain, FaUserCircle } from 'react-icons/fa';
import axios from '../utils/axios';
import { toast } from 'react-toastify';
import { Edit as EditIcon } from '@mui/icons-material';
import ReviewEditDialog from '../components/ReviewEditDialog';
import ReviewCard from '../components/ReviewCard';

// RatingSection bileşenini güncelle
const RatingSection = ({ title, value, onChange, icon: Icon }) => (
  <div className="p-4 bg-gray-50 rounded-xl">
    <div className="flex items-center gap-2 mb-3">
      <div className="w-8 h-8 rounded-lg bg-blue-50 flex items-center justify-center flex-shrink-0">
        <Icon size={18} className="text-blue-600" />
      </div>
      <label className="text-sm font-medium text-gray-700 truncate">{title}</label>
    </div>
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
      <div className="flex gap-1">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            type="button"
            key={star}
            onClick={() => onChange(star)}
            className="focus:outline-none transition-transform hover:scale-110"
          >
            <FaStar
              size={24}
              className={`${star <= value ? "text-yellow-400" : "text-gray-200"} transition-colors duration-200`}
            />
          </button>
        ))}
      </div>
      <span className="text-sm font-medium text-gray-600">
        {value > 0 ? `${value}/5` : 'Puan seçiniz'}
      </span>
    </div>
  </div>
);

// RatingStats bileşenini güncelle
const RatingStats = ({ title, rating, icon: Icon }) => {
  if (!rating) return null;
  
  return (
    <div className="flex items-center justify-between p-3">
      <div className="flex items-center gap-3 min-w-0">
        <div className="w-10 h-10 rounded-xl bg-blue-50 flex-shrink-0 flex items-center justify-center">
          <Icon size={20} className="text-blue-600" />
        </div>
        <div className="min-w-0">
          <span className="text-sm font-medium text-gray-600 block truncate">{title}</span>
          <div className="flex items-center gap-1 mt-1">
            <span className="text-lg font-bold text-blue-600">{rating.average.toFixed(1)}</span>
            <div className="flex">
              {[...Array(5)].map((_, i) => (
                <FaStar
                  key={i}
                  size={12}
                  className={`${i < rating.average ? "text-yellow-400" : "text-gray-200"}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PsychologistReviews = () => {
  const navigate = useNavigate(); // Hook'u bileşenin en üstünde tanımla
  const location = useLocation();
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [psychologistData, setPsychologistData] = useState(null);
  const [ratings, setRatings] = useState({
    professionalism: 0,
    communication: 0,
    expertise: 0,
    overall: 0
  });
  const [comment, setComment] = useState('');
  const [reviews, setReviews] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState('');
  const [stats, setStats] = useState(null);

  useEffect(() => {
    fetchPsychologist();
    fetchReviews();
    if (user && user?._id) {
      fetchUserAppointments();
    }
  }, [id, user]);

  const fetchUserAppointments = async () => {
    if (!user || !user?._id) {
      return;
    }

    try {
      console.log('Fetching appointments for review...', { userId: user?._id, psychologistId: id });
      
      const { data } = await axios.get('/api/appointments/completed-for-review', {
        params: {
          userId: user._id,
          psychologistId: id
        }
      });
      
      console.log('Appointments for review:', data);
      setAppointments(data.data || []);
    } catch (error) {
      console.error('Appointments fetch error:', error);
      toast.error('Randevu bilgileri yüklenemedi');
    }
  };

  const fetchPsychologist = async () => {
    try {
      const { data } = await axios.get(`/api/psychologists/${id}`);
      setPsychologistData(data.data);
    } catch (error) {
      toast.error('Psikolog bilgileri yüklenemedi');
    }
  };

  const fetchReviews = async () => {
    try {
      console.log('Fetching reviews for psychologist:', id);
      const { data } = await axios.get(`/api/reviews/psychologist/${id}`);
      if (data.success) {
        // Yorumları ID'ye göre benzersiz hale getir
        const uniqueReviews = data.data.reviews.reduce((acc, current) => {
          if (!acc.find(item => item._id === current._id)) {
            acc.push(current);
          }
          return acc;
        }, []);

        console.log('Unique reviews count:', uniqueReviews.length);
        setReviews(uniqueReviews);
        setStats(data.data.stats);
      }
    } catch (error) {
      console.error('Error fetching reviews:', error);
      toast.error('Yorumlar yüklenemedi');
    }
  };

  const handleSubmitReview = async (e) => {
    e.preventDefault();
    
    if (!user || !user?._id) {
      toast.error('Lütfen giriş yapınız');
      return;
    }

    if (!selectedAppointment) {
      toast.error('Lütfen değerlendirmek istediğiniz görüşmeyi seçin');
      return;
    }

    // Convert ratings to numbers and validate
    const parsedRatings = {
      professionalism: Number(ratings.professionalism),
      communication: Number(ratings.communication),
      expertise: Number(ratings.expertise),
      overall: Number(ratings.overall)
    };

    const hasValidRatings = Object.entries(parsedRatings).every(([key, value]) => {
      const isValid = !isNaN(value) && value >= 1 && value <= 5;
      if (!isValid) {
        toast.error(`Geçersiz ${key} değeri. 1-5 arası bir değer olmalıdır.`);
      }
      return isValid;
    });

    if (!hasValidRatings) return;

    try {
      const reviewData = {
        psychologist: id,
        user: user._id,
        appointment: selectedAppointment,
        rating: parsedRatings,
        comment
      };

      const response = await axios.post('/api/reviews', reviewData);

      if (response.data.success) {
        toast.success('Değerlendirmeniz başarıyla eklendi');
        setRatings({
          professionalism: 0,
          communication: 0,
          expertise: 0,
          overall: 0
        });
        setComment('');
        setSelectedAppointment('');
        fetchReviews(); // Only fetch reviews after successful submission
      }
    } catch (error) {
      console.error('Review submit error:', error);
      toast.error(error.response?.data?.message || 'Değerlendirme eklenirken bir hata oluştu');
    }
  };

  const handleReviewUpdate = (updatedReview) => {
    setReviews(prevReviews => 
      prevReviews.map(review => 
        review._id === updatedReview._id ? updatedReview : review
      )
    );
    // İstatistikleri yeniden yükle
    fetchReviews();
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <div className="flex items-center justify-between mb-8">
      <button 
          onClick={() => {
            // Eğer önceki sayfa varsa oraya git, yoksa psikolog detay sayfasına git
            if (location.key !== "default") {
              navigate(-1);
            } else {
              navigate(`/psychologist/${id}`);
            }
          }}
          className="flex items-center text-gray-600 hover:text-blue-600 transition-colors duration-200"
        >
          <FaArrowLeft className="mr-2" />
          <span>Geri Dön</span>
        </button>
        {psychologistData && (
          <div className="flex items-center">
            <img
              src={psychologistData.profileImage || '/default-avatar.png'}
              alt={psychologistData.name}
              className="w-12 h-12 rounded-full mr-4 border-2 border-gray-100"
            />
            <div>
              <h1 className="text-xl font-bold text-gray-800">
                {psychologistData.name} {psychologistData.surname}
              </h1>
              <div className="flex items-center mt-1">
                <div className="flex mr-2">
                  {[...Array(5)].map((_, index) => (
                    <FaStar
                      key={index}
                      size={16}
                      className="mr-0.5"
                      color={index < (psychologistData.rating || 0) ? "#ffc107" : "#e4e5e9"}
                    />
                  ))}
                </div>
                <span className="text-sm text-gray-600">
                  {psychologistData.rating ? `${psychologistData.rating.toFixed(1)} / 5` : 'Henüz değerlendirme yok'}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Stats container'ı güncelle */}
      {stats && (
        <div className="bg-white p-4 rounded-2xl mb-8 shadow-sm border border-gray-100">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 divide-y sm:divide-y-0 sm:divide-x divide-gray-100">
            <RatingStats title="Genel Değerlendirme" rating={stats.ratings.overall} icon={FaStar} />
            <RatingStats title="Uzmanlık Seviyesi" rating={stats.ratings.expertise} icon={FaBrain} />
            <RatingStats title="İletişim Becerisi" rating={stats.ratings.communication} icon={FaComments} />
            <RatingStats title="Profesyonellik" rating={stats.ratings.professionalism} icon={FaUserTie} />
          </div>
        </div>
      )}

      {/* Değerlendirme form bölümünü güncelle */}
      {user && appointments.length > 0 ? (
        <div className="bg-white p-8 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 mb-8">
          <div className="flex items-center mb-6">
            <FaStar className="text-blue-600 mr-3" size={24} />
            <h3 className="text-xl font-semibold text-gray-800">Değerlendirme Yap</h3>
          </div>
          
          <form onSubmit={handleSubmitReview} className="space-y-6">
            <div className="bg-gray-50 rounded-xl p-4 mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Değerlendirmek İstediğiniz Görüşme
              </label>
              <select
                value={selectedAppointment}
                onChange={(e) => setSelectedAppointment(e.target.value)}
                className="w-full p-3 bg-white border border-gray-200 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all duration-200"
                required
              >
                <option value="">Görüşme seçiniz</option>
                {appointments.map(apt => (
                  <option key={apt._id} value={apt._id}>
                    {new Date(apt.date).toLocaleDateString('tr-TR', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              <RatingSection 
                title="Profesyonellik" 
                value={ratings.professionalism}
                icon={FaUserTie}
                onChange={(value) => {
                  setRatings(prev => {
                    const newRatings = { ...prev, professionalism: value };
                    // 1 ondalık basamakla hesapla
                    const overall = Number(
                      ((newRatings.professionalism + newRatings.communication + newRatings.expertise) / 3)
                      .toFixed(1)
                    );
                    return { ...newRatings, overall };
                  });
                }}
              />
              <RatingSection 
                title="İletişim" 
                value={ratings.communication}
                icon={FaComments}
                onChange={(value) => {
                  setRatings(prev => {
                    const newRatings = { ...prev, communication: value };
                    const overall = Number(
                      ((newRatings.professionalism + newRatings.communication + newRatings.expertise) / 3)
                      .toFixed(1)
                    );
                    return { ...newRatings, overall };
                  });
                }}
              />
              <RatingSection 
                title="Uzmanlık" 
                value={ratings.expertise}
                icon={FaBrain}
                onChange={(value) => {
                  setRatings(prev => {
                    const newRatings = { ...prev, expertise: value };
                    const overall = Number(
                      ((newRatings.professionalism + newRatings.communication + newRatings.expertise) / 3)
                      .toFixed(1)
                    );
                    return { ...newRatings, overall };
                  });
                }}
              />
            </div>

            <div className="bg-gray-50 rounded-xl p-4">
              <label className="flex items-center text-sm font-medium text-gray-700 mb-3">
                <FaStar className="text-blue-600 mr-2" size={20} />
                Genel Değerlendirme
              </label>
              <div className="flex items-center justify-between">
                <div className="flex gap-1">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <FaStar
                      key={star}
                      size={24}
                      className={`${star <= ratings.overall ? "text-yellow-400" : "text-gray-200"}`}
                    />
                  ))}
                </div>
                <span className="ml-3 text-sm font-medium text-gray-600">
                  {ratings.overall > 0 ? `${ratings.overall}/5` : 'Diğer puanları girin'}
                </span>
              </div>
            </div>

            <div className="bg-gray-50 rounded-xl p-4">
              <label className="flex items-center text-sm font-medium text-gray-700 mb-3">
                <FaComments className="text-blue-600 mr-2" size={20} />
                Yorumunuz
              </label>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="w-full p-4 bg-white border border-gray-200 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all duration-200"
                placeholder="Deneyiminizi paylaşın..."
                rows="4"
                required
              />
            </div>

            <button
              type="submit"
              disabled={
                !selectedAppointment || 
                !Object.values(ratings).every(r => r > 0) || 
                !comment
              }
              className="w-full px-6 py-3 bg-blue-600 text-white rounded-xl font-medium
                      hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed
                      transition-all duration-200 transform hover:scale-[1.02]"
            >
              Değerlendirme Gönder
            </button>
          </form>
        </div>
      ) : (
        <div className="bg-blue-50 border border-blue-200 rounded-xl p-6 mb-8">
          <div className="flex items-center text-blue-700">
            <FaStar className="mr-2" size={20} />
            <span>Değerlendirme yapabilmek için tamamlanmış bir görüşmeniz olmalıdır.</span>
          </div>
        </div>
      )}

      {/* Reviews List */}
      <div className="space-y-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-6">Değerlendirmeler</h3>
        {Array.isArray(reviews) && reviews.length > 0 ? (
          reviews.map((review) => (
            <ReviewCard 
              key={review._id} 
              review={review}
              isUserReview={review.user?._id === user?._id}
              onReviewUpdate={handleReviewUpdate}
            />
          ))
        ) : (
          <div className="bg-gray-50 border border-gray-200 rounded-xl p-8 text-center text-gray-600">
            Henüz değerlendirme yapılmamış.
          </div>
        )}
      </div>
    </div>
  );
};

export default PsychologistReviews;