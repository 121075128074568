import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

// Layout Components
import Layout from '../components/Layout';
import PrivateRoute from '../components/PrivateRoute';
import RequirePsychologist from '../components/RequirePsychologist';
import AdminLayout from '../layouts/AdminLayout';
import AdminRoute from '../components/routing/AdminRoute';

// Pages
import Home from '../pages/Home';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Profile from '../pages/Profile';
import VideoCall from '../pages/VideoCall';
import Appointments from '../pages/Appointments';
import Dashboard from '../pages/Dashboard';
import MessagesPage from '../pages/MessagesPage';
import Notifications from '../pages/Notifications';
import PsychologistAppointments from '../pages/PsychologistAppointments';
import PsychologistSchedule from '../pages/PsychologistSchedule';
import PsychologistContent from '../pages/PsychologistContent';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Blog from '../pages/Blog';
import BlogDetail from '../pages/BlogDetail';
import PsychologistList from '../pages/PsychologistList';
import PsychologistDetail from '../pages/PsychologistDetail';
import JoinPsychologistTeam from '../pages/JoinPsychologistTeam';
import ApplicationSuccess from '../pages/ApplicationSuccess';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import VerifyEmail from '../pages/VerifyEmail';
import PsychologistReviews from '../pages/PsychologistReviews';

// Admin Pages
import UserList from '../pages/admin/UserList';
import UserDetails from '../pages/admin/UserDetails';
import ApplicationDetails from '../pages/admin/ApplicationDetails';
import AdminDashboard from '../pages/admin/Dashboard';
import Users from '../pages/admin/Users';
import Applications from '../pages/admin/Applications';

const AppRoutes = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <Routes>
      <Route element={<Layout />}>
        {/* Public Routes */}
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="login" element={!user ? <Login /> : <Navigate to="/" />} />
        <Route path="register" element={!user ? <Register /> : <Navigate to="/" />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
        <Route path="verify-email/:token" element={<VerifyEmail />} />
        <Route path="psychologists" element={<PsychologistList />} />
        <Route path="psychologists/:id" element={<PsychologistDetail />} />
        <Route path="join-psychologist-team" element={<JoinPsychologistTeam />} />
        <Route path="application-success" element={<ApplicationSuccess />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:id" element={<BlogDetail />} />
        <Route path="psychologist-reviews/:id" element={<PsychologistReviews />} />

        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route path="profile" element={<Profile />} />
          <Route path="appointments" element={<Appointments />} />
          <Route path="video-call/:appointmentId" element={<VideoCall />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="messages" element={<MessagesPage />} />
        </Route>
        
        {/* Psychologist Routes */}
        <Route path="psychologist" element={<RequirePsychologist />}>
          <Route path="appointments" element={<PsychologistAppointments />} />
          <Route path="schedule" element={<PsychologistSchedule />} />
          <Route path="content" element={<PsychologistContent />} />
          <Route path="video-call/:appointmentId" element={<VideoCall />} />
        </Route>
      </Route>

      {/* Admin Routes */}
      <Route path="admin" element={<AdminLayout />}>
        <Route index element={
          <AdminRoute>
            <AdminDashboard />
          </AdminRoute>
        } />
        <Route path="users" element={
          <AdminRoute>
            <Users />
          </AdminRoute>
        } />
        <Route path="applications" element={
          <AdminRoute>
            <Applications />
          </AdminRoute>
        } />
        <Route path="applications/:id" element={
          <AdminRoute>
            <ApplicationDetails />
          </AdminRoute>
        } />
        <Route path="users/:id" element={
          <AdminRoute>
            <UserDetails />
          </AdminRoute>
        } />
      </Route>

      {/* Catch-all Route */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
