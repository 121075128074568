import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../utils/axios';
import ReactMarkdown from 'react-markdown';

const BlogDetail = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/articles/${id}`);
        setArticle(response.data.data);
      } catch (error) {
        console.error('Blog yazısı yüklenirken hata:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Yükleniyor...
            </h2>
          </div>
        </div>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Yazı bulunamadı
            </h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <article className="max-w-3xl mx-auto">
        <div className="mb-8">
          <img
            className="w-full h-64 object-cover rounded-lg"
            src={article.image || 'https://via.placeholder.com/1200x400'}
            alt={article.title}
          />
        </div>

        <div className="prose prose-lg max-w-none">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">{article.title}</h1>

          <div className="flex items-center mb-8">
            <img
              className="h-12 w-12 rounded-full"
              src={article.author?.profileImage || 'https://via.placeholder.com/48x48'}
              alt={article.author?.name}
            />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-900">{article.author?.name}</p>
              <div className="flex space-x-1 text-sm text-gray-500">
                <time dateTime={article.createdAt}>
                  {new Date(article.createdAt).toLocaleDateString('tr-TR')}
                </time>
                <span aria-hidden="true">&middot;</span>
                <span>{article.readingTime} dk okuma</span>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <ReactMarkdown>{article.content}</ReactMarkdown>
          </div>
        </div>
      </article>
    </div>
  );
};

export default BlogDetail;
