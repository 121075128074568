import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RequirePsychologist = () => {
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const location = useLocation();

  console.log('\n=== RequirePsychologist Kontrolü ===');
  console.log('İstek yapılan sayfa:', location.pathname);
  console.log('Kullanıcı:', user);
  console.log('Kimlik doğrulandı mı:', isAuthenticated);
  console.log('====================\n');

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (user?.role !== 'psychologist') {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default RequirePsychologist;
