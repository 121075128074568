import React, { useState, useRef, useEffect } from 'react';
import { FiPlus, FiImage, FiVideo, FiEdit2, FiTrash2, FiEye, FiX } from 'react-icons/fi';
import { MdArticle } from 'react-icons/md';
import Editor from '../components/Editor';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import {
  fetchPsychologistContents,
  createContent,
  updateContent,
  deleteContent,
  resetContentStatus,
} from '../store/slices/contentSlice';

const PsychologistContent = () => {
  const dispatch = useDispatch();
  const { contents, loading, error, success } = useSelector((state) => state.content);
  const [activeTab, setActiveTab] = useState('articles');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [contentType, setContentType] = useState('article');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingContent, setEditingContent] = useState(null);
  const fileInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const editorRef = useRef(null);

  // İçerikleri gruplara ayır
  const groupedContents = {
    articles: contents?.filter((item) => item?.type === 'article') || [],
    videos: contents?.filter((item) => item?.type === 'video') || [],
  };

  useEffect(() => {
    dispatch(fetchPsychologistContents());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(resetContentStatus());
    }
    if (success) {
      dispatch(resetContentStatus());
    }
  }, [error, success, dispatch]);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Boyut kontrolü
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        toast.error('Dosya boyutu 5MB\'dan küçük olmalıdır');
        return;
      }

      // Uzantı kontrolü
      const allowedExtensions = ['.jpg', '.jpeg', '.png', '.webp'];
      const fileExt = '.' + file.name.split('.').pop().toLowerCase();
      if (!allowedExtensions.includes(fileExt)) {
        toast.error('Sadece .jpg, .jpeg, .png ve .webp uzantılı resim dosyaları yüklenebilir');
        return;
      }

      setThumbnail(file);
    }
  };

  const handleVideoSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Boyut kontrolü
      if (file.size > 100 * 1024 * 1024) { // 100MB limit
        toast.error('Video boyutu 100MB\'dan küçük olmalıdır');
        return;
      }

      // Uzantı kontrolü
      const allowedExtensions = ['.mp4', '.webm', '.ogg'];
      const fileExt = '.' + file.name.split('.').pop().toLowerCase();
      if (!allowedExtensions.includes(fileExt)) {
        toast.error('Sadece .mp4, .webm ve .ogg uzantılı video dosyaları yüklenebilir');
        return;
      }

      setVideoFile(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Title kontrolü
      if (!title.trim()) {
        toast.error('Lütfen başlık giriniz');
        return;
      }

      const formData = new FormData();
      formData.append('title', title);
      formData.append('type', contentType);

      if (contentType === 'article') {
        if (!content) {
          toast.error('Lütfen makale içeriğini giriniz');
          return;
        }
        formData.append('content', content);
      } else if (contentType === 'video') {
        // Video içeriği kontrolü - ya dosya ya da URL olmalı
        if (videoFile) {
          formData.append('videoFile', videoFile);
        } else if (videoUrl) {
          formData.append('videoUrl', videoUrl);
        } else {
          toast.error('Lütfen bir video yükleyin veya video URL\'si girin');
          return;
        }
      }

      if (thumbnail) {
        formData.append('thumbnail', thumbnail);
      }

      if (isEditing && editingContent) {
        await dispatch(updateContent({ id: editingContent._id, formData })).unwrap();
        toast.success('İçerik başarıyla güncellendi');
      } else {
        await dispatch(createContent(formData)).unwrap();
        toast.success('İçerik başarıyla oluşturuldu');
      }

      setShowCreateModal(false);
      resetForm();
    } catch (err) {
      console.error('İçerik işlemi hatası:', err);
      toast.error(err.error || (isEditing ? 'İçerik güncellenirken bir hata oluştu' : 'İçerik oluşturulurken bir hata oluştu'));
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Bu içeriği silmek istediğinizden emin misiniz?')) {
      try {
        await dispatch(deleteContent(id)).unwrap();
        toast.success('İçerik başarıyla silindi');
      } catch (err) {
        toast.error(err.error || 'İçerik silinirken bir hata oluştu');
      }
    }
  };

  const handleEdit = (item) => {
    setEditingContent(item);
    setTitle(item.title);
    setContent(item.type === 'article' ? item.content : '');
    setVideoUrl(item.type === 'video' && item.videoUrl ? item.videoUrl : '');
    setContentType(item.type);
    setIsEditing(true);
    setShowCreateModal(true);
  };

  const handlePreview = (item) => {
    console.log(item);
  };

  const resetForm = () => {
    setTitle('');
    setContent('');
    setThumbnail(null);
    setVideoUrl('');
    setVideoFile(null);
    setIsEditing(false);
    setEditingContent(null);
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean']
    ],
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-6">
            <h1 className="text-2xl font-semibold text-gray-900">İçerik Yönetimi</h1>
            <p className="mt-1 text-sm text-gray-500">Makalelerinizi ve videolarınızı buradan yönetebilirsiniz.</p>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div>
          {/* Tabs ve Create Button */}
          <div className="flex justify-between items-center mb-6">
            <div className="flex space-x-4">
              <button
                onClick={() => setActiveTab('articles')}
                className={`px-4 py-2 rounded-lg text-sm font-medium ${
                  activeTab === 'articles'
                    ? 'bg-blue-50 text-blue-700'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Makaleler
              </button>
              <button
                onClick={() => setActiveTab('videos')}
                className={`px-4 py-2 rounded-lg text-sm font-medium ${
                  activeTab === 'videos'
                    ? 'bg-blue-50 text-blue-700'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Videolar
              </button>
            </div>

            <button
              onClick={() => setShowCreateModal(true)}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <FiPlus className="w-5 h-5 mr-2" />
              Yeni İçerik
            </button>
          </div>

          {/* İçerik Listesi */}
          <div className="bg-white rounded-xl shadow-sm">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
              {groupedContents[activeTab].map((item) => (
                <div key={item._id} className="bg-white border rounded-xl overflow-hidden group">
                  <div className="relative aspect-video">
                    {item.type === 'video' && item.videoFile ? (
                      <video
                        src={`${import.meta.env.VITE_API_URL}${item.videoFile}`}
                        className="w-full h-full object-cover"
                        controls
                        controlsList="nodownload"
                        preload="metadata"
                      >
                        <source src={`${import.meta.env.VITE_API_URL}${item.videoFile}`} type="video/mp4" />
                        Tarayıcınız video oynatmayı desteklemiyor.
                      </video>
                    ) : item.type === 'video' && item.videoUrl ? (
                      <iframe
                        src={item.videoUrl.replace('watch?v=', 'embed/')}
                        className="w-full h-full"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    ) : (
                      <img
                        src={item.thumbnail.startsWith('/') ? `${import.meta.env.VITE_API_URL}${item.thumbnail}` : item.thumbnail}
                        alt={item.title}
                        className="w-full h-full object-cover"
                      />
                    )}
                    <div className="absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center gap-2">
                      <button
                        onClick={() => handleEdit(item)}
                        className="p-2 bg-white/10 hover:bg-white/20 rounded-full text-white transition-colors"
                      >
                        <FiEdit2 className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handlePreview(item)}
                        className="p-2 bg-white/10 hover:bg-white/20 rounded-full text-white transition-colors"
                      >
                        <FiEye className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(item._id)}
                        className="p-2 bg-white/10 hover:bg-white/20 rounded-full text-white transition-colors"
                      >
                        <FiTrash2 className="w-5 h-5" />
                      </button>
                    </div>
                    {item.type === 'video' && (
                      <div className="absolute top-2 right-2 px-2 py-1 bg-red-500 text-white text-xs rounded">
                        Video
                      </div>
                    )}
                  </div>
                  <div className="p-4">
                    <h3 className="font-medium text-gray-900">{item.title}</h3>
                    {item.type === 'article' && (
                      <div className="mt-2 text-sm text-gray-600 line-clamp-3" dangerouslySetInnerHTML={{ __html: item.content }} />
                    )}
                    <div className="flex items-center justify-between mt-2 text-sm text-gray-500">
                      <span>{item.views || 0} görüntülenme</span>
                      <span>{format(new Date(item.createdAt), 'dd MMMM yyyy', { locale: tr })}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Create/Edit Modal */}
          {showCreateModal && (
            <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
              <div className="bg-white rounded-lg w-full max-w-2xl p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-semibold">
                    {isEditing ? 'İçeriği Düzenle' : 'Yeni İçerik Oluştur'}
                  </h2>
                  <button onClick={() => setShowCreateModal(false)} className="text-gray-500 hover:text-gray-700">
                    <FiX size={24} />
                  </button>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Başlık</label>
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">İçerik Türü</label>
                    <select
                      value={contentType}
                      onChange={(e) => setContentType(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    >
                      <option value="article">Makale</option>
                      <option value="video">Video</option>
                    </select>
                  </div>

                  {contentType === 'article' ? (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">İçerik</label>
                      <Editor
                        ref={editorRef}
                        value={content}
                        onChange={setContent}
                        modules={modules}
                        className="bg-white"
                      />
                    </div>
                  ) : (
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Video Yükle
                        </label>
                        <div
                          onClick={() => videoInputRef.current?.click()}
                          className={`border-2 border-dashed rounded-xl p-4 text-center cursor-pointer hover:border-blue-500 transition-colors ${
                            videoFile ? 'border-blue-500' : 'border-gray-300'
                          }`}
                        >
                          {videoFile ? (
                            <div className="flex items-center justify-center gap-2">
                              <FiVideo className="w-6 h-6 text-blue-500" />
                              <span className="text-sm text-gray-600">{videoFile.name}</span>
                            </div>
                          ) : (
                            <div className="flex flex-col items-center py-4">
                              <FiVideo className="w-8 h-8 text-gray-400" />
                              <p className="mt-2 text-sm text-gray-500">
                                Video yüklemek için tıklayın veya sürükleyin
                              </p>
                              <p className="text-xs text-gray-400 mt-1">
                                (Maksimum boyut: 100MB)
                              </p>
                            </div>
                          )}
                          <input
                            type="file"
                            ref={videoInputRef}
                            onChange={handleVideoSelect}
                            accept="video/*"
                            className="hidden"
                          />
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Veya Video URL'si
                        </label>
                        <input
                          type="text"
                          value={videoUrl}
                          onChange={(e) => setVideoUrl(e.target.value)}
                          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          placeholder="YouTube veya Vimeo video URL'si"
                        />
                      </div>
                    </div>
                  )}

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Kapak Görseli</label>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileSelect}
                      accept="image/*"
                      className="w-full"
                    />
                  </div>

                  <div className="flex justify-end space-x-3">
                    <button
                      type="button"
                      onClick={() => setShowCreateModal(false)}
                      className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    >
                      {isEditing ? 'Güncelle' : 'Oluştur'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PsychologistContent;
