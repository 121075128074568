import React, { useState, useRef } from 'react';
import { useChat } from '../../contexts/ChatContext';
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
  Typography,
  Tooltip,
  LinearProgress,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Send, AttachFile, Close as CloseIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';

const MessageInput = () => {
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef();
  const { activeConversation, sendMessage } = useChat();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim() && !selectedFile) return;

    try {
      setUploading(true);
      const formData = new FormData();
      
      if (selectedFile) {
        formData.append('messageAttachment', selectedFile);
      }
      
      if (message.trim()) {
        formData.append('content', message.trim());
      }

      await sendMessage(formData);
      setMessage('');
      setSelectedFile(null);
    } catch (error) {
      toast.error('Mesaj gönderilemedi');
    } finally {
      setUploading(false);
    }
  };

  const handleFileSelect = () => {
    fileInputRef.current?.click();
  };

  const validateFile = (file) => {
    const maxSize = 5 * 1024 * 1024; // 5MB
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'video/mp4',
      'video/webm'
    ];

    if (file.size > maxSize) {
      toast.error('Dosya boyutu 5MB\'dan küçük olmalıdır');
      return false;
    }

    if (!allowedTypes.includes(file.type)) {
      toast.error('Desteklenmeyen dosya türü');
      return false;
    }

    return true;
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!validateFile(file)) {
      e.target.value = '';
      return;
    }

    setSelectedFile(file);
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  if (!activeConversation) return null;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        p: { xs: 1, sm: 2 },
        borderTop: 1,
        borderColor: 'divider',
        backgroundColor: 'background.paper'
      }}
    >
      {selectedFile && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 1,
            p: 1,
            borderRadius: 1,
            bgcolor: 'action.hover'
          }}
        >
          <AttachFile fontSize="small" color="primary" />
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {selectedFile.name}
          </Typography>
          <IconButton size="small" onClick={clearSelectedFile}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
      
      {uploading && (
        <LinearProgress 
          sx={{ 
            mb: 1,
            borderRadius: 1
          }} 
        />
      )}

      <TextField
        fullWidth
        multiline
        maxRows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Mesajınızı yazın..."
        variant="outlined"
        disabled={uploading}
        size={isMobile ? "small" : "medium"}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 3,
            backgroundColor: theme.palette.grey[50]
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept="image/*,.pdf,.doc,.docx,.mp4,.webm"
              />
              <Tooltip title="Dosya ekle">
                <IconButton
                  onClick={handleFileSelect}
                  disabled={uploading}
                  color={selectedFile ? 'primary' : 'default'}
                  sx={{ mr: 1 }}
                >
                  {uploading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <AttachFile />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Gönder">
                <span>
                  <IconButton
                    type="submit"
                    disabled={!message.trim() && !selectedFile}
                    color="primary"
                    sx={{
                      transition: 'transform 0.2s',
                      '&:not(:disabled):hover': {
                        transform: 'scale(1.1)'
                      }
                    }}
                  >
                    <Send />
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export default MessageInput;