import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';

const API_URL = import.meta.env.VITE_API_URL || 'https://terapiyo.com';

export const useWebRTC = (appointmentId) => {
  const { token, user } = useSelector((state) => state.auth);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [error, setError] = useState(null);
  const [connectionState, setConnectionState] = useState('disconnected');
  const [participants, setParticipants] = useState([]);

  const peerConnection = useRef(null);
  const videoSocket = useRef(null);

  const createPeerConnection = useCallback(() => {
    try {
      const configuration = {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:stun1.l.google.com:19302' }
        ]
      };

      const pc = new RTCPeerConnection(configuration);

      pc.onicecandidate = (event) => {
        if (event.candidate) {
          videoSocket.current?.emit('signal', {
            roomId: `room_${appointmentId}`,
            signal: {
              type: 'candidate',
              candidate: event.candidate
            }
          });
        }
      };

      pc.ontrack = (event) => {
        console.log('Uzak medya akışı alındı:', event.streams[0].id);
        setRemoteStream(event.streams[0]);
      };

      pc.oniceconnectionstatechange = () => {
        console.log('ICE bağlantı durumu:', pc.iceConnectionState);
        setConnectionState(pc.iceConnectionState);
      };

      pc.onconnectionstatechange = () => {
        console.log('Peer bağlantı durumu:', pc.connectionState);
        if (pc.connectionState === 'failed') {
          console.log('Bağlantı başarısız oldu, yeniden deneniyor...');
          restartConnection();
        }
      };

      peerConnection.current = pc;
      return pc;
    } catch (error) {
      console.error('Peer bağlantısı oluşturma hatası:', error);
      setError('Video bağlantısı kurulamadı');
      return null;
    }
  }, [appointmentId]);

  const initializeMediaDevices = useCallback(async () => {
    try {
      console.log('Medya akışı başlatılıyor...');
      const constraints = {
        video: {
          width: { min: 640, ideal: 1920, max: 1920 },
          height: { min: 480, ideal: 1080, max: 1080 },
          aspectRatio: { ideal: 1.7777777778 }, // 16:9
          facingMode: 'user'
        },
        audio: true
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      console.log('Medya akışı alındı:', stream.id);
      
      // Set video track settings for better quality
      const videoTrack = stream.getVideoTracks()[0];
      if (videoTrack) {
        const capabilities = videoTrack.getCapabilities();
        if (capabilities) {
          try {
            await videoTrack.applyConstraints({
              width: { ideal: capabilities.width.max },
              height: { ideal: capabilities.height.max },
              frameRate: { max: 30 }
            });
          } catch (e) {
            console.warn('Could not apply ideal constraints:', e);
          }
        }
      }
      
      setLocalStream(stream);
      const pc = createPeerConnection();
      
      if (pc) {
        stream.getTracks().forEach(track => {
          pc.addTrack(track, stream);
        });
      }

      return stream;
    } catch (error) {
      console.error('Medya akışı başlatma hatası:', error);
      if (error.name === 'NotAllowedError') {
        setError('Kamera ve mikrofon izni reddedildi');
      } else if (error.name === 'NotFoundError') {
        setError('Kamera veya mikrofon bulunamadı');
      } else {
        setError('Medya akışı başlatılamadı');
      }
      return null;
    }
  }, [createPeerConnection]);

  const initializeSocketConnection = useCallback(() => {
    if (!token || !user || !appointmentId) {
      setError('Geçersiz oturum veya randevu bilgisi');
      return;
    }

    console.log('Video socket olayları dinleniyor...');
    
    videoSocket.current = io(`${API_URL}/video`, {
      path: '/socket.io',
      auth: { token },
      transports: ['websocket'],
      secure: true,
      rejectUnauthorized: false
    });

    videoSocket.current.on('connect', () => {
      console.log('Video socket bağlandı');
      videoSocket.current.emit('join-room', { appointmentId });
    });

    videoSocket.current.on('connect_error', (error) => {
      console.error('Video socket bağlantı hatası:', error);
      setError('Video bağlantısı kurulamadı');
    });

    videoSocket.current.on('room-joined', (data) => {
      console.log('Odaya katılım başarılı:', data);
      setParticipants(data.participants);
    });

    videoSocket.current.on('user-connected', async (data) => {
      console.log('Kullanıcı bağlandı:', data);
      if (data.userId !== user._id) {
        try {
          const pc = peerConnection.current;
          const offer = await pc.createOffer();
          await pc.setLocalDescription(offer);
          
          videoSocket.current.emit('signal', {
            roomId: `room_${appointmentId}`,
            signal: {
              type: 'offer',
              sdp: offer
            }
          });
        } catch (error) {
          console.error('Teklif oluşturma hatası:', error);
        }
      }
    });

    videoSocket.current.on('signal', async (data) => {
      try {
        const pc = peerConnection.current;
        const signal = data.signal;

        if (!pc) {
          console.error('PeerConnection henüz oluşturulmamış');
          return;
        }

        if (signal.type === 'offer') {
          if (pc.signalingState !== 'stable') {
            console.log('Sinyal durumu stabil değil, bekliyor...');
            await Promise.all([
              pc.setLocalDescription({ type: 'rollback' }),
              pc.setRemoteDescription(new RTCSessionDescription(signal.sdp))
            ]);
          } else {
            await pc.setRemoteDescription(new RTCSessionDescription(signal.sdp));
          }
          
          const answer = await pc.createAnswer();
          await pc.setLocalDescription(answer);
          
          videoSocket.current.emit('signal', {
            roomId: `room_${appointmentId}`,
            signal: {
              type: 'answer',
              sdp: answer
            }
          });
        }
        else if (signal.type === 'answer') {
          if (pc.signalingState === 'have-local-offer') {
            await pc.setRemoteDescription(new RTCSessionDescription(signal.sdp));
          } else {
            console.log('Beklenmeyen sinyal durumu:', pc.signalingState);
          }
        }
        else if (signal.type === 'candidate' && pc.remoteDescription) {
          await pc.addIceCandidate(new RTCIceCandidate(signal.candidate));
        }
      } catch (error) {
        console.error('Sinyal işleme hatası:', error);
        setError('Video bağlantısı kurulurken bir hata oluştu');
      }
    });

    videoSocket.current.on('user-disconnected', (data) => {
      console.log('Kullanıcı ayrıldı:', data);
      setParticipants(prev => prev.filter(p => p.userId !== data.userId));
      if (remoteStream) {
        remoteStream.getTracks().forEach(track => track.stop());
        setRemoteStream(null);
      }
    });

    videoSocket.current.on('error', (error) => {
      console.error('Video socket hatası:', error);
      setError(error.message);
    });
  }, [token, user, appointmentId]);

  const restartConnection = useCallback(async () => {
    console.log('Bağlantı yeniden başlatılıyor...');
    
    // Mevcut bağlantıları temizle
    if (peerConnection.current) {
      peerConnection.current.close();
    }
    
    // Yeni peer bağlantısı oluştur
    const pc = createPeerConnection();
    
    if (pc && localStream) {
      // Medya akışlarını yeni bağlantıya ekle
      localStream.getTracks().forEach(track => {
        pc.addTrack(track, localStream);
      });
    }
  }, [createPeerConnection, localStream]);

  const startScreenShare = useCallback(async () => {
    try {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      
      let screenStream;
      try {
        screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            cursor: 'always',
            displaySurface: 'monitor',
            logicalSurface: true,
            frameRate: 30
          }
        });
      } catch (err) {
        console.error('Ekran paylaşımı hatası:', err);
        throw err;
      }

      const pc = peerConnection.current;
      if (!pc) {
        screenStream.getTracks().forEach(track => track.stop());
        throw new Error('Bağlantı bulunamadı');
      }

      // Mevcut video track'i bul
      const senders = pc.getSenders();
      const videoSender = senders.find(sender => sender.track?.kind === 'video');

      // Yeni ekran paylaşımı track'i
      const screenTrack = screenStream.getVideoTracks()[0];

      try {
        if (videoSender) {
          // Eski track'i durdur
          if (videoSender.track) {
            videoSender.track.stop();
          }

          // Yeni track'i ekle
          await videoSender.replaceTrack(screenTrack);

          // Renegotiation
          const offer = await pc.createOffer();
          await pc.setLocalDescription(offer);
          
          videoSocket.current?.emit('signal', {
            roomId: `room_${appointmentId}`,
            signal: {
              type: 'offer',
              sdp: pc.localDescription
            }
          });
        } else {
          pc.addTrack(screenTrack, screenStream);
          
          // Renegotiation
          const offer = await pc.createOffer();
          await pc.setLocalDescription(offer);
          
          videoSocket.current?.emit('signal', {
            roomId: `room_${appointmentId}`,
            signal: {
              type: 'offer',
              sdp: pc.localDescription
            }
          });
        }

        return screenStream;
      } catch (error) {
        console.error('Track değişim hatası:', error);
        screenStream.getTracks().forEach(track => track.stop());
        throw error;
      }
    } catch (error) {
      console.error('Ekran paylaşımı hatası:', error);
      throw error;
    }
  }, [appointmentId]);

  const stopScreenShare = useCallback(async () => {
    try {
      const pc = peerConnection.current;
      if (!pc) throw new Error('Bağlantı bulunamadı');

      const senders = pc.getSenders();
      const videoSender = senders.find(sender => sender.track?.kind === 'video');
      
      if (videoSender && localStream?.getVideoTracks()[0]) {
        // Eski track'i durdur
        if (videoSender.track) {
          videoSender.track.stop();
        }
        
        // Kamera track'ini ekle
        const cameraTrack = localStream.getVideoTracks()[0];
        await videoSender.replaceTrack(cameraTrack);
        
        // Renegotiation
        const offer = await pc.createOffer();
        await pc.setLocalDescription(offer);
        
        videoSocket.current?.emit('signal', {
          roomId: `room_${appointmentId}`,
          signal: {
            type: 'offer',
            sdp: pc.localDescription
          }
        });
        
        return true;
      }
      return false;
    } catch (error) {
      console.error('Ekran paylaşımı durdurma hatası:', error);
      throw error;
    }
  }, [localStream, appointmentId]);

  const toggleAudio = useCallback(() => {
    if (localStream) {
      const audioTrack = localStream.getAudioTracks()[0];
      audioTrack.enabled = !audioTrack.enabled;
      setIsAudioEnabled(audioTrack.enabled);
    }
  }, [localStream]);

  const toggleVideo = useCallback(() => {
    if (localStream) {
      const videoTrack = localStream.getVideoTracks()[0];
      videoTrack.enabled = !videoTrack.enabled;
      setIsVideoEnabled(videoTrack.enabled);
    }
  }, [localStream]);

  const endCall = useCallback(() => {
    if (localStream) {
      localStream.getTracks().forEach(track => track.stop());
    }
    if (remoteStream) {
      remoteStream.getTracks().forEach(track => track.stop());
    }
    if (peerConnection.current) {
      peerConnection.current.close();
    }
    if (videoSocket.current) {
      videoSocket.current.disconnect();
    }
  }, [localStream, remoteStream]);

  useEffect(() => {
    const initialize = async () => {
      if (!token || !user || !appointmentId) {
        setError('Geçersiz oturum veya randevu bilgisi');
        return;
      }

      const stream = await initializeMediaDevices();
      if (stream) {
        initializeSocketConnection();
      }
    };

    initialize();

    return () => {
      if (localStream) {
        localStream.getTracks().forEach(track => track.stop());
      }
      if (remoteStream) {
        remoteStream.getTracks().forEach(track => track.stop());
      }
      if (peerConnection.current) {
        peerConnection.current.close();
      }
      if (videoSocket.current) {
        videoSocket.current.disconnect();
      }
    };
  }, [token, user, appointmentId, initializeMediaDevices, initializeSocketConnection]);

  return {
    localStream,
    remoteStream,
    isAudioEnabled,
    isVideoEnabled,
    error,
    connectionState,
    participants,
    toggleAudio,
    toggleVideo,
    endCall,
    restartConnection,
    startScreenShare,
    stopScreenShare
  };
};

export default useWebRTC;
