import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Stack,
  Typography,
  Button,
  Chip,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  IconButton,
  Divider,
  List,
  ListItem,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Link,
  useTheme,
  Snackbar
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckIcon,
  Close as CloseIcon,
  Email as EmailIcon,
  AttachmentFile as AttachmentIcon,
  OpenInNew as ExternalLinkIcon
} from '@mui/icons-material';
import {
  fetchApplicationDetails,
  approveApplication,
  rejectApplication,
  clearApplicationDetails
} from '../../store/slices/adminSlice';
import moment from 'moment';
moment.locale('tr');

const ApplicationDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const { application, loading, error } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    if (id) {
      // URL'den gelen ID'yi temizle (eğer CV dosya adı varsa)
      const cleanId = id.split('-')[0];
      console.log('Başvuru detayları yükleniyor... ID:', cleanId);
      dispatch(fetchApplicationDetails(cleanId))
        .unwrap()
        .catch((error) => {
          console.error('Başvuru detayları yüklenirken hata:', error);
          setSnackbar({
            open: true,
            message: 'Başvuru detayları yüklenirken bir hata oluştu.',
            severity: 'error'
          });
        });
    }
    
    return () => {
      dispatch(clearApplicationDetails());
    };
  }, [dispatch, id]);

  const handleApprove = async () => {
    try {
      const cleanId = id.split('-')[0];
      await dispatch(approveApplication(cleanId)).unwrap();
      setSnackbar({
        open: true,
        message: 'Başvuru onaylandı',
        severity: 'success'
      });
      setTimeout(() => navigate('/admin/applications'), 2000);
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'Bir hata oluştu',
        severity: 'error'
      });
    }
  };

  const handleReject = async () => {
    try {
      const cleanId = id.split('-')[0];
      await dispatch(rejectApplication({ id: cleanId, reason: rejectionReason })).unwrap();
      setIsRejectDialogOpen(false);
      setSnackbar({
        open: true,
        message: 'Başvuru reddedildi',
        severity: 'success'
      });
      setTimeout(() => navigate('/admin/applications'), 2000);
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'Bir hata oluştu',
        severity: 'error'
      });
    }
  };

  const handleBack = () => {
    navigate('/admin/applications');
  };

  const handleViewCV = () => {
    if (application?.cvPath) {
      const baseUrl = process.env.REACT_APP_API_URL || window.location.origin;
      const cvUrl = `${baseUrl}/api/uploads/cv/${application.cvPath}`;
      console.log('CV URL:', cvUrl);
      
      // Önce dosyanın varlığını kontrol et
      fetch(cvUrl, { method: 'HEAD' })
        .then(response => {
          if (response.ok) {
            window.open(cvUrl, '_blank');
          } else {
            setSnackbar({
              open: true,
              message: 'CV dosyası bulunamadı.',
              severity: 'error'
            });
          }
        })
        .catch(error => {
          console.error('CV kontrol hatası:', error);
          setSnackbar({
            open: true,
            message: 'CV dosyası kontrol edilirken bir hata oluştu.',
            severity: 'error'
          });
        });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        {error}
      </Alert>
    );
  }

  if (!application) {
    return (
      <Alert severity="warning">
        Başvuru bulunamadı
      </Alert>
    );
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'approved':
        return 'success';
      case 'rejected':
        return 'error';
      default:
        return 'info';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'pending':
        return 'Beklemede';
      case 'approved':
        return 'Onaylandı';
      case 'rejected':
        return 'Reddedildi';
      default:
        return 'Bilinmiyor';
    }
  };

  return (
    <Box>
      <Stack spacing={4}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5">Başvuru Detayları</Typography>
        </Stack>

        <Card>
          <CardHeader
            title={
              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar src={application.user?.avatar} />
                <Box>
                  <Typography variant="h6">
                    {application.user?.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {application.user?.email}
                  </Typography>
                </Box>
              </Stack>
            }
            action={
              <Chip
                label={getStatusText(application.status)}
                color={getStatusColor(application.status)}
              />
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Kişisel Bilgiler
                    </Typography>
                    <List>
                      <ListItem>
                        <Typography variant="body2">
                          <strong>İsim:</strong> {application.user?.name}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          <strong>E-posta:</strong> {application.user?.email}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          <strong>Telefon:</strong> {application.phoneNumber}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          <strong>Unvan:</strong> {application.title}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          <strong>Lisans Numarası:</strong> {application.licenseNumber}
                        </Typography>
                      </ListItem>
                    </List>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Eğitim Bilgileri
                    </Typography>
                    {application.education?.map((edu, index) => (
                      <Box key={index} mb={3} p={3} borderWidth="1px" borderRadius="md">
                        <Typography variant="body2">
                          {edu.school} ({edu.startYear} - {edu.endYear})
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Uzmanlık Alanları
                    </Typography>
                    <List>
                      {application.specializations?.map((spec, index) => (
                        <ListItem key={index}>
                          <Typography variant="body2">
                            {spec}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Deneyim
                    </Typography>
                    <Typography variant="body2">
                      {application.experience}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Hakkında
                    </Typography>
                    <Typography variant="body2">
                      {application.about}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Seans Ücreti
                    </Typography>
                    <Typography variant="body2">
                      {application.sessionFee} TL
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Sosyal Medya
                    </Typography>
                    <List>
                      {application.socialMedia?.linkedin && (
                        <ListItem>
                          <Link href={application.socialMedia.linkedin} target="_blank" rel="noopener">
                            <Stack direction="row" spacing={1} alignItems="center">
                              <EmailIcon />
                              <Typography variant="body2">
                                {application.socialMedia.linkedin}
                              </Typography>
                              <ExternalLinkIcon fontSize="small" />
                            </Stack>
                          </Link>
                        </ListItem>
                      )}
                      {application.socialMedia?.twitter && (
                        <ListItem>
                          <Link href={application.socialMedia.twitter} target="_blank" rel="noopener">
                            <Stack direction="row" spacing={1} alignItems="center">
                              <EmailIcon />
                              <Typography variant="body2">
                                {application.socialMedia.twitter}
                              </Typography>
                              <ExternalLinkIcon fontSize="small" />
                            </Stack>
                          </Link>
                        </ListItem>
                      )}
                      {application.socialMedia?.instagram && (
                        <ListItem>
                          <Link href={application.socialMedia.instagram} target="_blank" rel="noopener">
                            <Stack direction="row" spacing={1} alignItems="center">
                              <EmailIcon />
                              <Typography variant="body2">
                                {application.socialMedia.instagram}
                              </Typography>
                              <ExternalLinkIcon fontSize="small" />
                            </Stack>
                          </Link>
                        </ListItem>
                      )}
                    </List>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      CV
                    </Typography>
                    {application.cvPath ? (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ExternalLinkIcon />}
                        onClick={handleViewCV}
                      >
                        CV'yi Görüntüle
                      </Button>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        CV yüklenmemiş
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      KVKK Onayı
                    </Typography>
                    <Chip
                      label={application.kvkkApproval ? 'Onaylandı' : 'Onaylanmadı'}
                      color={application.kvkkApproval ? 'success' : 'error'}
                    />
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Başvuru Durumu
                    </Typography>
                    <Chip
                      label={getStatusText(application.status)}
                      color={getStatusColor(application.status)}
                    />
                    {application.rejectionReason && (
                      <Box mt={2}>
                        <Typography variant="body2" color="text.secondary">
                          Red Nedeni: {application.rejectionReason}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Başvuru Tarihi
                    </Typography>
                    <Typography variant="body2">
                      {moment(application.createdAt).format('LLL')}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {application.status === 'pending' && (
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              color="error"
              startIcon={<CloseIcon />}
              onClick={() => setIsRejectDialogOpen(true)}
            >
              Reddet
            </Button>
            <Button
              variant="contained"
              color="success"
              startIcon={<CheckIcon />}
              onClick={handleApprove}
            >
              Onayla
            </Button>
          </Stack>
        )}
      </Stack>

      {/* Reddetme Dialogu */}
      <Dialog
        open={isRejectDialogOpen}
        onClose={() => setIsRejectDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Başvuruyu Reddet</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Red Nedeni"
            fullWidth
            multiline
            rows={4}
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRejectDialogOpen(false)}>
            İptal
          </Button>
          <Button onClick={handleReject} color="error" variant="contained">
            Reddet
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ApplicationDetails;
