import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import axiosInstance from '../utils/axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SOCKET_URL, SOCKET_OPTIONS } from '../config';

const WebSocketContext = createContext();

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [videoSocket, setVideoSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { isAuthenticated, user, token } = useSelector((state) => state.auth);

  const fetchNotifications = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get('/api/notifications');
      if (response.data && Array.isArray(response.data)) {
        const sortedNotifications = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setNotifications(sortedNotifications);
        setUnreadCount(sortedNotifications.filter(n => !n.read).length);
      }
    } catch (error) {
      console.error('Bildirimler alınamadı:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      await axiosInstance.post(`/api/notifications/${notificationId}/read`);
      setNotifications(prev =>
        prev.map(n =>
          n._id === notificationId ? { ...n, read: true } : n
        )
      );
      setUnreadCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('Bildirim okundu işaretlenemedi:', error);
    }
  };

  const markAllAsRead = async () => {
    try {
      await axiosInstance.post('/api/notifications/mark-all-read');
      setNotifications(prev =>
        prev.map(n => ({ ...n, read: true }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.error('Tüm bildirimler okundu işaretlenemedi:', error);
    }
  };

  const handleNewNotification = (notification) => {
    setNotifications(prev => [notification, ...prev]);
    setUnreadCount(prev => prev + 1);
    
    // Bildirim toast'u göster
    toast.info(notification.message, {
      position: "top-right",
      autoClose: 5000
    });
  };

  const handleMessageNotification = (data) => {
    console.log('Yeni mesaj bildirimi:', data);
    
    // Sadece mesaj sayfasında değilsek unread count'u artır
    if (!window.location.pathname.includes('/messages')) {
      setUnreadMessageCount(prev => prev + 1);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user && token) {
      // Ana socket bağlantısı
      const newSocket = io(SOCKET_URL, {
        ...SOCKET_OPTIONS,
        auth: { token }
      });

      // Video görüşme socket bağlantısı
      const newVideoSocket = io(`${SOCKET_URL}/video`, {
        ...SOCKET_OPTIONS,
        auth: { token }
      });

      // Bağlantı olaylarını dinle
      newSocket.on('connect', () => {
        console.log('WebSocket bağlantısı kuruldu');
        fetchNotifications();
      });

      newSocket.on('disconnect', () => {
        console.log('WebSocket bağlantısı koptu');
      });

      // Bildirim olaylarını dinle
      newSocket.on('notification', handleNewNotification);
      newSocket.on('messageNotification', handleMessageNotification);

      // Listen for message read events
      newSocket.on('messagesRead', ({ conversationId, userId }) => {
        if (userId !== user._id) {
          setUnreadMessageCount(prev => Math.max(0, prev - 1));
        }
      });

      // Görüşme olaylarını dinle
      newSocket.on('sessionStarted', ({ appointmentId, room }) => {
        console.log('Görüşme başlatıldı:', { appointmentId, room });
        
        // Her iki kullanıcı tipi de aynı video görüşme sayfasına gidecek
        const videoCallPath = `/video-call/${appointmentId}`;

        // Mevcut URL ile hedef URL'i karşılaştır
        const currentPath = window.location.pathname;
        if (currentPath !== videoCallPath) {
          navigate(videoCallPath);
        }

        // Bildirimi göster
        toast.success('Görüşme başlatıldı! Görüşme sayfasına yönlendiriliyorsunuz...', {
          position: "top-center",
          autoClose: 3000
        });
      });

      newSocket.on('sessionEnded', ({ appointmentId }) => {
        console.log('Görüşme sonlandırıldı:', appointmentId);
        
        // Ana sayfaya yönlendir
        if (user.role === 'psychologist') {
          navigate('/psychologist/appointments');
        } else {
          navigate('/appointments');
        }

        // Bildirimi göster
        toast.info('Görüşme sonlandırıldı.', {
          position: "top-center",
          autoClose: 3000
        });
      });

      // Görüşme odası olaylarını dinle
      newSocket.on('userJoined', ({ userId, timestamp }) => {
        toast.info('Diğer kullanıcı görüşmeye katıldı.', {
          position: "top-right",
          autoClose: 3000
        });
      });

      newSocket.on('userLeft', ({ userId, timestamp }) => {
        toast.warning('Diğer kullanıcı görüşmeden ayrıldı.', {
          position: "top-right",
          autoClose: 3000
        });
      });

      // Hata olaylarını dinle
      newSocket.on('error', (error) => {
        console.error('WebSocket hatası:', error);
        toast.error(error.message || 'Bir hata oluştu', {
          position: "top-right",
          autoClose: 5000
        });
      });

      setSocket(newSocket);
      setVideoSocket(newVideoSocket);

      return () => {
        newSocket.disconnect();
        newVideoSocket.disconnect();
      };
    }
  }, [isAuthenticated, user, token, navigate]);

  // Update unreadMessageCount when route changes
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('/messages')) {
      setUnreadMessageCount(0);
    }
  }, [window.location.pathname]);

  return (
    <WebSocketContext.Provider value={{
      socket,
      videoSocket,
      notifications,
      setNotifications,
      unreadCount,
      setUnreadCount,
      unreadMessageCount,
      setUnreadMessageCount,
      isLoading,
      fetchNotifications,
      markAsRead,
      markAllAsRead
    }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
