import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../utils/axios';
import { setCredentials, clearCredentials } from '../store/slices/authSlice';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user, token: reduxToken, isAuthenticated } = useSelector(state => state.auth);

  // Token değişimini izle ve Axios headers'ı güncelle
  useEffect(() => {
    console.log('\n=== 🔐 Auth Provider - Token Kontrolü ===');
    console.log('Redux Token:', reduxToken ? 'Mevcut' : 'Yok');
    console.log('Local Token:', localStorage.getItem('token') ? 'Mevcut' : 'Yok');
    console.log('User:', user);
    console.log('Is Authenticated:', isAuthenticated);

    const localToken = localStorage.getItem('token');

    // Token'ları karşılaştır
    if (reduxToken !== localToken) {
      console.log('Token uyumsuzluğu tespit edildi!');
      console.log('Redux Token:', reduxToken);
      console.log('Local Token:', localToken);
    }

    // Redux token varsa ve local token yoksa veya farklıysa, local'e kaydet
    if (reduxToken && (!localToken || localToken !== reduxToken)) {
      console.log('Redux token local storage\'a kaydediliyor');
      localStorage.setItem('token', reduxToken);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${reduxToken}`;
    }
    // Local token varsa ve redux token yoksa veya farklıysa, redux'a kaydet
    else if (localToken && (!reduxToken || reduxToken !== localToken)) {
      console.log('Local token Redux\'a kaydediliyor');
      dispatch(setCredentials({ token: localToken }));
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${localToken}`;
    }
    // İkisi de yoksa temizle
    else if (!localToken && !reduxToken) {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  }, [reduxToken, user, isAuthenticated, dispatch]);

  const handleTokenRefresh = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        console.error('Refresh token bulunamadı');
        return false;
      }

      const response = await axiosInstance.post('/api/auth/refresh-token', { refreshToken });
      const newToken = response.data.token;
      
      if (newToken) {
        localStorage.setItem('token', newToken);
        dispatch(setCredentials({ token: newToken }));
        return true;
      }
      return false;
    } catch (error) {
      console.error('Token yenileme hatası:', error);
      dispatch(clearCredentials());
      return false;
    }
  };

  const value = {
    user,
    isAuthenticated,
    token: reduxToken,
    handleTokenRefresh
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;
