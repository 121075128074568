import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useWebRTC } from '../hooks/useWebRTC';
import {
  Box, IconButton, Typography, Fade, CircularProgress, Alert, Snackbar, Stack,
  useMediaQuery
} from '@mui/material';
import {
  Mic, MicOff, Videocam, VideocamOff,
  CallEnd, ScreenShare, StopScreenShare
} from '@mui/icons-material';
import DraggableVideo from '../components/DraggableVideo';

const VideoCall = () => {
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme(); // Now this will work
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { token, user } = useSelector((state) => state.auth);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const {
    localStream,
    remoteStream,
    connectionState,
    error: webRTCError,
    isAudioEnabled,
    isVideoEnabled,
    toggleAudio,
    toggleVideo,
    endCall,
    restartConnection,
    peerConnection,
    sendToPeer
  } = useWebRTC(appointmentId);

  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const containerRef = useRef(null);
  const [showControls, setShowControls] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const controlsTimer = useRef(null);
  const [isLandscape, setIsLandscape] = useState(window.innerHeight < window.innerWidth);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.innerHeight < window.innerWidth);
    };

    window.addEventListener('resize', handleOrientationChange);
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    if (!token || !user) {
      navigate('/login');
      return;
    }
  }, [token, user, navigate]);

  useEffect(() => {
    if (localStream && localVideoRef.current) {
      localVideoRef.current.srcObject = localStream;
      
      const playLocalVideo = async () => {
        try {
          await localVideoRef.current.play();
        } catch (err) {
          console.error('Local video oynatma hatası:', err);
          // Kullanıcı etkileşimi gerektiğinde otomatik oynatmayı tekrar dene
          const playButton = document.createElement('button');
          playButton.textContent = 'Kamerayı Başlat';
          playButton.style.position = 'absolute';
          playButton.style.zIndex = '1000';
          playButton.style.top = '50%';
          playButton.style.left = '50%';
          playButton.style.transform = 'translate(-50%, -50%)';
          playButton.onclick = async () => {
            try {
              await localVideoRef.current.play();
              playButton.remove();
            } catch (playErr) {
              console.error('Kullanıcı etkileşimi sonrası oynatma hatası:', playErr);
            }
          };
          localVideoRef.current.parentElement.appendChild(playButton);
        }
      };
      
      playLocalVideo();
    }
  }, [localStream]);

  useEffect(() => {
    if (remoteStream && remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remoteStream;
      
      const playRemoteVideo = async () => {
        try {
          await remoteVideoRef.current.play();
        } catch (err) {
          console.error('Remote video oynatma hatası:', err);
        }
      };
      
      playRemoteVideo();
    }
  }, [remoteStream]);

  const handleMouseMove = () => {
    setShowControls(true);
    setIsHovering(true);
    
    if (controlsTimer.current) {
      clearTimeout(controlsTimer.current);
    }
    
    controlsTimer.current = setTimeout(() => {
      if (!isHovering) {
        setShowControls(false);
      }
    }, 3000);
  };

  const constraints = {
    video: {
      width: { ideal: 1280 },
      height: { ideal: 720 },
      aspectRatio: 16 / 9
    }
  };

  const handleScreenShare = async () => {
    try {
      if (!isScreenSharing) {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true
        });
        
        if (peerConnection?.current?.getSenders) {
          const videoTrack = stream.getVideoTracks()[0];
          const sender = peerConnection.current.getSenders().find(s => s.track?.kind === 'video');
          
          if (sender) {
            await sender.replaceTrack(videoTrack);
            
            videoTrack.onended = async () => {
              if (localStream) {
                const cameraTrack = localStream.getVideoTracks()[0];
                if (sender && cameraTrack) {
                  await sender.replaceTrack(cameraTrack);
                }
                setIsScreenSharing(false);
              }
            };
            
            setIsScreenSharing(true);
          }
        }
      } else {
        if (localStream && peerConnection?.current?.getSenders) {
          const cameraTrack = localStream.getVideoTracks()[0];
          const sender = peerConnection.current.getSenders().find(s => s.track?.kind === 'video');
          
          if (sender && cameraTrack) {
            await sender.replaceTrack(cameraTrack);
          }
        }
        setIsScreenSharing(false);
      }
    } catch (err) {
      console.error('Ekran paylaşımı hatası:', err);
      setSnackbar({
        open: true,
        message: 'Ekran paylaşımı başlatılamadı',
        severity: 'error'
      });
    }
  };

  const toggleFullscreen = () => {
    try {
      const videoElement = remoteVideoRef.current;
      const container = containerRef.current;
      
      if (!videoElement || !container) return;

      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        if (window.innerWidth <= 768) {
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if (videoElement.webkitRequestFullscreen) {
            videoElement.webkitRequestFullscreen();
          } else if (videoElement.mozRequestFullScreen) {
            videoElement.mozRequestFullScreen();
          } else if (videoElement.msRequestFullscreen) {
            videoElement.msRequestFullscreen();
          }
        } else {
          if (container.requestFullscreen) {
            container.requestFullscreen();
          } else if (container.webkitRequestFullscreen) {
            container.webkitRequestFullscreen();
          } else if (container.mozRequestFullScreen) {
            container.mozRequestFullScreen();
          } else if (container.msRequestFullscreen) {
            container.msRequestFullscreen();
          }
        }
      }
    } catch (err) {
      console.error('Tam ekran hatası:', err);
      setSnackbar({
        open: true,
        message: 'Tam ekran moduna geçilemedi',
        severity: 'error'
      });
    }
  };

  const handleEndCall = () => {
    if (isScreenSharing) {
      handleScreenShare();
    }
    endCall();
    navigate('/appointments');
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsRemaining = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`;
  };

  const [callDuration, setCallDuration] = useState(0);

  useEffect(() => {
    let intervalId;
    if (connectionState === 'connected') {
      intervalId = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [connectionState]);

  if (!token) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Alert severity="error">
          Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.
        </Alert>
      </Box>
    );
  }

  if (error || webRTCError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Alert severity="error">
          {error || webRTCError}
        </Alert>
      </Box>
    );
  }

  if (!localStream || !remoteStream) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack spacing={2} alignItems="center">
          <CircularProgress />
          <Typography>Görüşme başlatılıyor...</Typography>
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'fixed',
        inset: 0,
        bgcolor: '#000',
        overflow: 'hidden'
      }}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {/* Main Video Container */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          pt: isMobile ? '60px' : '80px' // Account for navbar
        }}
      >
        {/* Remote Video */}
        <video
          ref={remoteVideoRef}
          autoPlay
          playsInline
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: isLandscape ? 'contain' : 'cover',
            backgroundColor: '#1a1a1a'
          }}
        />

        {/* Local Video */}
        {localStream && (
          <DraggableVideo 
            stream={localStream}
            isLandscape={isLandscape}
            isMobile={isMobile}
          />
        )}

        {/* Call Duration */}
        <Box
          sx={{
            position: 'absolute',
            top: isMobile ? '12px' : '24px',
            left: '50%',
            transform: 'translateX(-50%)',
            px: 2.5,
            py: 1,
            borderRadius: '20px',
            bgcolor: 'rgba(0,0,0,0.6)',
            backdropFilter: 'blur(10px)',
            color: 'white',
            zIndex: 1100
          }}
        >
          <Typography variant="body2">
            {formatTime(callDuration)}
          </Typography>
        </Box>

        {/* Control Panel */}
        <Fade in={showControls}>
          <Box
            sx={{
              position: 'absolute',
              bottom: isLandscape ? '16px' : '24px',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              gap: { xs: 1.5, sm: 2 },
              p: { xs: 1.5, sm: 2 },
              borderRadius: '30px',
              bgcolor: 'rgba(0,0,0,0.7)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 8px 32px rgba(0,0,0,0.3)',
              zIndex: 1100
            }}
          >
            {/* Mic Toggle */}
            <IconButton
              onClick={toggleAudio}
              sx={{
                width: { xs: 44, sm: 52 },
                height: { xs: 44, sm: 52 },
                bgcolor: isAudioEnabled ? 'rgba(255,255,255,0.1)' : 'error.main',
                color: 'white',
                '&:hover': {
                  bgcolor: isAudioEnabled ? 'rgba(255,255,255,0.2)' : 'error.dark',
                  transform: 'scale(1.05)',
                }
              }}
            >
              {isAudioEnabled ? <Mic /> : <MicOff />}
            </IconButton>

            {/* Camera Toggle */}
            <IconButton
              onClick={toggleVideo}
              sx={{
                width: { xs: 44, sm: 52 },
                height: { xs: 44, sm: 52 },
                bgcolor: isVideoEnabled ? 'rgba(255,255,255,0.1)' : 'error.main',
                color: 'white',
                '&:hover': {
                  bgcolor: isVideoEnabled ? 'rgba(255,255,255,0.2)' : 'error.dark',
                  transform: 'scale(1.05)',
                }
              }}
            >
              {isVideoEnabled ? <Videocam /> : <VideocamOff />}
            </IconButton>

            {/* End Call */}
            <IconButton
              onClick={handleEndCall}
              sx={{
                width: { xs: 44, sm: 52 },
                height: { xs: 44, sm: 52 },
                bgcolor: 'error.main',
                color: 'white',
                '&:hover': {
                  bgcolor: 'error.dark',
                  transform: 'scale(1.05)',
                }
              }}
            >
              <CallEnd />
            </IconButton>
          </Box>
        </Fade>
      </Box>

      {/* Connection Status */}
      {connectionState !== 'connected' && (
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(0,0,0,0.8)',
            zIndex: 2000
          }}
        >
          <Stack spacing={2} alignItems="center">
            <CircularProgress size={isMobile ? 40 : 48} />
            <Typography variant={isMobile ? 'body1' : 'h6'} color="white">
              {connectionState === 'connecting'
                ? 'Bağlanıyor...'
                : connectionState === 'disconnected'
                ? 'Bağlantı kesildi'
                : 'Bağlantı bekleniyor...'}
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default VideoCall;
