import React from 'react';
import { Link } from 'react-router-dom';
import { BsFacebook, BsTwitter, BsInstagram, BsLinkedin } from 'react-icons/bs';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Hakkımızda */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-white">Terapiyo</h3>
            <p className="text-sm">
              Online psikolojik danışmanlık hizmetleri ile ruh sağlığınız için yanınızdayız.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-white transition-colors">
                <BsFacebook className="w-5 h-5" />
              </a>
              <a href="#" className="hover:text-white transition-colors">
                <BsTwitter className="w-5 h-5" />
              </a>
              <a href="#" className="hover:text-white transition-colors">
                <BsInstagram className="w-5 h-5" />
              </a>
              <a href="#" className="hover:text-white transition-colors">
                <BsLinkedin className="w-5 h-5" />
              </a>
            </div>
          </div>

          {/* Hızlı Bağlantılar */}
          <div>
            <h3 className="text-lg font-semibold text-white mb-4">Hızlı Bağlantılar</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="hover:text-white transition-colors">
                  Hakkımızda
                </Link>
              </li>
              <li>
                <Link to="/psychologists" className="hover:text-white transition-colors">
                  Psikologlarımız
                </Link>
              </li>
              <li>
                <Link to="/blog" className="hover:text-white transition-colors">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-white transition-colors">
                  İletişim
                </Link>
              </li>
            </ul>
          </div>

          {/* Hizmetler */}
          <div>
            <h3 className="text-lg font-semibold text-white mb-4">Hizmetler</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/services/individual" className="hover:text-white transition-colors">
                  Bireysel Terapi
                </Link>
              </li>
              <li>
                <Link to="/services/couples" className="hover:text-white transition-colors">
                  Çift Terapisi
                </Link>
              </li>
              <li>
                <Link to="/services/family" className="hover:text-white transition-colors">
                  Aile Terapisi
                </Link>
              </li>
              <li>
                <Link to="/services/child" className="hover:text-white transition-colors">
                  Çocuk Terapisi
                </Link>
              </li>
            </ul>
          </div>

          {/* İletişim */}
          <div>
            <h3 className="text-lg font-semibold text-white mb-4">İletişim</h3>
            <ul className="space-y-2">
              <li>
                <a href="tel:+902121234567" className="hover:text-white transition-colors">
                  +90 (212) 123 45 67
                </a>
              </li>
              <li>
                <a href="mailto:info@terapiyo.com" className="hover:text-white transition-colors">
                  info@terapiyo.com
                </a>
              </li>
              <li>
                <address className="not-italic">
                  Maslak, Büyükdere Cad. No:123<br />
                  Sarıyer/İstanbul
                </address>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-12 border-t border-gray-800 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm">
              © {new Date().getFullYear()} Terapiyo. Tüm hakları saklıdır.
            </p>
            <div className="mt-4 md:mt-0 space-x-4">
              <Link to="/privacy" className="text-sm hover:text-white transition-colors">
                Gizlilik Politikası
              </Link>
              <Link to="/terms" className="text-sm hover:text-white transition-colors">
                Kullanım Koşulları
              </Link>
              <Link to="/cookies" className="text-sm hover:text-white transition-colors">
                Çerez Politikası
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
