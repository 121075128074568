import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { createApplication, resetError } from '../store/slices/psychologistApplicationSlice';
import { MdCloudUpload, MdDelete, MdAdd, MdRemove } from 'react-icons/md';
import { PlusIcon, XMarkIcon as XIcon, DocumentIcon } from '@heroicons/react/24/outline';

const JoinPsychologistTeam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, submitSuccess } = useSelector((state) => state.psychologistApplication);

  useEffect(() => {
    // Hata durumunu sıfırla
    dispatch(resetError());
  }, [dispatch]);

  useEffect(() => {
    // Başarılı başvuru durumunda yönlendir
    if (submitSuccess) {
      navigate('/application-success');
    }
  }, [submitSuccess, navigate]);

  const [formData, setFormData] = useState({
    title: '',
    specializations: [], 
    education: [],
    experience: '',
    about: '',
    sessionFee: '',
    licenseNumber: '',
    cv: null,
    phoneNumber: '',
    socialMedia: {
      linkedin: '',
      twitter: '',
      instagram: '',
    },
    kvkkApproval: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSpecializationsChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setFormData(prev => ({
      ...prev,
      specializations: isChecked 
        ? [...prev.specializations, value]
        : prev.specializations.filter(item => item !== value)
    }));
  };

  const handleEducationChange = (index, field, value) => {
    const newEducation = [...formData.education];
    if (!newEducation[index]) {
      newEducation[index] = {};
    }
    newEducation[index][field] = value;
    setFormData({
      ...formData,
      education: newEducation
    });
  };

  const addEducation = () => {
    setFormData({
      ...formData,
      education: [...formData.education, { school: '', department: '', startYear: '', endYear: '' }]
    });
  };

  const removeEducation = (index) => {
    const newEducation = formData.education.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      education: newEducation
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      cv: e.target.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Zorunlu alanları kontrol et
    const requiredFields = [
      'title',
      'licenseNumber',
      'specializations',
      'education',
      'experience',
      'about',
      'phoneNumber',
      'kvkkApproval'
    ];

    const missingFields = requiredFields.filter(field => {
      if (field === 'specializations') {
        return !formData[field] || formData[field].length === 0;
      }
      if (field === 'education') {
        return !formData[field] || formData[field].length === 0 || 
          formData[field].some(edu => !edu.school || !edu.department || !edu.startYear);
      }
      return !formData[field];
    });

    if (missingFields.length > 0) {
      alert(`Lütfen tüm zorunlu alanları doldurun: ${missingFields.join(', ')}`);
      return;
    }

    // Form verilerini hazırla
    const submitData = new FormData();

    // Temel bilgileri ekle
    submitData.append('title', formData.title);
    submitData.append('licenseNumber', formData.licenseNumber);
    submitData.append('experience', formData.experience);
    submitData.append('about', formData.about);
    submitData.append('sessionFee', Number(formData.sessionFee));
    submitData.append('phoneNumber', formData.phoneNumber);
    submitData.append('kvkkApproval', formData.kvkkApproval.toString());

    // Uzmanlık alanlarını ekle
    formData.specializations.forEach(spec => {
      submitData.append('specializations', spec);
    });

    // Eğitim bilgilerini ekle
    const validEducation = formData.education.map(edu => ({
      school: edu.school.trim(),
      department: edu.department.trim(),
      startYear: Number(edu.startYear),
      endYear: edu.endYear ? Number(edu.endYear) : null
    })).filter(edu => edu.school && edu.department && edu.startYear);

    if (validEducation.length === 0) {
      alert('En az bir eğitim bilgisi girmelisiniz.');
      return;
    }

    // Eğitim bilgilerini string olarak ekle
    submitData.append('education', JSON.stringify(validEducation));

    // Sosyal medya hesaplarını ekle
    submitData.append('socialMedia', JSON.stringify(formData.socialMedia));

    // CV dosyasını ekle
    if (formData.cv) {
      submitData.append('cv', formData.cv);
    }

    try {
      const formDataObject = {};
      for (let [key, value] of submitData.entries()) {
        formDataObject[key] = value;
      }

      console.log('Form verileri gönderiliyor:', formDataObject);

      await dispatch(createApplication(submitData)).unwrap();
      // Yönlendirme useEffect hook'u tarafından yapılacak
    } catch (error) {
      console.error('Form gönderme hatası:', error);
      alert('Başvuru gönderilirken bir hata oluştu: ' + (error.message || 'Bilinmeyen bir hata oluştu'));
    }
  };

  const specializationOptions = [
    'Yetişkin Terapisi',
    'Çift Terapisi',
    'Aile Terapisi',
    'Çocuk ve Ergen Terapisi',
    'Cinsel Terapi',
    'EMDR',
    'Oyun Terapisi'
  ];

  const degreeOptions = ['Lisans', 'Yüksek Lisans', 'Doktora', 'Uzmanlık'];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section - Mobil için daha kompakt */}
      <div className="relative overflow-hidden bg-gradient-to-r from-primary-600 to-primary-800 py-12 sm:py-24">
        <div className="absolute inset-0" style={{
          backgroundImage: `radial-gradient(circle at 25% 25%, rgba(255,255,255,0.2) 2%, transparent 2.5%),
                           radial-gradient(circle at 75% 75%, rgba(255,255,255,0.2) 2%, transparent 2.5%),
                           radial-gradient(circle at 50% 50%, rgba(255,255,255,0.2) 2%, transparent 2.5%)`,
          backgroundSize: '240px 240px',
        }} />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl md:text-5xl"
            >
              Psikolog Ekibimize Katılın
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="mt-4 sm:mt-6 max-w-2xl mx-auto text-lg sm:text-xl text-white/90 px-4"
            >
              Profesyonel gelişiminize katkıda bulunurken, danışanlarınıza online platformumuz üzerinden güvenli ve etkili bir şekilde hizmet verin.
            </motion.p>
          </div>
        </div>
      </div>

      {/* Form Section - Mobil için optimize edilmiş */}
      <div className="max-w-4xl mx-auto px-4 py-8 sm:py-16 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="bg-white shadow-xl rounded-2xl overflow-hidden"
        >
          <div className="p-4 sm:p-8">
            {/* Form İlerleme Göstergesi */}
            <div className="mb-8">
              <div className="flex justify-between items-center text-sm font-medium text-gray-500">
                <span>Temel Bilgiler</span>
                <span>→</span>
                <span>Uzmanlık</span>
                <span>→</span>
                <span>Eğitim</span>
                <span>→</span>
                <span>CV & Ücret</span>
              </div>
              <div className="mt-2 h-2 bg-gray-200 rounded-full">
                <div
                  className="h-full bg-primary-600 rounded-full transition-all duration-500"
                  style={{ width: '25%' }}
                />
              </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              {error && (
                <div className="rounded-lg bg-red-50 p-4 border border-red-200">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-red-800">{error}</p>
                    </div>
                  </div>
                </div>
              )}

              {/* Temel Bilgiler */}
              <div className="space-y-4">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-900">Temel Bilgiler</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                      Unvan
                    </label>
                    <input
                      id="title"
                      name="title"
                      type="text"
                      required
                      value={formData.title}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                      placeholder="Örn: Uzman Klinik Psikolog"
                    />
                  </div>

                  <div>
                    <label htmlFor="licenseNumber" className="block text-sm font-medium text-gray-700">
                      Lisans Numarası
                    </label>
                    <input
                      id="licenseNumber"
                      name="licenseNumber"
                      type="text"
                      required
                      value={formData.licenseNumber}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              {/* Uzmanlık Alanları */}
              <div className="mt-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Uzmanlık Alanları
                </label>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
                  {specializationOptions.map((option) => (
                    <div key={option} className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id={option}
                          name="specializations"
                          type="checkbox"
                          value={option}
                          checked={formData.specializations.includes(option)}
                          onChange={handleSpecializationsChange}
                          className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                        />
                      </div>
                      <label htmlFor={option} className="ml-2 text-sm text-gray-600">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Eğitim Bilgileri */}
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-900">Eğitim Bilgileri</h3>
                  <button
                    type="button"
                    onClick={addEducation}
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    <PlusIcon className="h-5 w-5 mr-1" />
                    Eğitim Ekle
                  </button>
                </div>

                <div className="space-y-4">
                  {formData.education.map((edu, index) => (
                    <div
                      key={index}
                      className="relative p-4 bg-white border rounded-lg shadow-sm hover:shadow-md transition-shadow"
                    >
                      <div className="absolute top-2 right-2">
                        <button
                          type="button"
                          onClick={() => removeEducation(index)}
                          className="text-gray-400 hover:text-red-500 focus:outline-none"
                        >
                          <XIcon className="h-5 w-5" />
                        </button>
                      </div>

                      <div className="grid grid-cols-1 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Okul Adı
                          </label>
                          <input
                            type="text"
                            name="school"
                            value={edu.school}
                            onChange={(e) => handleEducationChange(index, 'school', e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                            required
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Bölüm
                          </label>
                          <input
                            type="text"
                            name="department"
                            value={edu.department}
                            onChange={(e) => handleEducationChange(index, 'department', e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                            required
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Başlangıç Yılı
                            </label>
                            <input
                              type="number"
                              name="startYear"
                              value={edu.startYear}
                              onChange={(e) => handleEducationChange(index, 'startYear', e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                              min="1950"
                              max={new Date().getFullYear()}
                              required
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Bitiş Yılı
                            </label>
                            <input
                              type="number"
                              name="endYear"
                              value={edu.endYear}
                              onChange={(e) => handleEducationChange(index, 'endYear', e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                              min="1950"
                              max={new Date().getFullYear() + 10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Telefon Numarası */}
              <div>
                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                  Telefon Numarası
                </label>
                <input
                  type="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="+90 555 555 55 55"
                  required
                />
              </div>

              {/* Sosyal Medya Hesapları */}
              <div className="space-y-4">
                <label className="block text-sm font-medium text-gray-700">
                  Sosyal Medya Hesapları
                </label>
                
                <div>
                  <label htmlFor="linkedin" className="block text-sm font-medium text-gray-500">
                    LinkedIn
                  </label>
                  <input
                    type="url"
                    name="socialMedia.linkedin"
                    id="linkedin"
                    value={formData.socialMedia.linkedin}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      socialMedia: {
                        ...prev.socialMedia,
                        linkedin: e.target.value
                      }
                    }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="https://linkedin.com/in/your-profile"
                  />
                </div>

                <div>
                  <label htmlFor="twitter" className="block text-sm font-medium text-gray-500">
                    Twitter
                  </label>
                  <input
                    type="url"
                    name="socialMedia.twitter"
                    id="twitter"
                    value={formData.socialMedia.twitter}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      socialMedia: {
                        ...prev.socialMedia,
                        twitter: e.target.value
                      }
                    }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="https://twitter.com/your-profile"
                  />
                </div>

                <div>
                  <label htmlFor="instagram" className="block text-sm font-medium text-gray-500">
                    Instagram
                  </label>
                  <input
                    type="url"
                    name="socialMedia.instagram"
                    id="instagram"
                    value={formData.socialMedia.instagram}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      socialMedia: {
                        ...prev.socialMedia,
                        instagram: e.target.value
                      }
                    }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="https://instagram.com/your-profile"
                  />
                </div>
              </div>

              {/* Deneyim ve Hakkında */}
              <div className="bg-gray-50 p-6 rounded-lg space-y-6">
                <h3 className="text-lg font-medium text-gray-900">Deneyim ve Hakkında</h3>
                <div className="space-y-6">
                  <div>
                    <label htmlFor="experience" className="block text-sm font-medium text-gray-700">
                      Deneyim
                    </label>
                    <textarea
                      id="experience"
                      name="experience"
                      rows={4}
                      required
                      value={formData.experience}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                      placeholder="Mesleki deneyimlerinizi detaylı bir şekilde anlatın..."
                    />
                  </div>

                  <div>
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                      Hakkında
                    </label>
                    <textarea
                      id="about"
                      name="about"
                      rows={4}
                      required
                      value={formData.about}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                      placeholder="Kendinizi ve terapi yaklaşımınızı anlatın..."
                    />
                  </div>
                </div>
              </div>

              {/* CV ve Sertifikalar */}
              <div className="space-y-4">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-900">CV ve Sertifikalar</h3>
                
                <div className="space-y-4">
                  <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-primary-500 transition-colors">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <label
                          htmlFor="cv-upload"
                          className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>CV'nizi yükleyin</span>
                          <input
                            id="cv-upload"
                            name="cv"
                            type="file"
                            className="sr-only"
                            accept=".pdf,.doc,.docx"
                            onChange={handleFileChange}
                            required
                          />
                        </label>
                        <p className="pl-1">veya sürükleyip bırakın</p>
                      </div>
                      <p className="text-xs text-gray-500">PDF, DOC, DOCX (Max 10MB)</p>
                    </div>
                  </div>

                  {formData.cv && (
                    <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                      <div className="flex items-center">
                        <DocumentIcon className="h-5 w-5 text-gray-400" />
                        <span className="ml-2 text-sm text-gray-600">{formData.cv.name}</span>
                      </div>
                      <button
                        type="button"
                        onClick={() => setFormData({ ...formData, cv: null })}
                        className="text-sm text-red-500 hover:text-red-700"
                      >
                        Kaldır
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {/* KVKK Onayı */}
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="kvkkApproval"
                    name="kvkkApproval"
                    type="checkbox"
                    checked={formData.kvkkApproval}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      kvkkApproval: e.target.checked
                    }))}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    required
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="kvkkApproval" className="font-medium text-gray-700">
                    KVKK Onayı
                  </label>
                  <p className="text-gray-500">
                    Kişisel verilerimin işlenmesine ilişkin <a href="/kvkk" target="_blank" className="text-indigo-600 hover:text-indigo-500">KVKK Aydınlatma Metni</a>'ni okudum ve onaylıyorum.
                  </p>
                </div>
              </div>

              {/* Seans Ücreti */}
              <div className="bg-gray-50 p-6 rounded-lg space-y-6">
                <h3 className="text-lg font-medium text-gray-900">Seans Ücreti</h3>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                  <div>
                    <label htmlFor="sessionFee" className="block text-sm font-medium text-gray-700">
                      Seans Ücreti (TL)
                    </label>
                    <input
                      id="sessionFee"
                      name="sessionFee"
                      type="number"
                      required
                      value={formData.sessionFee}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={loading}
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Başvuru Gönderiliyor...
                    </>
                  ) : (
                    'Başvuruyu Gönder'
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>

        {/* Mobil Yardım Butonu */}
        <div className="fixed bottom-4 right-4 z-50 sm:hidden">
          <button
            type="button"
            onClick={() => alert('Yardıma ihtiyacınız varsa bize ulaşın: destek@terapiyov.com')}
            className="bg-primary-600 text-white rounded-full p-4 shadow-lg"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinPsychologistTeam;
