import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'react-hot-toast';
import { MdEmail, MdPhone, MdLocationOn } from 'react-icons/md';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // TODO: Form gönderme işlemi backend'e eklendiğinde burası güncellenecek
    toast.success('Mesajınız başarıyla gönderildi. En kısa sürede size dönüş yapacağız.');
    
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-4xl font-bold text-gray-900 mb-8">İletişim</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* İletişim Formu */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Bize Ulaşın</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                  Adınız Soyadınız
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  E-posta Adresiniz
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                />
              </div>

              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                  Konu
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                  Mesajınız
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="4"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full bg-primary-600 text-white px-6 py-3 rounded-lg hover:bg-primary-700 transition-colors"
              >
                Gönder
              </button>
            </form>
          </div>

          {/* İletişim Bilgileri */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">İletişim Bilgileri</h2>
            
            <div className="space-y-6">
              <div className="flex items-start space-x-4">
                <MdEmail className="text-2xl text-primary-600 mt-1" />
                <div>
                  <h3 className="font-medium text-gray-800">E-posta</h3>
                  <p className="text-gray-600">info@terapiyov5.com</p>
                  <p className="text-gray-600">destek@terapiyov5.com</p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <MdPhone className="text-2xl text-primary-600 mt-1" />
                <div>
                  <h3 className="font-medium text-gray-800">Telefon</h3>
                  <p className="text-gray-600">+90 (212) XXX XX XX</p>
                  <p className="text-gray-600">+90 (532) XXX XX XX</p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <MdLocationOn className="text-2xl text-primary-600 mt-1" />
                <div>
                  <h3 className="font-medium text-gray-800">Adres</h3>
                  <p className="text-gray-600">
                    Merkez Mahallesi, Büyükdere Caddesi No:123
                    <br />
                    Şişli, İstanbul 34394
                  </p>
                </div>
              </div>
            </div>

            {/* Çalışma Saatleri */}
            <div className="mt-8">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Çalışma Saatleri</h3>
              <div className="space-y-2">
                <p className="text-gray-600">
                  <span className="font-medium">Pazartesi - Cuma:</span> 09:00 - 18:00
                </p>
                <p className="text-gray-600">
                  <span className="font-medium">Cumartesi:</span> 10:00 - 14:00
                </p>
                <p className="text-gray-600">
                  <span className="font-medium">Pazar:</span> Kapalı
                </p>
              </div>
            </div>

            {/* Acil Durum Bilgileri */}
            <div className="mt-8 bg-red-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-red-800 mb-2">Acil Durum Kaynakları</h3>
              <div className="space-y-2">
                <p className="text-red-700">
                  <span className="font-medium">Acil Yardım Hattı:</span> 112
                </p>
                <p className="text-red-700">
                  <span className="font-medium">Aile İçi Şiddet Hattı:</span> 183
                </p>
                <p className="text-red-700">
                  <span className="font-medium">Uyuşturucu ile Mücadele Hattı:</span> 191
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Contact;
