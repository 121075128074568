import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { BsChevronDown } from 'react-icons/bs';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full py-6 text-left focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center justify-between">
          <span className="text-lg font-medium text-gray-900">{question}</span>
          <motion.span
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <BsChevronDown className="w-5 h-5 text-gray-500" />
          </motion.span>
        </div>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <div className="pb-6 text-gray-600">
              {answer}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: "Online terapi nedir?",
      answer: "Online terapi, internet üzerinden video görüşme yoluyla profesyonel psikolojik destek almanızı sağlayan modern bir terapi yöntemidir. Geleneksel yüz yüze terapinin tüm avantajlarını, evinizin rahatlığında yaşamanıza olanak tanır."
    },
    {
      question: "Online terapi geleneksel terapi kadar etkili mi?",
      answer: "Yapılan araştırmalar, online terapinin geleneksel terapi kadar etkili olduğunu göstermektedir. Özellikle anksiyete, depresyon ve stres yönetimi gibi konularda online terapi başarılı sonuçlar vermektedir."
    },
    {
      question: "Görüşmeler nasıl yapılıyor?",
      answer: "Görüşmeler, güvenli ve özel bir video konferans sistemi üzerinden gerçekleştirilir. Sisteme bilgisayar, tablet veya akıllı telefonunuzdan erişebilirsiniz. Görüşme öncesinde size özel bir bağlantı gönderilir."
    },
    {
      question: "Ödeme nasıl yapılıyor?",
      answer: "Ödemelerinizi kredi kartı veya banka kartı ile güvenli bir şekilde yapabilirsiniz. Seans ücretleri, görüşme öncesinde tahsil edilir ve ödeme bilgileriniz güvenli bir şekilde saklanır."
    },
    {
      question: "İptal politikanız nedir?",
      answer: "Görüşmelerinizi 24 saat öncesine kadar ücretsiz olarak iptal edebilir veya yeniden planlayabilirsiniz. 24 saatten daha kısa sürede yapılan iptallerde seans ücreti iade edilmez."
    }
  ];

  return (
    <div className="max-w-3xl mx-auto">
      <div className="divide-y divide-gray-200">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
