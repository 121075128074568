import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { FaStar, FaBrain, FaComments, FaUserTie } from 'react-icons/fa';
import ReviewEditDialog from './ReviewEditDialog';

const ReviewCard = ({ review, isUserReview, onReviewUpdate }) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const fullName = review.user?.name || '';
  const nameParts = fullName.split(' ');
  const firstInitial = nameParts[0]?.charAt(0) || '?';
  const lastInitial = nameParts[nameParts.length - 1]?.charAt(0) || '?';

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const RatingRow = ({ icon: Icon, value, label }) => (
    <div className="flex items-center gap-3 bg-gray-50 p-3 rounded-xl">
      <div className="w-8 h-8 rounded-lg bg-blue-100 flex items-center justify-center">
        <Icon className="text-blue-600" size={16} />
      </div>
      <div className="flex-grow">
        <div className="text-xs text-gray-500 mb-1">{label}</div>
        <div className="flex gap-1">
          {[...Array(5)].map((_, i) => (
            <FaStar
              key={i}
              size={12}
              className={`${
                i < value 
                  ? "text-yellow-400" 
                  : "text-gray-200"
              } transition-colors`}
            />
          ))}
        </div>
      </div>
      <span className="text-sm font-medium text-gray-700">
        {value}/5
      </span>
    </div>
  );

  const handleEditClick = () => {
    setIsEditDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsEditDialogOpen(false);
  };

  return (
    <div className="bg-white p-6 rounded-2xl shadow-sm hover:shadow-lg transition-all duration-300 border border-gray-100 relative group">
      {isUserReview && (
        <Tooltip title="Değerlendirmeyi Düzenle" placement="left">
          <IconButton
            onClick={handleEditClick}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              zIndex: 10,
              opacity: 0,
              transform: 'scale(0.9)',
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: 'rgba(59, 130, 246, 0.1)',
                transform: 'scale(1.1)',
              },
              '.group:hover &': {
                opacity: 1,
                transform: 'scale(1)',
              }
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      <div className="flex gap-4">
        <div className="flex-shrink-0">
          <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center text-white font-semibold shadow-sm">
            {firstInitial}{lastInitial}
          </div>
        </div>
        
        <div className="flex-grow">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h4 className="font-semibold text-gray-900">{review.user?.name}</h4>
              <p className="text-sm text-gray-500">{formatDate(review.createdAt)}</p>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-4">
            <RatingRow 
              icon={FaUserTie} 
              value={review.rating.professionalism} 
              label="Profesyonellik" 
            />
            <RatingRow 
              icon={FaComments} 
              value={review.rating.communication} 
              label="İletişim" 
            />
            <RatingRow 
              icon={FaBrain} 
              value={review.rating.expertise} 
              label="Uzmanlık" 
            />
            <RatingRow 
              icon={FaStar} 
              value={review.rating.overall} 
              label="Genel" 
            />
          </div>

          <div className="bg-gray-50 p-4 rounded-xl">
            <p className="text-gray-700 text-sm leading-relaxed">{review.comment}</p>
          </div>
        </div>
      </div>

      {isUserReview && (
        <ReviewEditDialog
          open={isEditDialogOpen}
          onClose={handleCloseDialog}
          review={review}
          onReviewUpdate={(updatedReview) => {
            onReviewUpdate(updatedReview);
            handleCloseDialog();
          }}
        />
      )}
    </div>
  );
};

export default ReviewCard;