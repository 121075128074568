import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

// Başvuru durumunu kontrol et
export const checkApplicationStatus = createAsyncThunk(
  'psychologistApplication/checkStatus',
  async (_, { rejectWithValue }) => {
    try {
      console.log('\n=== Başvuru Durumu Kontrolü ===');
      const response = await axiosInstance.get('/api/psychologist-applications/my-application');
      console.log('API Yanıtı:', response.data);
      console.log('====================\n');

      return response.data;
    } catch (error) {
      console.error('\n=== Başvuru Durumu Kontrolü Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('====================\n');
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Yeni başvuru oluştur
export const createApplication = createAsyncThunk(
  'psychologistApplication/create',
  async (applicationData, { rejectWithValue }) => {
    try {
      console.log('\n=== Yeni Başvuru Oluşturuluyor ===');
      console.log('Form verileri:', Object.fromEntries(applicationData.entries()));

      const response = await axiosInstance.post(
        '/api/psychologist-applications',
        applicationData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      console.log('API Yanıtı:', response.data);
      console.log('====================\n');

      return response.data;
    } catch (error) {
      console.error('\n=== Başvuru Oluşturma Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('====================\n');
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

const initialState = {
  application: null,
  loading: false,
  error: null,
  submitSuccess: false
};

const psychologistApplicationSlice = createSlice({
  name: 'psychologistApplication',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    resetSubmitSuccess: (state) => {
      state.submitSuccess = false;
    }
  },
  extraReducers: (builder) => {
    builder
      // Başvuru durumu kontrolü
      .addCase(checkApplicationStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkApplicationStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.application = action.payload.data.application;
        state.error = null;
      })
      .addCase(checkApplicationStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Başvuru durumu kontrol edilirken bir hata oluştu';
      })

      // Yeni başvuru oluşturma
      .addCase(createApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.submitSuccess = false;
      })
      .addCase(createApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.application = action.payload;
        state.error = null;
        state.submitSuccess = true;
      })
      .addCase(createApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Başvuru oluşturulurken bir hata oluştu';
        state.submitSuccess = false;
      });
  }
});

export const { resetError, resetSubmitSuccess } = psychologistApplicationSlice.actions;
export default psychologistApplicationSlice.reducer;
