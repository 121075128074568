import React, { createContext, useContext, useState, useEffect } from 'react';
import { useWebSocket } from './WebSocketContext';
import { useSelector } from 'react-redux';
import axiosInstance from '../utils/axios';
import { toast } from 'react-toastify';

const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const { socket } = useWebSocket();
  const { user } = useSelector((state) => state.auth);

  // Konuşmaları getir
  const fetchConversations = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/api/messages/conversations');
      console.log('Konuşmalar yanıtı:', response);
      if (response.data?.status === 'success' && response.data?.data) {
        setConversations(response.data.data);
      }
    } catch (error) {
      console.error('Konuşmalar alınırken hata:', error);
      toast.error('Konuşmalar yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  // Mesajları getir
  const fetchMessages = async (conversationId) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/api/messages/${conversationId}`);
      if (response.data?.status === 'success' && response.data?.data) {
        setMessages(response.data.data);
      }
    } catch (error) {
      console.error('Mesajlar alınırken hata:', error);
      toast.error('Mesajlar yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  // Yeni konuşma başlat
  const startConversation = async (participantId) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/api/messages/conversations', {
        participantId
      });
      console.log('Konuşma başlatma yanıtı:', response);
      if (response.data?.status === 'success' && response.data?.data) {
        const newConversation = response.data.data;
        setConversations(prev => [newConversation, ...prev]);
        setActiveConversation(newConversation);
        return newConversation;
      }
      return null;
    } catch (error) {
      console.error('Konuşma başlatılırken hata:', error);
      if (error.response?.status === 404) {
        toast.error('Seçilen psikolog sistemde bulunamadı. Lütfen sayfayı yenileyip tekrar deneyin.');
      } else if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Konuşma başlatılırken bir hata oluştu');
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Mesaj gönder
  const sendMessage = async (formData) => {
    if (!activeConversation) return;

    try {
      const response = await axiosInstance.post(
        `/api/messages/${activeConversation._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (response.data?.status === 'success' && response.data?.data) {
        const newMessage = response.data.data;
        // Burada state'i güncellemeye gerek yok çünkü socket event'i ile güncellenecek
        return newMessage;
      }
    } catch (error) {
      console.error('Mesaj gönderilirken hata:', error);
      toast.error('Mesaj gönderilemedi');
      throw error;
    }
  };

  // Mesajları okundu olarak işaretle
  const markMessagesAsRead = async (conversationId) => {
    try {
      await axiosInstance.post(`/api/messages/${conversationId}/read`);
      
      // Mesajları lokalde güncelle
      setMessages(prevMessages => 
        prevMessages.map(msg => ({
          ...msg,
          readBy: [...(msg.readBy || []), user._id]
        }))
      );

      // Konuşma listesini güncelle
      setConversations(prev =>
        prev.map(conv =>
          conv._id === conversationId
            ? { ...conv, unreadCount: 0 }
            : conv
        )
      );
    } catch (error) {
      console.error('Mesajlar okundu olarak işaretlenirken hata:', error);
    }
  };

  // Socket event handlers
  useEffect(() => {
    if (!socket) return;

    const handleNewMessage = (data) => {
      console.log('Yeni mesaj alındı:', data);
      
      // Mesajı state'e ekle
      if (activeConversation?._id === data.conversationId) {
        const messageWithStatus = {
          ...data.message,
          delivered: false
        };
        setMessages(prev => [...prev, messageWithStatus]);
        
        // Aktif konuşmadaysa otomatik olarak okundu olarak işaretle
        markMessagesAsRead(data.conversationId);
      }
      
      // Konuşma listesini güncelle
      setConversations(prevConversations => {
        // Mevcut konuşmaları güncelle
        const updatedConversations = prevConversations.map(conv => {
          if (conv._id === data.conversationId) {
            return {
              ...conv,
              lastMessage: data.message,
              unreadCount: conv._id === activeConversation?._id 
                ? 0 
                : (conv.unreadCount || 0) + 1
            };
          }
          return conv;
        });

        // Konuşma listesinde yoksa ekle
        if (!updatedConversations.some(conv => conv._id === data.conversationId)) {
          updatedConversations.unshift({
            _id: data.conversationId,
            lastMessage: data.message,
            unreadCount: 1,
            participants: data.message.participants
          });
        }

        // Son mesaja göre sırala ve güncelle
        return updatedConversations.sort((a, b) => 
          new Date(b.lastMessage?.createdAt || 0) - new Date(a.lastMessage?.createdAt || 0)
        );
      });
    };

    const handleMessageDelivered = (data) => {
      const { messageId, conversationId } = data;
      
      // Mesajın iletildi durumunu güncelle
      setMessages(prevMessages => 
        prevMessages.map(msg => 
          msg._id === messageId
            ? { 
                ...msg, 
                delivered: true,
                deliveredAt: new Date()
              }
            : msg
        )
      );
    };

    const handleMessageRead = (data) => {
      const { conversationId, userId, messageIds } = data;
      
      // Mesajları güncelle
      setMessages(prevMessages => 
        prevMessages.map(msg => ({
          ...msg,
          readBy: messageIds.includes(msg._id)
            ? [...new Set([...(msg.readBy || []), userId])]
            : msg.readBy || []
        }))
      );

      // Konuşmanın okunmamış mesaj sayacını güncelle
      if (userId !== user._id) {
        setConversations(prev =>
          prev.map(conv =>
            conv._id === conversationId
              ? { ...conv, unreadCount: 0 }
              : conv
          )
        );
      }
    };

    // Event listener'ları ekle
    socket.on('newMessage', handleNewMessage);
    socket.on('messageDelivered', handleMessageDelivered);
    socket.on('messageRead', handleMessageRead);
    
    // Cleanup
    return () => {
      socket.off('newMessage', handleNewMessage);
      socket.off('messageDelivered', handleMessageDelivered);
      socket.off('messageRead', handleMessageRead);
    };
  }, [socket, activeConversation, user?._id]);

  // Aktif konuşma değiştiğinde
  useEffect(() => {
    if (!socket || !activeConversation) return;

    // Socket.IO room'una katıl
    socket.emit('joinChat', { conversationId: activeConversation._id });
    // Mesajları getir
    fetchMessages(activeConversation._id);

    // Cleanup
    return () => {
      if (activeConversation?._id) {
        socket.emit('leaveChat', { conversationId: activeConversation._id });
      }
    };
  }, [socket, activeConversation]);

  // Aktif konuşma değiştiğinde mesajları okundu olarak işaretle
  useEffect(() => {
    if (!activeConversation) return;

    const unreadMessages = messages.some(msg => 
      msg.sender._id !== user._id && !msg.readBy?.includes(user._id)
    );

    if (unreadMessages) {
      markMessagesAsRead(activeConversation._id);
    }
  }, [activeConversation?._id, messages]);

  // İlk yüklemede konuşmaları getir
  useEffect(() => {
    if (user) {
      fetchConversations();
    }
  }, [user]);

  const contextValue = {
    conversations,
    activeConversation,
    messages,
    loading,
    setActiveConversation,
    fetchConversations,
    startConversation,
    sendMessage,
    markMessagesAsRead
  };

  return (
    <ChatContext.Provider value={contextValue}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;