import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { MdSearch, MdFilterList, MdStar, MdSchool } from 'react-icons/md';
import PsychologistCard from '../components/PsychologistCard';
import { fetchAllPsychologists } from '../store/slices/psychologistSlice';

const PsychologistList = () => {
  const dispatch = useDispatch();
  const { psychologists, loading, error } = useSelector((state) => state.psychologist);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    specialization: '',
    minRating: 0,
    minExperience: 0,
    priceRange: [0, 2000],
  });
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    dispatch(fetchAllPsychologists());
  }, [dispatch]);

  const handleFilterChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const filteredPsychologists = Array.isArray(psychologists) ? psychologists.filter(psych => {
    if (!psych) {
      console.warn('Geçersiz psikolog verisi:', psych);
      return false;
    }

    // Kullanıcı bilgilerini kontrol et
    const userName = psych.user?.name || '';
    const expertise = psych.expertise || '';
    const specialties = psych.specialties || [];
    const rating = psych.rating || 0;
    const experience = psych.experience || 0;
    const hourlyRate = psych.hourlyRate || 0;

    // Arama terimi kontrolü
    const searchLower = searchTerm.toLowerCase().trim();
    const matchesSearch = searchLower === '' || 
      userName.toLowerCase().includes(searchLower) ||
      expertise.toLowerCase().includes(searchLower);

    // Uzmanlık alanı kontrolü
    const matchesSpecialization = !filters.specialization ||
      specialties.some(spec => 
        (spec.name || '').toLowerCase().includes(filters.specialization.toLowerCase())
      );

    // Diğer filtre kontrolleri
    const matchesRating = rating >= filters.minRating;
    console.log('matchesRating:', matchesRating);
    console.log('rating:', rating);
    console.log('filters.minRating:', filters.minRating);
    const matchesExperience = experience >= filters.minExperience;
    const matchesPrice = hourlyRate >= filters.priceRange[0] && 
                        hourlyRate <= filters.priceRange[1];

    // Tüm filtrelerin sonucunu döndür
    return matchesSearch && matchesSpecialization && 
           matchesRating && matchesExperience && matchesPrice;
  }) : [];

  // Debug bilgileri
  useEffect(() => {
    if (psychologists) {
      console.log('Toplam psikolog sayısı:', Array.isArray(psychologists) ? psychologists.length : 'Geçersiz veri');
      console.log('Filtrelenmiş psikolog sayısı:', filteredPsychologists.length);
      console.log('Aktif filtreler:', filters);
      console.log('Arama terimi:', searchTerm);
    }
  }, [psychologists, filteredPsychologists, filters, searchTerm]);

  if (loading) return <div className="flex items-center justify-center min-h-screen">Yükleniyor...</div>;
  if (error) return <div className="text-red-500 text-center p-4">{error}</div>;

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Üst Kısım - Arama ve Filtreler */}
      <div className="sticky top-0 z-10 bg-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          {/* Arama Çubuğu */}
          <div className="flex items-center gap-4 mb-4">
            <div className="flex-1 relative">
              <MdSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 text-xl" />
              <input
                type="text"
                placeholder="Psikolog ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2.5 rounded-lg border border-gray-200 focus:border-primary-500 focus:ring-2 focus:ring-primary-200 outline-none transition-all"
              />
            </div>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center gap-2 px-4 py-2.5 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
            >
              <MdFilterList className="text-xl" />
              <span>Filtreler</span>
            </button>
          </div>

          {/* Filtreler */}
          <AnimatePresence>
            {showFilters && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="overflow-hidden"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-4 border-t">
                  {/* Uzmanlık Alanı */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Uzmanlık Alanı
                    </label>
                    <input
                      type="text"
                      placeholder="Örn: Depresyon"
                      value={filters.specialization}
                      onChange={(e) => handleFilterChange('specialization', e.target.value)}
                      className="w-full px-3 py-2 rounded-lg border border-gray-200 focus:border-primary-500 focus:ring-2 focus:ring-primary-200 outline-none"
                    />
                  </div>

                  {/* Minimum Puan */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Minimum Puan
                    </label>
                    <div className="relative">
                      <MdStar className="absolute left-3 top-1/2 -translate-y-1/2 text-yellow-400" />
                      <select
                        value={filters.minRating}
                        onChange={(e) => handleFilterChange('minRating', Number(e.target.value))}
                        className="w-full pl-9 pr-3 py-2 rounded-lg border border-gray-200 focus:border-primary-500 focus:ring-2 focus:ring-primary-200 outline-none appearance-none"
                      >
                        <option value={0}>Tümü</option>
                        <option value={4}>4.0+</option>
                        <option value={4.5}>4.5+</option>
                        <option value={4.8}>4.8+</option>
                      </select>
                    </div>
                  </div>

                  {/* Minimum Deneyim */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Minimum Deneyim
                    </label>
                    <div className="relative">
                      <MdSchool className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                      <select
                        value={filters.minExperience}
                        onChange={(e) => handleFilterChange('minExperience', Number(e.target.value))}
                        className="w-full pl-9 pr-3 py-2 rounded-lg border border-gray-200 focus:border-primary-500 focus:ring-2 focus:ring-primary-200 outline-none appearance-none"
                      >
                        <option value={0}>Tümü</option>
                        <option value={3}>3+ Yıl</option>
                        <option value={5}>5+ Yıl</option>
                        <option value={10}>10+ Yıl</option>
                      </select>
                    </div>
                  </div>

                  {/* Fiyat Aralığı */}
                  <div className="lg:col-span-3">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Fiyat Aralığı: {filters.priceRange[0]}₺ - {filters.priceRange[1]}₺
                    </label>
                    <div className="px-3">
                      <input
                        type="range"
                        min={0}
                        max={2000}
                        step={100}
                        value={filters.priceRange[1]}
                        onChange={(e) => handleFilterChange('priceRange', [filters.priceRange[0], Number(e.target.value)])}
                        className="w-full"
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      {/* Psikolog Listesi */}
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredPsychologists.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-500">Aramanıza uygun psikolog bulunamadı.</p>
            </div>
          ) : (
            filteredPsychologists.map((psychologist) => (
              <motion.div
                key={psychologist._id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <PsychologistCard psychologist={psychologist} />
              </motion.div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default PsychologistList;
