import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BsArrowRight, BsCalendar, BsPerson } from 'react-icons/bs';

const BlogCard = ({ blog }) => {
  return (
    <motion.div
      whileHover={{ y: -5 }}
      className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300"
    >
      <div className="relative">
        <img
          src={blog.image}
          alt={blog.title}
          className="w-full h-48 object-cover transform hover:scale-105 transition-transform duration-300"
        />
        <div className="absolute top-4 right-4 bg-primary-500 text-white px-3 py-1 rounded-full text-sm">
          {blog.category}
        </div>
      </div>
      <div className="p-6">
        <div className="flex items-center text-gray-500 text-sm mb-3">
          <BsCalendar className="mr-2" />
          <span>{blog.date}</span>
          <BsPerson className="ml-4 mr-2" />
          <span>{blog.author}</span>
        </div>
        <h3 className="text-xl font-semibold text-gray-900 mb-3 line-clamp-2 hover:text-primary-600">
          {blog.title}
        </h3>
        <p className="text-gray-600 mb-4 line-clamp-3">
          {blog.excerpt}
        </p>
        <Link
          to={`/blog/${blog.id}`}
          className="inline-flex items-center text-primary-600 hover:text-primary-700 font-medium"
        >
          Devamını Oku
          <BsArrowRight className="ml-2" />
        </Link>
      </div>
    </motion.div>
  );
};

export default BlogCard;
