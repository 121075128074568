import React, { useRef, useEffect, useCallback } from 'react';
import { useChat } from '../../contexts/ChatContext';
import { useSelector } from 'react-redux';
import { Box, Typography, Avatar, Skeleton, IconButton, useTheme, Divider } from '@mui/material';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { InsertDriveFile as FileIcon, Image as ImageIcon, Videocam as VideoIcon, ZoomIn as ZoomInIcon, Circle as CircleIcon, Done as DoneIcon, DoneAll as DoneAllIcon } from '@mui/icons-material';

const getProfileImageUrl = (path) => {
  if (!path) return "/default-avatar.png";
  const baseUrl = process.env.REACT_APP_API_URL || window.location.origin;
  console.log("API URL Image Path:", `${baseUrl}/api/uploads/${path}`);
  return `${baseUrl}/api/uploads/${path}`;
};

const MessageList = () => {
  const { messages, activeConversation, loading, markMessagesAsRead } = useChat();
  const { user } = useSelector(state => state.auth);
  const messagesEndRef = useRef(null);
  const theme = useTheme();
  const observerRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const formatMessageTime = (date) => {
    return format(new Date(date), 'HH:mm', { locale: tr });
  };

  const getOtherParticipant = () => {
    return activeConversation?.participants.find(p => p._id !== user._id);
  };

  const renderAttachment = (message) => {
    if (!message.attachment) return null;

    const attachmentUrl = `/api/uploads/messages/${message.attachment}`;
    const fileName = message.attachment.split('/').pop();

    switch (message.attachmentType) {
      case 'image':
        return (
          <Box sx={{ position: 'relative', mt: 1, maxWidth: '100%' }}>
            <Box
              component="img"
              src={attachmentUrl}
              alt="attachment"
              sx={{
                maxWidth: '100%',
                maxHeight: 200,
                borderRadius: 1,
                cursor: 'pointer',
                transition: 'filter 0.2s',
                '&:hover': {
                  filter: 'brightness(0.9)'
                }
              }}
              onClick={() => window.open(attachmentUrl, '_blank')}
            />
            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                bgcolor: 'rgba(0,0,0,0.5)',
                color: 'white',
                '&:hover': {
                  bgcolor: 'rgba(0,0,0,0.7)'
                }
              }}
              onClick={() => window.open(attachmentUrl, '_blank')}
            >
              <ZoomInIcon />
            </IconButton>
          </Box>
        );
      case 'video':
        return (
          <Box sx={{ mt: 1, borderRadius: 1, overflow: 'hidden' }}>
            <Box
              component="video"
              controls
              sx={{
                maxWidth: '100%',
                maxHeight: 200,
                bgcolor: 'black'
              }}
            >
              <source src={attachmentUrl} />
              Tarayıcınız video oynatmayı desteklemiyor.
            </Box>
          </Box>
        );
      case 'document':
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mt: 1,
              p: 1.5,
              bgcolor: 'background.paper',
              borderRadius: 1,
              cursor: 'pointer',
              border: '1px solid',
              borderColor: 'divider',
              transition: 'all 0.2s',
              '&:hover': {
                bgcolor: 'action.hover',
                borderColor: 'primary.main'
              }
            }}
            onClick={() => window.open(attachmentUrl, '_blank')}
          >
            <FileIcon color="primary" />
            <Typography variant="body2" sx={{ 
              wordBreak: 'break-all',
              color: theme.palette.primary.main
            }}>
              {fileName}
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  const isUnread = (message) => {
    return !message.readBy?.includes(user._id);
  };

  // Görünür mesajları izle
  const messageObserver = useCallback((node) => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const messageId = entry.target.getAttribute('data-message-id');
            const message = messages.find(m => m._id === messageId);
            
            if (message && !message.readBy?.includes(user._id) && message.sender._id !== user._id) {
              markMessagesAsRead(activeConversation._id);
            }
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );

    if (node) {
      observerRef.current.observe(node);
    }
  }, [activeConversation, messages, user._id, markMessagesAsRead]);

  // Cleanup observer
  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  const getMessageStatus = (message) => {
    if (!message.delivered) {
      return {
        icon: <DoneIcon sx={{ fontSize: 14, color: 'text.secondary' }} />,
        label: 'Gönderildi'
      };
    }

    const isRead = message.readBy?.length > 1; // En az bir kişi okumuşsa
    
    if (isRead) {
      return {
        icon: <DoneAllIcon sx={{ fontSize: 14, color: theme.palette.primary.main }} />,
        label: 'Okundu'
      };
    }
    
    return {
      icon: <DoneAllIcon sx={{ fontSize: 14, color: 'text.secondary' }} />,
      label: 'İletildi'
    };
  };

  if (loading) {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {[...Array(3)].map((_, i) => (
          <Box key={i} sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
            <Skeleton variant="circular" width={32} height={32} />
            <Skeleton variant="rounded" width={200} height={60} />
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        overflowY: 'auto',
        p: { xs: 1, sm: 2 },
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        bgcolor: '#f8f9fa'
      }}
    >
      {messages.length > 0 && (
        <Box sx={{ position: 'relative', mb: 3, mt: 1 }}>
          <Divider>
            <Typography variant="caption" color="text.secondary" sx={{ px: 2 }}>
              {format(new Date(messages[0].createdAt), 'd MMMM yyyy', { locale: tr })}
            </Typography>
          </Divider>
        </Box>
      )}

      {messages.map((message, index) => {
        const isSender = message.sender._id === user._id;
        const otherUser = getOtherParticipant();
        const unread = !isSender && isUnread(message);
        const showDateDivider = index > 0 && 
          new Date(message.createdAt).toDateString() !== new Date(messages[index - 1].createdAt).toDateString();
        const messageStatus = isSender ? getMessageStatus(message) : null;

        return (
          <React.Fragment key={message._id}>
            {showDateDivider && (
              <Box sx={{ position: 'relative', mb: 3, mt: 1 }}>
                <Divider>
                  <Typography variant="caption" color="text.secondary" sx={{ px: 2 }}>
                    {format(new Date(message.createdAt), 'd MMMM yyyy', { locale: tr })}
                  </Typography>
                </Divider>
              </Box>
            )}
            <Box
              ref={unread ? messageObserver : null}
              data-message-id={message._id}
              sx={{
                display: 'flex',
                justifyContent: isSender ? 'flex-end' : 'flex-start',
                gap: 1,
                mb: 3,
                position: 'relative'
              }}
            >
              {!isSender && (
                <Avatar
                  src={ getProfileImageUrl( otherUser?.profileImage) ||
                    "/default-avatar.png"}
                  alt={otherUser?.name}
                  sx={{ 
                    width: 32, 
                    height: 32,
                    boxShadow: 1
                  }}
                >
                  {otherUser?.name?.charAt(0)}
                </Avatar>
              )}
              <Box
                sx={{
                  maxWidth: { xs: '80%', sm: '70%' },
                  bgcolor: isSender ? 'primary.main' : 'white',
                  borderRadius: 2,
                  padding: 1.5,
                  position: 'relative',
                  boxShadow: 1,
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 10,
                    [isSender ? 'right' : 'left']: -6,
                    width: 0,
                    height: 0,
                    borderTop: '6px solid transparent',
                    borderBottom: '6px solid transparent',
                    [isSender ? 'borderLeft' : 'borderRight']: `6px solid ${isSender ? theme.palette.primary.main : 'white'}`
                  }
                }}
              >
                {unread && (
                  <CircleIcon 
                    sx={{ 
                      position: 'absolute',
                      right: -8,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: theme.palette.primary.main,
                      fontSize: 8
                    }}
                  />
                )}
                <Typography
                  variant="body1"
                  color={isSender ? 'white' : 'text.primary'}
                  sx={{ 
                    wordBreak: 'break-word',
                    lineHeight: 1.4
                  }}
                >
                  {message.content}
                </Typography>
                {renderAttachment(message)}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 0.5,
                    mt: 0.5
                  }}
                >
                  <Typography
                    variant="caption"
                    color={isSender ? 'rgba(255,255,255,0.8)' : 'text.secondary'}
                  >
                    {formatMessageTime(message.createdAt)}
                  </Typography>
                  {isSender && messageStatus?.icon}
                </Box>
              </Box>
            </Box>
          </React.Fragment>
        );
      })}
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default MessageList;