import React, { useMemo } from 'react';
import { useChat } from '../../contexts/ChatContext';
import { useSelector } from 'react-redux';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Box,
  Divider,
  Badge,
  useTheme,
  Skeleton,
  Chip
} from '@mui/material';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';

const getProfileImageUrl = (path) => {
  if (!path) return "/default-avatar.png";
  const baseUrl = process.env.REACT_APP_API_URL || window.location.origin;
  console.log("API URL Image Path:", `${baseUrl}/api/uploads/${path}`);
  return `${baseUrl}/api/uploads/${path}`;
};

const ConversationList = ({ onConversationSelect }) => {
  const { conversations, activeConversation, setActiveConversation, loading } = useChat();
  const { user } = useSelector(state => state.auth);
  const theme = useTheme();

  // Konuşmaları canlı olarak sırala ve güncelle
  const sortedConversations = useMemo(() => {
    return [...conversations].sort((a, b) => 
      new Date(b.lastMessage?.createdAt || 0) - new Date(a.lastMessage?.createdAt || 0)
    );
  }, [conversations]);

  const getOtherParticipant = (conversation) => {
    return conversation.participants.find(p => p._id !== user._id);
  };

  const formatLastMessageTime = (date) => {
    if (!date) return '';
    const messageDate = new Date(date);
    const today = new Date();
    
    if (messageDate.toDateString() === today.toDateString()) {
      return format(messageDate, 'HH:mm', { locale: tr });
    } else if (messageDate.getFullYear() === today.getFullYear()) {
      return format(messageDate, 'd MMM', { locale: tr });
    }
    return format(messageDate, 'd MMM yyyy', { locale: tr });
  };

  const handleConversationClick = (conversation) => {
    setActiveConversation(conversation);
    if (onConversationSelect) {
      onConversationSelect();
    }
  };

  if (loading) {
    return (
      <List>
        {[...Array(3)].map((_, i) => (
          <React.Fragment key={i}>
            <ListItem>
              <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
              </ListItemAvatar>
              <ListItemText
                primary={<Skeleton width={120} />}
                secondary={<Skeleton width={160} />}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>
    );
  }

  if (!conversations.length) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="text.secondary">
          Henüz konuşma bulunmuyor
        </Typography>
      </Box>
    );
  }

  return (
    <List sx={{ 
      height: '100%', 
      overflowY: 'auto',
      bgcolor: 'background.paper',
      p: 0
    }}>
      {sortedConversations.map((conversation) => {
        const otherUser = getOtherParticipant(conversation);
        const isActive = activeConversation?._id === conversation._id;
        const hasUnread = conversation.unreadCount > 0;

        return (
          <React.Fragment key={conversation._id}>
            <ListItem
              button
              selected={isActive}
              onClick={() => handleConversationClick(conversation)}
              sx={{
                px: 2,
                py: 1.5,
                transition: 'all 0.2s',
                bgcolor: hasUnread ? 'rgba(33, 150, 243, 0.08)' : (isActive ? 'action.selected' : 'transparent'),
                '&:hover': {
                  bgcolor: hasUnread ? 'rgba(33, 150, 243, 0.12)' : (isActive ? 'action.selected' : 'action.hover'),
                },
                borderLeft: hasUnread ? '4px solid' : 'none',
                borderLeftColor: hasUnread ? 'primary.main' : 'transparent'
              }}
            >
              <ListItemAvatar>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                  invisible={!otherUser?.isOnline}
                  badgeContent={hasUnread ? conversation.unreadCount : null}
                  max={99}
                  color={hasUnread ? "error" : "default"}
                >
                  <Avatar
                    src={getProfileImageUrl( otherUser?.profileImage) ||
                  "/default-avatar.png"
                     }
                    alt={otherUser?.name}
                    sx={{ 
                      width: 48, 
                      height: 48,
                      boxShadow: hasUnread ? 2 : 1,
                      border: hasUnread ? '2px solid' : 'none',
                      borderColor: hasUnread ? 'primary.main' : 'transparent'
                    }}
                  >
                    {otherUser?.name?.charAt(0)}
                  </Avatar>
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography 
                      variant="subtitle2" 
                      noWrap
                      sx={{ 
                        fontWeight: hasUnread ? 700 : 400,
                        color: hasUnread ? 'primary.main' : 'text.secondary',
                        fontSize: hasUnread ? '0.95rem' : '0.875rem'
                      }}
                    >
                      {otherUser?.name}
                    </Typography>
                    {conversation.lastMessage && (
                      <Typography 
                        variant="caption" 
                        color={hasUnread ? 'primary.main' : 'text.secondary'}
                        sx={{ 
                          ml: 1, 
                          flexShrink: 0,
                          fontWeight: hasUnread ? 600 : 400
                        }}
                      >
                        {formatLastMessageTime(conversation.lastMessage.createdAt)}
                      </Typography>
                    )}
                  </Box>
                }
                secondary={
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{ 
                        flex: 1,
                        color: hasUnread ? 'text.primary' : 'text.secondary',
                        fontWeight: hasUnread ? 600 : 400,
                        fontStyle: hasUnread ? 'normal' : 'italic'
                      }}
                    >
                      {conversation.lastMessage?.content || 'Henüz mesaj yok'}
                    </Typography>
                    {hasUnread && (
                      <Chip
                        label={conversation.unreadCount}
                        color="primary"
                        size="small"
                        sx={{
                          ml: 1,
                          height: 20,
                          borderRadius: '50%',
                          minWidth: 20,
                          fontWeight: 'bold'
                        }}
                      />
                    )}
                  </Box>
                }
              />
            </ListItem>
            <Divider component="li" variant="inset" />
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default ConversationList;