import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Stack,
  Typography,
  Button,
  Chip,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  IconButton,
  Divider,
  List,
  ListItem,
  Alert,
  CircularProgress,
  useTheme
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  CalendarToday as CalendarIcon
} from '@mui/icons-material';
import { fetchUserDetails, clearUserDetails } from '../../store/slices/adminSlice';
import moment from 'moment';
import 'moment/locale/tr';
moment.locale('tr');

function UserDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { selectedUser: user, loading, error } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    dispatch(fetchUserDetails(id));
    return () => dispatch(clearUserDetails());
  }, [dispatch, id]);

  const handleBack = () => {
    navigate('/admin/users');
  };

  if (loading) {
    return (
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">
          {error}
        </Alert>
      </Box>
    );
  }

  if (!user) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="info">
          Kullanıcı bulunamadı.
        </Alert>
      </Box>
    );
  }

  const getRoleColor = (role) => {
    switch (role) {
      case 'admin':
        return 'error';
      case 'psychologist':
        return 'success';
      default:
        return 'primary';
    }
  };

  const getRoleLabel = (role) => {
    switch (role) {
      case 'admin':
        return 'Admin';
      case 'psychologist':
        return 'Psikolog';
      default:
        return 'Kullanıcı';
    }
  };

  return (
    <Box sx={{ p: 2, maxWidth: '1200px', mx: 'auto' }}>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 3 }}>
        <IconButton
          onClick={handleBack}
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">Kullanıcı Detayları</Typography>
      </Stack>

      <Grid container spacing={3}>
        {/* Sol Kolon - Kişisel Bilgiler */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ width: 64, height: 64 }}
                  src={user.avatar}
                  alt={user.name}
                >
                  {user.name?.charAt(0)}
                </Avatar>
              }
              title={
                <Box sx={{ ml: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    {user.name}
                  </Typography>
                  <Chip
                    label={getRoleLabel(user.role)}
                    color={getRoleColor(user.role)}
                    size="small"
                  />
                </Box>
              }
            />
            <CardContent>
              <Stack spacing={2}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <EmailIcon color="action" />
                  <Typography>{user.email}</Typography>
                </Stack>

                {user.phone && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <PhoneIcon color="action" />
                    <Typography>{user.phone}</Typography>
                  </Stack>
                )}

                <Stack direction="row" spacing={1} alignItems="center">
                  <CalendarIcon color="action" />
                  <Typography>
                    Kayıt: {moment(user.createdAt).format('LL')}
                  </Typography>
                </Stack>

                <Box>
                  <Chip
                    label={user.isActive ? 'Aktif' : 'Pasif'}
                    color={user.isActive ? 'success' : 'error'}
                    variant="outlined"
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Sağ Kolon - Detay Bilgileri */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title={<Typography variant="h6">Profil Bilgileri</Typography>}
            />
            <CardContent>
              <Stack spacing={3}>
                {user.role === 'psychologist' && (
                  <>
                    <Box>
                      <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                        Uzmanlık Alanları
                      </Typography>
                      <List>
                        {user.specialties?.map((spec, index) => (
                          <ListItem key={index}>
                            <Typography>{spec.name}</Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Box>

                    <Divider />

                    <Box>
                      <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                        Eğitim Bilgileri
                      </Typography>
                      {user.education?.map((edu, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                          <Typography variant="subtitle2">
                            {edu.institution}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {edu.field} - {edu.degree} ({moment(edu.startDate).format('YYYY')} - {edu.endDate ? moment(edu.endDate).format('YYYY') : 'Devam Ediyor'})
                          </Typography>
                        </Box>
                      ))}
                    </Box>

                    <Box>
                      <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                        Deneyimler
                      </Typography>
                      {user.experiences?.map((exp, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                          <Typography variant="subtitle2">
                            {exp.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {exp.company} ({moment(exp.startDate).format('YYYY')} - {exp.endDate ? moment(exp.endDate).format('YYYY') : 'Devam Ediyor'})
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </>
                )}

                {user.role === 'user' && (
                  <Box>
                    <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                      Randevu Geçmişi
                    </Typography>
                    <Typography>
                      Toplam Randevu: {user.appointmentCount || 0}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserDetails;
