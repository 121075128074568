import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeaturedPsychologists } from '../store/slices/psychologistSlice';
import BlogCard from '../components/BlogCard';
import PsychologistCard from '../components/PsychologistCard';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import { MdArrowForward, MdPlayArrow, MdOutlineSupportAgent, MdOutlineCalendarMonth, 
         MdOutlineSecurity, MdOutlineGroups, MdOutlinePsychology, MdOutlineCelebration,
         MdOutlineFavorite, MdOutlineLightbulb, MdOutlineCheckCircle } from 'react-icons/md';
import { Box, Typography, Container, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Rating } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import Carousel from 'react-material-ui-carousel';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Özel stil bileşenleri
const CarouselContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  '& .MuiPaper-root': {
    backgroundColor: 'transparent'
  }
}));

const ProfileImage = styled('img')({
  width: 120,
  height: 120,
  borderRadius: '50%',
  objectFit: 'cover',
  marginBottom: 2
});

const Home = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { featuredPsychologists, loading, error } = useSelector((state) => state.psychologist);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    dispatch(fetchFeaturedPsychologists());
  }, [dispatch]);

  const heroSlides = useMemo(() => [
    {
      title: "Ruh Sağlığınız İçin",
      subtitle: "Profesyonel Destek",
      description: "Uzman psikologlarımızla online görüşmeler yaparak, ruh sağlığınızı iyileştirin.",
      image: "https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e",
      color: "from-sage-600 to-primary-700"
    },
    {
      title: "Güvenli ve Konforlu",
      subtitle: "Online Terapi",
      description: "Evinizin rahatlığında, güvenli ve özel görüşmeler yapın.",
      image: "https://images.unsplash.com/photo-1516302752625-fcc3c50ae61f",
      color: "from-sand-600 to-sage-700"
    },
    {
      title: "7/24 Yanınızda",
      subtitle: "Kesintisiz Destek",
      description: "Size özel terapi planı ile her an yanınızdayız.",
      image: "https://images.unsplash.com/photo-1544027993-37dbfe43562a",
      color: "from-primary-600 to-sage-700"
    }
  ], []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % heroSlides.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [heroSlides]);

  // Örnek blog verileri
  const blogs = [
    {
      id: 1,
      title: "Anksiyete ile Başa Çıkma Yöntemleri",
      excerpt: "Günlük hayatta anksiyete ile başa çıkmanın etkili yolları ve profesyonel destek almanın önemi...",
      image: "https://images.unsplash.com/photo-1547561090-e2c1104d363f",
      category: "Anksiyete",
      date: "25 Aralık 2023",
      author: "Dr. Ayşe Yılmaz"
    },
    {
      id: 2,
      title: "İlişkilerde İletişimin Önemi",
      excerpt: "Sağlıklı bir ilişki için etkili iletişim teknikleri ve yaygın iletişim hatalarından kaçınma...",
      image: "https://images.unsplash.com/photo-1521156690873-b14cb73c9900",
      category: "İlişkiler",
      date: "24 Aralık 2023",
      author: "Dr. Mehmet Kaya"
    },
    {
      id: 3,
      title: "Depresyonla Mücadele",
      excerpt: "Depresyonun belirtileri, tedavi yöntemleri ve günlük yaşama etkilerini azaltma stratejileri...",
      image: "https://images.unsplash.com/photo-1518199266791-5375a83190b7",
      category: "Depresyon",
      date: "23 Aralık 2023",
      author: "Dr. Zeynep Demir"
    }
  ];

  const renderFeaturedPsychologists = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" p={4}>
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Typography color="error" align="center">
          {error}
        </Typography>
      );
    }

    if (!featuredPsychologists || featuredPsychologists.length === 0) {
      return (
        <Typography color="textSecondary" align="center">
          Henüz öne çıkan psikolog bulunmuyor
        </Typography>
      );
    }

    console.log('Featured Psychologists:', featuredPsychologists);

    const itemsPerSlide = 3;
    const slides = [];
    
    for (let i = 0; i < featuredPsychologists.length; i += itemsPerSlide) {
      slides.push(featuredPsychologists.slice(i, i + itemsPerSlide));
    }

    return (
      <div style={{ width: '100vw', marginLeft: 'calc(-50vw + 50%)', marginRight: 'calc(-50vw + 50%)', position: 'relative' }}>
        <Carousel
          autoPlay={true}
          animation="slide"
          indicators={true}
          navButtonsAlwaysVisible={true}
          cycleNavigation={true}
          interval={5000}
          duration={500}
          fullHeightHover={false}
          sx={{
            width: '100%',
            '& .MuiPaper-root': {
              backgroundColor: 'transparent',
              width: '100%',
              margin: 0,
              padding: 0
            }
          }}
          navButtonsProps={{
            style: {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderRadius: '50%',
              color: '#000',
              padding: '8px',
              margin: '0 10px'
            }
          }}
          indicatorContainerProps={{
            style: {
              marginTop: '20px',
              textAlign: 'center'
            }
          }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                gap: '16px',
                padding: '20px 40px',
                boxSizing: 'border-box'
              }}
            >
              {slide.map((psychologist) => (
                <div 
                  key={psychologist._id} 
                  style={{ 
                    flex: '1 1 0',
                    minWidth: '0'
                  }}
                >
                  <PsychologistCard psychologist={psychologist} />
                </div>
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <section className="relative h-screen overflow-hidden">
        {/* Animated Background Patterns */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-primary-900/20 to-primary-800/20">
            <div className="absolute inset-0" style={{
              backgroundImage: `radial-gradient(circle at 25% 25%, rgba(255,255,255,0.2) 2%, transparent 2.5%),
                               radial-gradient(circle at 75% 75%, rgba(255,255,255,0.2) 2%, transparent 2.5%),
                               radial-gradient(circle at 50% 50%, rgba(255,255,255,0.2) 2%, transparent 2.5%)`,
              backgroundSize: '240px 240px',
              animation: 'patternMove 40s linear infinite'
            }}/>
          </div>
        </div>

        {/* Hero Content */}
        <div className="relative max-w-7xl mx-auto h-full px-4 sm:px-6 lg:px-8">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentSlide}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="flex items-center h-full"
            >
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                <div>
                  <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.2 }}
                    className="relative"
                  >
                    <div className="absolute -left-4 -top-4 w-20 h-20 bg-primary-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"/>
                    <div className="absolute -right-4 -bottom-4 w-20 h-20 bg-purple-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"/>
                    <h1 className="text-5xl md:text-7xl font-bold text-white leading-tight">
                      {heroSlides[currentSlide].title}<br/>
                      <span className="text-primary-200">{heroSlides[currentSlide].subtitle}</span>
                    </h1>
                  </motion.div>
                  
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                    className="mt-6 text-xl text-gray-100 max-w-lg"
                  >
                    {heroSlides[currentSlide].description}
                  </motion.p>

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6 }}
                    className="mt-10 flex flex-wrap gap-4"
                  >
                    {!isAuthenticated && (
                      <Link
                        to="/register"
                        className="group relative inline-flex items-center px-8 py-4 overflow-hidden rounded-lg bg-white text-primary-600 hover:bg-primary-50 transition-colors"
                      >
                        <span className="absolute left-0 top-0 h-full w-0 bg-primary-100 transition-all duration-500 ease-out group-hover:w-full"/>
                        <span className="relative flex items-center">
                          Hemen Başlayın
                          <MdArrowForward className="ml-2 group-hover:translate-x-1 transition-transform"/>
                        </span>
                      </Link>
                    )}
                    <button className="group relative inline-flex items-center px-8 py-4 overflow-hidden rounded-lg border-2 border-white text-white hover:bg-white hover:text-primary-600 transition-colors">
                      <MdPlayArrow className="mr-2 text-xl group-hover:scale-110 transition-transform"/>
                      Nasıl Çalışır?
                    </button>
                  </motion.div>
                </div>

                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.3 }}
                  className="relative hidden lg:block"
                >
                  {/* Floating Elements */}
                  <div className="absolute -left-8 top-1/4 w-16 h-16 bg-primary-400 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float"/>
                  <div className="absolute right-8 bottom-1/4 w-20 h-20 bg-purple-400 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float animation-delay-2000"/>
                  
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-primary-500/30 to-purple-500/30 rounded-3xl transform rotate-6"/>
                    <img
                      src={heroSlides[currentSlide].image}
                      alt="Online Terapi"
                      className="relative rounded-3xl shadow-2xl transform hover:scale-105 transition-transform duration-500"
                    />
                  </div>

                  {/* Floating Icons */}
                  <motion.div
                    animate={{
                      y: [0, -10, 0],
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                    className="absolute -left-12 top-1/3 bg-white p-3 rounded-2xl shadow-lg"
                  >
                    <MdOutlineFavorite className="text-2xl text-rose-500"/>
                  </motion.div>

                  <motion.div
                    animate={{
                      y: [0, 10, 0],
                    }}
                    transition={{
                      duration: 2,
                      delay: 0.5,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                    className="absolute -right-8 top-1/4 bg-white p-3 rounded-2xl shadow-lg"
                  >
                    <MdOutlinePsychology className="text-2xl text-purple-500"/>
                  </motion.div>

                  <motion.div
                    animate={{
                      y: [0, -15, 0],
                    }}
                    transition={{
                      duration: 2,
                      delay: 1,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                    className="absolute right-12 bottom-1/4 bg-white p-3 rounded-2xl shadow-lg"
                  >
                    <MdOutlineLightbulb className="text-2xl text-yellow-500"/>
                  </motion.div>
                </motion.div>
              </div>
            </motion.div>
          </AnimatePresence>

          {/* Slide Indicators */}
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-3">
            {heroSlides.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-3 h-3 rounded-full transition-colors duration-300 ${
                  currentSlide === index ? 'bg-white' : 'bg-white/50'
                }`}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-20 bg-gradient-to-br from-sage-50 to-primary-50">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { 
                number: "5000+", 
                label: "Mutlu Danışan", 
                icon: MdOutlineGroups, 
                color: "text-primary-600" 
              },
              { 
                number: "100+", 
                label: "Uzman Psikolog", 
                icon: MdOutlinePsychology, 
                color: "text-sage-600" 
              },
              { 
                number: "4.9", 
                label: "Memnuniyet Puanı", 
                icon: MdOutlineFavorite, 
                color: "text-rose-500" 
              },
              { 
                number: "24/7", 
                label: "Online Destek", 
                icon: MdOutlineLightbulb, 
                color: "text-amber-500" 
              }
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="text-center p-6 rounded-xl bg-white/80 backdrop-blur-sm hover:bg-white/90 transition-colors"
              >
                <div className={`inline-flex items-center justify-center w-12 h-12 rounded-xl bg-primary-50`}>
                  <stat.icon className={`text-2xl ${stat.color}`}/>
                </div>
                <div className="mt-4 text-4xl font-bold text-gray-900">
                  {stat.number}
                </div>
                <div className="mt-2 text-gray-600">
                  {stat.label}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Neden Terapiyo? */}
      <section className="py-20 bg-gradient-to-br from-sage-50 via-white to-primary-50">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-2xl mx-auto mb-16">
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Neden Terapiyo?
            </h2>
            <p className="text-lg text-gray-600">
              Size en iyi online terapi deneyimini sunmak için buradayız
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
            {[
              {
                title: "7/24 Destek",
                description: "Her an yanınızdayız. İhtiyacınız olduğunda bize ulaşabilirsiniz.",
                icon: MdOutlineSupportAgent,
                color: "text-sage-600",
                bgColor: "bg-sage-50"
              },
              {
                title: "Esnek Planlama",
                description: "Size uygun zamanda, istediğiniz yerden görüşme yapabilirsiniz.",
                icon: MdOutlineCalendarMonth,
                color: "text-primary-600",
                bgColor: "bg-primary-50"
              },
              {
                title: "Güvenli Görüşme",
                description: "End-to-end şifreli görüşmelerle gizliliğiniz güvende.",
                icon: MdOutlineSecurity,
                color: "text-primary-700",
                bgColor: "bg-primary-50"
              },
              {
                title: "Uzman Kadro",
                description: "Alanında uzman ve deneyimli psikologlarla tanışın.",
                icon: MdOutlinePsychology,
                color: "text-sage-700",
                bgColor: "bg-sage-50"
              },
              {
                title: "Kolay Kullanım",
                description: "Kullanıcı dostu platform ve mobil uygulama ile kolay erişim.",
                icon: MdOutlineGroups,
                color: "text-primary-600",
                bgColor: "bg-primary-50"
              },
              {
                title: "Mutlu Danışanlar",
                description: "Binlerce danışanımızın mutluluğuna ortak olduk.",
                icon: MdOutlineCelebration,
                color: "text-sage-600",
                bgColor: "bg-sage-50"
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white p-8 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300"
              >
                <div className={`w-14 h-14 rounded-xl flex items-center justify-center ${feature.bgColor} mb-6`}>
                  <feature.icon className={`text-3xl ${feature.color}`} />
                </div>
                <h3 className="text-xl font-semibold mb-3 text-gray-800">{feature.title}</h3>
                <p className="text-gray-600 leading-relaxed">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Nasıl Çalışır */}
      <section className="py-16 bg-gradient-to-br from-sand-50 to-sage-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-sand-800">
            Nasıl Çalışır?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                step: "1",
                title: "Üye Ol",
                description: "Hızlıca üye olun ve profilinizi oluşturun.",
                icon: MdOutlineCheckCircle,
                color: "text-primary-600"
              },
              {
                step: "2",
                title: "Psikolog Seç",
                description: "Size en uygun psikoloğu seçin.",
                icon: MdOutlinePsychology,
                color: "text-sage-600"
              },
              {
                step: "3",
                title: "Randevu Al",
                description: "Uygun zamanı seçin ve randevunuzu alın.",
                icon: MdOutlineCalendarMonth,
                color: "text-sand-600"
              },
              {
                step: "4",
                title: "Görüşmeye Başla",
                description: "Güvenli platformumuzda görüşmenizi gerçekleştirin.",
                icon: MdOutlineSupportAgent,
                color: "text-primary-600"
              }
            ].map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="relative bg-white/80 backdrop-blur-sm p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
              >
                <div className={`absolute -top-4 -left-4 w-8 h-8 rounded-full flex items-center justify-center ${step.color} bg-white shadow-sm border-2 border-white`}>
                  <span className="font-bold text-sm">{step.step}</span>
                </div>
                <div className={`w-12 h-12 rounded-full flex items-center justify-center ${step.color} bg-opacity-10 mb-4`}>
                  <step.icon className="text-2xl" />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-gray-800">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Psikolog Başvuru CTA Section */}
      <section className="bg-gradient-to-r from-primary-600 to-primary-800 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              Psikolog musunuz?
            </h2>
            <p className="mt-4 text-xl text-white/90">
              Ekibimize katılın ve online terapi platformumuz üzerinden danışanlarınızla buluşun.
            </p>
            <div className="mt-8">
              <Link
                to="/join-psychologist-team"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-primary-700 bg-white hover:bg-gray-50 transition-colors duration-300"
              >
                Hemen Başvurun
                <MdArrowForward className="ml-2 -mr-1 h-5 w-5" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Öne Çıkan Psikologlar */}
      <section className="relative py-24">
        {/* Arkaplan Deseni */}
        <div className="absolute inset-0 bg-gradient-to-br from-sage-50 via-white to-primary-50">
          <div className="absolute inset-0" style={{
            backgroundImage: `radial-gradient(circle at 25% 25%, rgba(167, 243, 208, 0.1) 2%, transparent 2.5%),
                             radial-gradient(circle at 75% 75%, rgba(147, 197, 253, 0.1) 2%, transparent 2.5%),
                             radial-gradient(circle at 50% 50%, rgba(167, 243, 208, 0.1) 2%, transparent 2.5%)`,
            backgroundSize: '48px 48px',
            animation: 'patternMove 20s linear infinite'
          }}/>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Başlık Alanı */}
          <div className="text-center max-w-3xl mx-auto mb-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <span className="inline-block px-4 py-2 rounded-full bg-primary-100 text-primary-800 text-sm font-medium mb-4">
                Uzman Kadromuz
              </span>
              <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                Öne Çıkan Psikologlarımız
              </h2>
              <p className="text-xl text-gray-600">
                Alanında uzman ve deneyimli psikologlarımızla tanışın, size en uygun olanı seçerek ruh sağlığınız için ilk adımı atın
              </p>
            </motion.div>
          </div>

          {/* Psikolog Kartları */}
          <div className="w-full overflow-hidden">
            {loading ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {Array(3).fill(null).map((_, index) => (
                  <div key={index} className="animate-pulse bg-white rounded-xl p-6">
                    <div className="flex items-center space-x-4">
                      <div className="rounded-full bg-gray-200 h-20 w-20"></div>
                      <div className="flex-1 space-y-3">
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : error ? (
              <div className="text-center text-red-600 p-6">
                {error}
              </div>
            ) : featuredPsychologists?.length > 0 ? (
              <Slider
                dots={false}
                infinite={true}
                speed={8000}
                slidesToShow={3}
                slidesToScroll={1}
                autoplay={true}
                autoplaySpeed={0}
                cssEase="linear"
                pauseOnHover={true}
                responsive={[
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 2,
                    }
                  },
                  {
                    breakpoint: 640,
                    settings: {
                      slidesToShow: 1,
                    }
                  }
                ]}
              >
                {featuredPsychologists.map((psychologist) => (
                  <div key={psychologist._id} className="px-2">
                    <PsychologistCard psychologist={psychologist} />
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="text-center text-gray-600 p-6">
                Henüz öne çıkan psikolog bulunmuyor.
              </div>
            )}
          </div>

          {/* Alt Bilgi ve CTA */}
          <div className="text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="max-w-2xl mx-auto"
            >
              <Link
                to="/psychologists"
                className="inline-flex items-center px-8 py-4 border border-transparent text-base font-medium rounded-lg text-primary-700 bg-white hover:bg-gray-50 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
              >
                Tüm Psikologları Gör
                <MdArrowForward className="ml-2 text-xl" />
              </Link>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Blog Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Son Blog Yazıları
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Ruh sağlığı hakkında faydalı bilgiler ve öneriler
            </p>
          </div>

          <div className="mt-12 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {blogs.map((blog) => (
              <BlogCard key={blog.id} blog={blog} />
            ))}
          </div>
        </div>
      </section>

      {/* Sıkça Sorulan Sorular */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Sıkça Sorulan Sorular
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Online terapi hakkında merak edilenler
            </p>
          </div>

          <div className="mt-12">
            <FAQ />
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
