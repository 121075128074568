import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  Chip,
  CircularProgress,
  Alert,
  TableContainer,
  Paper
} from '@mui/material';
import { fetchApplications } from '../../store/slices/adminSlice';
import moment from 'moment';
import 'moment/locale/tr';
moment.locale('tr');

const Applications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { applications, loading, error } = useSelector((state) => ({
    applications: state.admin.applications || [],
    loading: state.admin.loading,
    error: state.admin.error
  }));

  useEffect(() => {
    dispatch(fetchApplications());
  }, [dispatch]);

  const handleViewDetails = (application) => {
    navigate(`/admin/applications/${application._id}`);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'approved':
        return 'success';
      case 'rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'pending':
        return 'Beklemede';
      case 'approved':
        return 'Onaylandı';
      case 'rejected':
        return 'Reddedildi';
      default:
        return 'Bilinmiyor';
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        {error}
      </Alert>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Psikolog Başvuruları
      </Typography>

      {applications.length === 0 ? (
        <Alert severity="info">
          Henüz başvuru bulunmuyor.
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Başvuru Tarihi</TableCell>
                <TableCell>İsim</TableCell>
                <TableCell>E-posta</TableCell>
                <TableCell>Durum</TableCell>
                <TableCell>İşlemler</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applications.map((application) => (
                <TableRow key={application._id}>
                  <TableCell>
                    {moment(application.createdAt).format('LLL')}
                  </TableCell>
                  <TableCell>{application.user?.name}</TableCell>
                  <TableCell>{application.user?.email}</TableCell>
                  <TableCell>
                    <Chip
                      label={getStatusText(application.status)}
                      color={getStatusColor(application.status)}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleViewDetails(application)}
                    >
                      Detaylar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Applications;
