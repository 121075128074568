import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// Async thunks
export const fetchPsychologistContents = createAsyncThunk(
  'content/fetchPsychologistContents',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/content/psychologist/contents');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPsychologistPublicContents = createAsyncThunk(
  'content/fetchPsychologistPublicContents',
  async (psychologistId, { rejectWithValue }) => {
    try {
      console.log('=== Public İçerikler İsteniyor ===');
      console.log('Psikolog ID:', psychologistId);

      const response = await axios.get(`/api/content/psychologist/${psychologistId}/public-contents`);

      return response.data.data;
    } catch (error) {
      console.error('İçerikler getirilirken hata:', error);
      console.error('Hata detayı:', error.response?.data);
      return rejectWithValue(error.response?.data || 'İçerikler getirilemedi');
    }
  }
);

export const createContent = createAsyncThunk(
  'content/createContent',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/content/psychologist/contents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateContent = createAsyncThunk(
  'content/updateContent',
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      const response = await axios.patch(`/api/content/psychologist/contents/${id}`, formData, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteContent = createAsyncThunk(
  'content/deleteContent',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/api/content/psychologist/contents/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Görüntüleme sayısını artır
export const incrementViews = createAsyncThunk(
  'content/incrementViews',
  async (contentId, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/content/${contentId}/view`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Görüntüleme sayısı artırılamadı');
    }
  }
);

// İçeriği beğen/beğenmekten vazgeç
export const toggleLike = createAsyncThunk(
  'content/toggleLike',
  async (contentId, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/content/${contentId}/like`);
      return { contentId, ...response.data.data };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'İçerik beğenilemedi');
    }
  }
);

const initialState = {
  contents: [],
  publicContents: [], 
  loading: false,
  error: null,
  status: 'idle',
  currentContent: null
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    clearContents: (state) => {
      state.contents = [];
      state.publicContents = [];
      state.error = null;
    },
    resetContentStatus: (state) => {
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    // fetchPsychologistContents
    builder
      .addCase(fetchPsychologistContents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPsychologistContents.fulfilled, (state, action) => {
        state.loading = false;
        state.contents = action.payload.data;
      })
      .addCase(fetchPsychologistContents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    // fetchPsychologistPublicContents
      .addCase(fetchPsychologistPublicContents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPsychologistPublicContents.fulfilled, (state, action) => {
        state.loading = false;
        state.publicContents = action.payload;
      })
      .addCase(fetchPsychologistPublicContents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

    // createContent
      .addCase(createContent.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(createContent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contents.unshift(action.payload.data);
      })
      .addCase(createContent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

    // updateContent
      .addCase(updateContent.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.contents.findIndex(content => content._id === action.payload.data._id);
        if (index !== -1) {
          state.contents[index] = action.payload.data;
        }
      })
      .addCase(updateContent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

    // deleteContent
      .addCase(deleteContent.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteContent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contents = state.contents.filter(content => content._id !== action.meta.arg);
      })
      .addCase(deleteContent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

    // Görüntüleme sayısı
      .addCase(incrementViews.fulfilled, (state, action) => {
        const content = action.payload;
        const index = state.publicContents.findIndex(c => c._id === content._id);
        if (index !== -1) {
          state.publicContents[index].views = content.views;
        }
      })

    // Beğeni
      .addCase(toggleLike.fulfilled, (state, action) => {
        const { contentId, likes, isLiked } = action.payload;
        const index = state.publicContents.findIndex(c => c._id === contentId);
        if (index !== -1) {
          state.publicContents[index] = {
            ...state.publicContents[index],
            likes: new Array(likes).fill(null), // Beğeni sayısı kadar boş array oluştur
            isLiked: isLiked
          };
        }
      });
  }
});

export const { clearContents, resetContentStatus } = contentSlice.actions;
export default contentSlice.reducer;
