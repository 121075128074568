import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWebSocket } from '../../contexts/WebSocketContext';
import './NotificationList.css';

const NotificationList = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { notifications, markNotificationAsRead } = useWebSocket();

  const handleNotificationClick = async (notification) => {
    if (!notification?._id || loading) return;
    
    try {
      setLoading(true);
      await markNotificationAsRead(notification._id);

      // İlgili sayfaya yönlendir
      if (notification.type === 'APPOINTMENT_CREATED' && notification.data?.appointment?.id) {
        navigate(`/appointments/${notification.data.appointment.id}`);
      }
    } catch (error) {
      console.error('Bildirim işaretlenirken hata:', error);
      setError('Bildirim işaretlenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  if (!Array.isArray(notifications) || notifications.length === 0) {
    return (
      <div className="notification-empty">
        <i className="fas fa-bell-slash text-gray-400 text-4xl mb-2"></i>
        <p>Bildirim bulunmuyor</p>
      </div>
    );
  }

  return (
    <div className="notification-list">
      {error && (
        <div className="notification-error">
          <i className="fas fa-exclamation-circle mr-2"></i>
          {error}
        </div>
      )}
      {notifications.map((notification) => (
        <div
          key={notification?._id || Math.random()}
          className={`notification-item ${notification?.read ? '' : 'unread'} ${loading ? 'loading' : ''}`}
          onClick={() => handleNotificationClick(notification)}
        >
          <div className="notification-icon">
            {notification?.type === 'APPOINTMENT_CREATED' ? (
              <i className="fas fa-calendar-check"></i>
            ) : (
              <i className="fas fa-bell"></i>
            )}
          </div>
          <div className="notification-content">
            <p className="notification-message">{notification?.message}</p>
            <div className="notification-meta">
              <span className="notification-date">
                {notification?.createdAt ? new Date(notification.createdAt).toLocaleString('tr-TR') : ''}
              </span>
              {!notification?.read && (
                <span className="notification-badge">Yeni</span>
              )}
            </div>
          </div>
          <div className="notification-arrow">
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationList;
