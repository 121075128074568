import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useWebSocket } from '../contexts/WebSocketContext';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  CircularProgress,
  Slide,
  IconButton
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import axios from '../utils/axios';
import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

const Notifications = () => {
  const { notifications, setNotifications } = useWebSocket();
  const [loading, setLoading] = useState(false);
  const observerRefs = useRef({});

  // Bildirim görünürlüğünü izle
  const handleIntersection = useCallback(async (entries, observer) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const notificationId = entry.target.dataset.notificationId;
        const notification = notifications.find(n => n._id === notificationId);
        
        if (notification && !notification.read) {
          try {
            await handleMarkAsRead(notificationId);
          } catch (error) {
            console.error('Otomatik okundu işaretleme hatası:', error);
          }
        }
        
        observer.unobserve(entry.target);
      }
    }
  }, [notifications]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 1.0,
      rootMargin: '0px'
    });

    notifications.forEach(notification => {
      const element = document.querySelector(`[data-notification-id="${notification._id}"]`);
      if (element && !notification.read) {
        observer.observe(element);
        observerRefs.current[notification._id] = element;
      }
    });

    return () => {
      Object.values(observerRefs.current).forEach(element => {
        observer.unobserve(element);
      });
    };
  }, [notifications, handleIntersection]);

  const handleMarkAsRead = async (notificationId) => {
    if (!notificationId) return;

    try {
      setLoading(true);
      await axios.put(`/api/notifications/${notificationId}/read`);
      
      setNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification._id === notificationId
            ? { ...notification, read: true }
            : notification
        )
      );
    } catch (error) {
      console.error('Bildirim okundu işaretlenirken hata:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (notificationId) => {
    if (!notificationId) {
      console.error('Geçersiz bildirim ID\'si');
      return;
    }

    try {
      setLoading(true);
      await axios.delete(`/api/notifications/${notificationId}`);
      
      setNotifications(prevNotifications =>
        prevNotifications.filter(notification => notification._id !== notificationId)
      );
    } catch (error) {
      console.error('Bildirim silinirken hata:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAll = async () => {
    try {
      setLoading(true);
      await axios.delete('/api/notifications');
      setNotifications([]);
    } catch (error) {
      console.error('Tüm bildirimler silinirken hata:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    if (!date) return 'Tarih yok';
    try {
      const parsedDate = new Date(date);
      if (isNaN(parsedDate.getTime())) {
        return 'Geçersiz tarih';
      }
      return format(parsedDate, 'dd MMMM yyyy HH:mm', { locale: tr });
    } catch (error) {
      console.error('Tarih biçimlendirme hatası:', error);
      return 'Geçersiz tarih';
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto', p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h5" component="h1">
          Bildirimler
        </Typography>
        <Box>
          <Button
            startIcon={<DeleteIcon />}
            onClick={handleDeleteAll}
            disabled={loading || notifications.length === 0}
            color="error"
          >
            Tümünü Sil
          </Button>
        </Box>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {notifications.length === 0 ? (
        <Typography color="textSecondary" align="center">
          Bildirim bulunmuyor
        </Typography>
      ) : (
        <SwipeableList threshold={0.5}>
          {notifications.map((notification) => (
            <SwipeableListItem
              key={notification._id || Math.random()}
              swipeLeft={{
                content: (
                  <Box sx={{ 
                    height: '100%',
                    bgcolor: 'error.main',
                    display: 'flex',
                    alignItems: 'center',
                    px: 2,
                    color: 'white'
                  }}>
                    <DeleteIcon />
                  </Box>
                ),
                action: () => handleDelete(notification._id)
              }}
            >
              <ListItem
                data-notification-id={notification._id}
                sx={{
                  bgcolor: notification.read ? 'transparent' : 'action.hover',
                  transition: 'background-color 0.2s'
                }}
              >
                <ListItemText
                  primary={notification.title}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {notification.message}
                      </Typography>
                      <br />
                      <Typography component="span" variant="caption" color="textSecondary">
                        {formatDate(notification.createdAt)}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </SwipeableListItem>
          ))}
        </SwipeableList>
      )}
    </Box>
  );
};

export default Notifications;
