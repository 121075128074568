import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkApplicationStatus } from '../store/slices/psychologistApplicationSlice';
import { Helmet } from 'react-helmet-async';

function Dashboard() {
  const dispatch = useDispatch();
  const psychologistApplication = useSelector(state => state.psychologistApplication) || { application: null, loading: false, error: null };
  console.log('Tüm psychologistApplication state:', psychologistApplication);
  const { application, loading, error } = psychologistApplication;
  console.log('Çıkarılan application değeri:', application);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (user) {
      dispatch(checkApplicationStatus());
    }
  }, [dispatch, user]);

  const renderApplicationCard = () => {
    if (loading) {
      return (
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Psikolog Başvurusu</h2>
          <div className="animate-pulse">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
            <div className="space-y-3 mt-4">
              <div className="h-4 bg-gray-200 rounded"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Psikolog Başvurusu</h2>
          <p className="text-red-500">{error}</p>
        </div>
      );
    }

    if (!application) {
      return (
        <div className="p-4 bg-white rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Psikolog Başvurusu</h2>
          <p className="text-gray-600 mb-4">Henüz bir başvurunuz bulunmamaktadır.</p>
          <Link
            to="/psychologist-application"
            className="inline-block bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
          >
            Başvuru Yap
          </Link>
        </div>
      );
    }

    const getStatusColor = (status) => {
      switch (status) {
        case 'approved':
          return 'text-green-600';
        case 'rejected':
          return 'text-red-600';
        default:
          return 'text-yellow-600';
      }
    };

    const getStatusText = (status) => {
      switch (status) {
        case 'approved':
          return 'Onaylandı';
        case 'rejected':
          return 'Reddedildi';
        case 'pending':
          return 'İnceleniyor';
        default:
          return 'Bilinmiyor';
      }
    };

    return (
      <div className="p-4 bg-white rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Psikolog Başvurusu</h2>
        <div className="space-y-4">
          <div>
            <span className="font-medium">Durum: </span>
            <span className={getStatusColor(application.status)}>
              {getStatusText(application.status)}
            </span>
          </div>
          {(application.reviewNotes || application.feedback) && (
            <div>
              <span className="font-medium">Geri Bildirim: </span>
              <p className="mt-1 text-gray-600">
                {application.reviewNotes || application.feedback}
              </p>
            </div>
          )}
          <div>
            <span className="font-medium">Başvuru Tarihi: </span>
            <span className="text-gray-600">
              {new Date(application.createdAt).toLocaleDateString('tr-TR')}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderPsychologistDashboard = () => {
    return (
      <div className="grid gap-6 md:grid-cols-2">
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
          <h3 className="text-xl font-semibold mb-4">Randevu Yönetimi</h3>
          <p className="text-gray-600 mb-4">
            Randevu taleplerinizi görüntüleyin, onaylayın veya reddedin.
          </p>
          <Link
            to="/appointments"
            className="inline-flex items-center justify-center px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
          >
            Randevuları Yönet
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | TerapiyovV5</title>
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-8">Hoş Geldiniz, {user?.name}</h1>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {renderApplicationCard()}
          {renderPsychologistDashboard()}
          {/* Yaklaşan Randevular */}
          <div className="p-4 bg-white rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Yaklaşan Randevular</h2>
              <Link to="/appointments" className="text-blue-600 hover:text-blue-800">
                Tümünü Gör
              </Link>
            </div>
            <div className="flex items-center justify-center h-32 text-gray-500">
              <div className="text-center">
                <i className="far fa-calendar text-4xl mb-2"></i>
                <p>Bugün hiç randevunuz yok</p>
                <p className="text-sm text-gray-400">
                  Yeni randevu almak için takvimi kontrol edin
                </p>
              </div>
            </div>
          </div>

          {/* Son Mesajlar */}
          <div className="p-4 bg-white rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Son Mesajlar</h2>
              <Link to="/messages" className="text-blue-600 hover:text-blue-800">
                Tümünü Gör
              </Link>
            </div>
            <div className="flex items-center justify-center h-32 text-gray-500">
              <div className="text-center">
                <i className="far fa-comments text-4xl mb-2"></i>
                <p>Yeni mesajınız yok</p>
                <p className="text-sm text-gray-400">
                  Mesajlarınızı buradan takip edebilirsiniz
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
