import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axios';
import { useSelector } from 'react-redux';
import { tr } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import NotificationList from '../components/Notifications/NotificationList';
import { useDispatch } from 'react-redux';
import { fetchNotifications } from '../store/slices/notificationSlice';
import { format, addDays, isAfter } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  fetchPsychologistAppointments,
  updateAppointmentStatusByPsychologist,
  clearError,
  clearSuccess,
  startSession
} from '../store/slices/appointmentSlice';

const PsychologistAppointments = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [alternativeDates, setAlternativeDates] = useState(Array(3).fill(null));
  const [showDateSuggestionModal, setShowDateSuggestionModal] = useState(false);
  const { user, token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Kullanıcı giriş yapmamışsa login sayfasına yönlendir
    if (!user || !token) {
      navigate('/login');
      return;
    }

    // Psikolog değilse ana sayfaya yönlendir
    if (user.role !== 'psychologist') {
      navigate('/');
      return;
    }

    const loadData = async () => {
      try {
        await fetchAppointments();
      } catch (error) {
        console.error('Veri yükleme hatası:', error);
      }
    };

    loadData();
  }, [user, token, navigate]);

  const fetchAppointments = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await axiosInstance.get(`/api/appointments/psychologist`);
      console.log('Backend yanıtı:', response.data);

      if (!response.data || response.data.status !== 'success' || !response.data.data) {
        console.error('Geçersiz yanıt formatı:', response.data);
        throw new Error('Randevular alınırken bir hata oluştu');
      }

      const appointmentsData = response.data.data;
      console.log('İşlenecek randevular:', appointmentsData);

      if (!Array.isArray(appointmentsData)) {
        console.error('Geçersiz veri tipi:', typeof appointmentsData);
        throw new Error('Geçersiz randevu verisi');
      }

      // State'i güncelle
      setAppointments(appointmentsData);
      console.log('State güncellendi:', appointmentsData);

    } catch (error) {
      console.error('Randevular alınırken hata:', error);
      setError(error.message || 'Randevular alınırken bir hata oluştu');
      toast.error(error.message || 'Randevular alınırken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (appointmentId, newStatus) => {
    try {
      setLoading(true);
      const response = await axiosInstance.patch(`/api/appointments/${appointmentId}/status`, {
        status: newStatus
      });

      if (response.data.status !== 'success') {
        throw new Error(response.data.message || 'Randevu durumu güncellenirken bir hata oluştu');
      }

      // Randevuları yeniden yükle
      await fetchAppointments();
      toast.success('Randevu durumu güncellendi');
    } catch (error) {
      console.error('Randevu durumu güncellenirken hata:', error);
      toast.error(error.message || 'Randevu durumu güncellenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const CustomDatePicker = ({ index, selected, onChange }) => {
    return (
      <DatePicker
        selected={selected}
        onChange={onChange}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={30}
        dateFormat="dd MMMM yyyy HH:mm"
        locale={tr}
        minDate={new Date()}
        placeholderText="Tarih ve saat seçin"
        className="form-control"
        calendarClassName="custom-datepicker"
        timeCaption="Saat"
        shouldCloseOnSelect={false}
      />
    );
  };

  const handleSuggestDates = async (appointmentId) => {
    try {
      // Tarihlerin dolu olup olmadığını kontrol et
      if (alternativeDates.some(date => !date)) {
        toast.error('Lütfen tüm tarihleri doldurun');
        return;
      }

      // Tarihleri kontrol et
      const now = new Date();
      const invalidDates = alternativeDates.filter(date => date < now);
      if (invalidDates.length > 0) {
        toast.error('Lütfen gelecek tarihler seçin');
        return;
      }

      // Tarihleri sırala ve formatla
      const sortedDates = [...alternativeDates].sort((a, b) => a - b);
      
      const response = await axiosInstance.post(`/api/appointments/${appointmentId}/suggest-dates`, {
        alternativeDates: sortedDates.map(date => date.toISOString())
      });

      if (response.data.status === 'success') {
        await fetchAppointments();
        setShowDatePicker(false);
        setSelectedAppointment(null);
        setAlternativeDates(Array(3).fill(null));
        toast.success('Alternatif tarihler danışana iletildi');
      }
    } catch (error) {
      console.error('Alternatif tarihler gönderilirken hata:', error);
      toast.error(error.response?.data?.message || 'Alternatif tarihler gönderilirken bir hata oluştu');
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'confirmed':
        return 'bg-green-100 text-green-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      case 'completed':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'pending':
        return 'Beklemede';
      case 'confirmed':
        return 'Onaylandı';
      case 'cancelled':
        return 'İptal Edildi';
      case 'completed':
        return 'Tamamlandı';
      default:
        return status;
    }
  };

  const handleAcceptAlternativeDate = async (appointmentId, date) => {
    try {
      setLoading(true);
      const response = await axiosInstance.put(`/api/appointments/${appointmentId}/accept-alternative`, {
        date: date // date zaten ISO string formatında
      });

      if (response.data.status !== 'success') {
        throw new Error(response.data.message || 'Alternatif tarih kabul edilirken bir hata oluştu');
      }

      await fetchAppointments();
      toast.success('Alternatif tarih kabul edildi');
    } catch (error) {
      console.error('Alternatif tarih kabul edilirken hata:', error);
      toast.error(error.response?.data?.message || 'Alternatif tarih kabul edilirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleAppointmentAction = async (appointmentId, status) => {
    try {
      await dispatch(updateAppointmentStatusByPsychologist({ 
        appointmentId, 
        status 
      })).unwrap();
      
      toast.success('Randevu durumu başarıyla güncellendi');
    } catch (error) {
      console.error('Randevu işlemi hatası:', error);
      toast.error(error || 'Randevu durumu güncellenirken bir hata oluştu');
    }
  };

  const renderAppointmentActions = (appointment) => {
    if (appointment.status === 'pending') {
      return (
        <div className="flex space-x-2">
          <button
            onClick={() => handleAppointmentAction(appointment._id, 'confirmed')}
            className="px-3 py-1 text-sm bg-green-500 text-white rounded hover:bg-green-600"
          >
            Onayla
          </button>
          <button
            onClick={() => handleAppointmentAction(appointment._id, 'cancelled')}
            className="px-3 py-1 text-sm bg-red-500 text-white rounded hover:bg-red-600"
          >
            Reddet
          </button>
          <button
            onClick={() => {
              setSelectedAppointment(appointment);
              setShowDateSuggestionModal(true);
            }}
            className="px-3 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Alternatif Tarih Öner
          </button>
        </div>
      );
    }
    return null;
  };

  // Seansı başlatma butonunun görünürlüğünü kontrol et
  const canStartSession = (appointment) => {
    if (appointment.status !== 'confirmed') {
      return false;
    }

    const appointmentDateTime = new Date(appointment.date);
    const [hours, minutes] = appointment.time.split(':');
    appointmentDateTime.setHours(parseInt(hours));
    appointmentDateTime.setMinutes(parseInt(minutes));

    const now = new Date();
    const fiveMinutesBefore = new Date(appointmentDateTime.getTime() - 5 * 60000);
    const appointmentEndTime = new Date(appointmentDateTime.getTime() + 50 * 60000); // 50 dakika sonra

    return now >= fiveMinutesBefore && now <= appointmentEndTime;
  };

  // Buton metnini belirle
  const getSessionButtonText = (appointment) => {
    if (appointment.sessionStarted) {
      return 'Seansa Devam Et';
    }
    return 'Seansı Başlat';
  };

  // Seansı başlat
  const handleStartSession = async (appointmentId) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(`/api/appointments/${appointmentId}/start-session`);
      
      // Başarılı yanıt kontrolü düzeltildi
      if (response.data && response.data.success) {
        toast.success('Görüşme başlatıldı');
        // Video görüşme sayfasına yönlendir
        navigate(`/video-call/${appointmentId}`);
      } else {
        throw new Error(response.data?.message || 'Görüşme başlatılırken bir hata oluştu');
      }
    } catch (error) {
      console.error('Görüşme başlatma hatası:', error);
      toast.error(error.message || 'Görüşme başlatılırken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  // Alternatif tarih önerme modalı
  const DateSuggestionModal = ({ isOpen, onClose, onSubmit }) => {
    const [dates, setDates] = useState(['', '', '']);
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
      e.preventDefault();
      
      // Tarihleri kontrol et
      if (dates.some(date => !date)) {
        setError('Lütfen 3 tarih de seçin');
        return;
      }

      // Geçmiş tarih kontrolü
      const now = new Date();
      const hasInvalidDate = dates.some(date => new Date(date) < now);
      if (hasInvalidDate) {
        setError('Geçmiş tarih seçilemez');
        return;
      }

      // Tarihleri ISO string formatına çevir
      const formattedDates = dates.map(date => new Date(date).toISOString());
      
      onSubmit(formattedDates);
      onClose();
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <h2 className="text-xl font-semibold mb-4">Alternatif Tarih Öner</h2>
          <form onSubmit={handleSubmit}>
            {dates.map((date, index) => (
              <div key={index} className="mb-4">
                <label className="block text-sm font-medium mb-1">
                  {index + 1}. Alternatif Tarih
                </label>
                <input
                  type="datetime-local"
                  value={date}
                  onChange={(e) => {
                    const newDates = [...dates];
                    newDates[index] = e.target.value;
                    setDates(newDates);
                  }}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
            ))}
            {error && (
              <p className="text-red-500 text-sm mb-4">{error}</p>
            )}
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-600 bg-gray-100 rounded hover:bg-gray-200"
              >
                İptal
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
              >
                Öner
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const formatDate = (dateString) => {
    try {
      if (!dateString) return 'Tarih belirtilmemiş';
      const date = new Date(dateString);
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Istanbul'
      };
      return date.toLocaleString('tr-TR', options);
    } catch (error) {
      console.error('Tarih formatlanırken hata:', error);
      return 'Geçersiz tarih';
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Sol taraf: Randevular */}
        <div className="lg:col-span-2">
          <h1 className="text-3xl font-semibold mb-8">Randevularım</h1>
          
          {loading ? (
            <div className="text-center py-8">
              <p className="text-gray-600">Randevular yükleniyor...</p>
            </div>
          ) : error ? (
            <div className="text-center py-8">
              <p className="text-red-600">{error}</p>
              <button 
                onClick={fetchAppointments}
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
              >
                Tekrar Dene
              </button>
            </div>
          ) : !appointments ? (
            <div className="text-center py-8">
              <p className="text-red-600">Randevu verisi yüklenemedi</p>
              <button 
                onClick={fetchAppointments}
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
              >
                Tekrar Dene
              </button>
            </div>
          ) : appointments.length === 0 ? (
            <div className="text-center py-8">
              <p className="text-gray-600">Henüz randevunuz bulunmuyor.</p>
            </div>
          ) : (
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-2">
              {appointments.map((appointment) => {
                if (!appointment || !appointment._id) {
                  console.warn('Geçersiz randevu verisi:', appointment);
                  return null;
                }

                return (
                  <div
                    key={appointment._id}
                    className="bg-white rounded-lg shadow-md p-6 border border-gray-200"
                  >
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="text-lg font-semibold">
                          {appointment?.user?.name || 'İsimsiz Danışan'}
                        </h3>
                        <p className="text-gray-600">{appointment?.type || 'Belirtilmemiş'}</p>
                      </div>
                      <span className={`px-3 py-1 rounded-full text-sm ${getStatusBadgeClass(appointment?.status)}`}>
                        {getStatusText(appointment?.status)}
                      </span>
                    </div>

                    <div className="mb-4">
                      <p className="text-gray-700">
                        <span className="font-medium">Tarih:</span>{' '}
                        {formatDate(appointment?.date)}
                      </p>
                      <p className="text-gray-700">
                        <span className="font-medium">Saat:</span>{' '}
                        {appointment?.time || 'Saat belirtilmemiş'}
                      </p>
                    </div>

                    {/* Alternatif Tarihler */}
                    {appointment?.alternativeDates && appointment.alternativeDates.length > 0 && (
                      <div className="mb-4 p-3 bg-gray-50 rounded-lg">
                        <h4 className="font-medium text-gray-900 mb-2">Önerilen Tarihler:</h4>
                        <div className="space-y-2">
                          {appointment.alternativeDates.map((date, index) => (
                            <div key={index} className="flex justify-between items-center">
                              <span className="text-gray-700">
                                {formatDate(date)}
                              </span>
                              <span className="text-gray-600">
                                {format(new Date(date), 'HH:mm', { locale: tr })}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Randevu Durumu Butonları */}
                    {renderAppointmentActions(appointment)}
                    {canStartSession(appointment) && (
                      <button
                        className={`px-3 py-1 text-sm ${
                          appointment.sessionStarted 
                            ? 'bg-green-500 hover:bg-green-600' 
                            : 'bg-blue-500 hover:bg-blue-600'
                        } text-white rounded`}
                        onClick={() => handleStartSession(appointment._id)}
                      >
                        {getSessionButtonText(appointment)}
                      </button>
                    )}
                    
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/* Sağ taraf: Bildirimler */}
        <div>
          <h2 className="text-2xl font-bold mb-6">Bildirimler</h2>
          <NotificationList />
        </div>
      </div>

      {/* Alternatif Tarih Seçme Modal */}
      {showDateSuggestionModal && selectedAppointment && (
        <DateSuggestionModal
          isOpen={showDateSuggestionModal}
          onClose={() => setShowDateSuggestionModal(false)}
          onSubmit={(dates) => handleSuggestDates(selectedAppointment._id)}
        />
      )}
    </div>
  );
};

export default PsychologistAppointments;
