import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdStar, MdSchool, MdArrowForward } from 'react-icons/md';

const defaultAvatar = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgdmlld0JveD0iMCAwIDIwMCAyMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGNpcmNsZSBjeD0iMTAwIiBjeT0iMTAwIiByPSIxMDAiIGZpbGw9IiNFMkU4RjAiLz4KICA8Y2lyY2xlIGN4PSIxMDAiIGN5PSI4NSIgcj0iMzUiIGZpbGw9IiM5NEEzQjgiLz4KICA8cGF0aCBkPSJNMTAwIDE0MEMxMzYuMDM1IDE0MCAxNjUgMTUxLjQ2IDE2NSAxNjVDMTY1IDE3OC41NCAyNSAxNzguNTQgMjUgMTY1QzI1IDE1MS40NiA2My45NjQ1IDE0MCAxMDAgMTQwWiIgZmlsbD0iIzk0QTNCOCIvPgo8L3N2Zz4=';

const PsychologistCard = memo(({ psychologist }) => {
  const navigate = useNavigate();

  if (!psychologist || !psychologist.user) {
    console.error('Geçersiz psikolog verisi:', psychologist);
    return null;
  }

  const {
    _id,
    user,
    specialties = [],
    education = [],
    experience = [],
    hourlyRate = 0,
    rating = 0,
    sessionCount = 0,
    about,
    title
  } = psychologist;

  // En son eğitim bilgisi
  const latestEducation = education?.length > 0 
    ? [...education].sort((a, b) => b.year - a.year)[0]
    : null;

  // En son deneyim bilgisi
  const latestExperience = experience?.length > 0
    ? [...experience].sort((a, b) => new Date(b.startDate) - new Date(a.startDate))[0]
    : null;

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Psikolog detayına yönlendiriliyor:', _id);
    navigate(`/psychologists/${_id}`);
  }, [_id, navigate]);

  const getProfileImageUrl = (imagePath) => {
    if (!imagePath) return '/default-avatar.png';
    const baseUrl = process.env.REACT_APP_API_URL || window.location.origin;
    return `${baseUrl}/api/uploads/${imagePath}`;
  };

  return (
    <div 
      onClick={handleClick}
      className="block bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 group relative cursor-pointer"
    >
      <div className="absolute top-4 right-4">
        <div className="bg-primary-50 text-primary-600 px-3 py-1.5 rounded-full text-sm font-medium">
          {hourlyRate > 0 ? `${hourlyRate}₺` : 'Ücretsiz'}<span className="text-xs font-normal">/sa</span>
        </div>
      </div>

      <div className="p-4">
        {/* Üst Kısım - Profil Bilgileri */}
        <div className="flex items-center gap-4">
          <div className="relative">
            <img
              src={getProfileImageUrl(user?.profileImage)|| defaultAvatar}
              alt={user.name}
              className="w-20 h-20 rounded-full object-cover border-4 border-gray-100 group-hover:border-primary-50 transition-colors"
            />
          </div>
          <div className="flex-1">
            <h3 className="text-lg font-semibold text-gray-900 group-hover:text-primary-600 transition-colors">
              {user.name}
            </h3>
            {title && (
              <p className="text-sm text-gray-600">{title}</p>
            )}
            <div className="flex items-center gap-2 mt-1">
              <div className="flex items-center text-amber-400">
                <MdStar className="text-lg" />
                <span className="text-sm font-medium ml-0.5">{rating > 0 ? rating.toFixed(1) : '0.0'}</span>
              </div>
              <span className="text-sm text-gray-500">
                {sessionCount > 0 ? `(${sessionCount} seans)` : '(Yeni)'}
              </span>
            </div>
          </div>
        </div>

        {/* Orta Kısım - Uzmanlık Alanları */}
        {specialties?.length > 0 && (
          <div className="mt-4">
            <div className="flex flex-wrap gap-2">
              {specialties.slice(0, 3).map((specialty, index) => (
                <span
                  key={specialty._id || index}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-sage-50 text-sage-700"
                >
                  {specialty.name}
                </span>
              ))}
            </div>
          </div>
        )}

        {/* Alt Kısım - Eğitim ve Deneyim */}
        <div className="mt-4 space-y-2">
          {latestEducation && (
            <div className="flex items-start gap-2">
              <MdSchool className="text-lg text-gray-400 mt-0.5" />
              <div className="text-sm">
                <p className="text-gray-900">{latestEducation.institution}</p>
                <p className="text-gray-500">{latestEducation.field}</p>
              </div>
            </div>
          )}
          {latestExperience && (
            <div className="text-sm text-gray-600 line-clamp-2">
              {latestExperience.title} - {latestExperience.company}
            </div>
          )}
        </div>

        {/* Hakkında */}
        {about && (
          <div className="mt-4">
            <p className="text-sm text-gray-600 line-clamp-2">{about}</p>
          </div>
        )}
      </div>
    </div>
  );
});

PsychologistCard.displayName = 'PsychologistCard';

export default PsychologistCard;
