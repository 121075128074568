import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  ListItem,
  Divider,
  Chip,
  Alert,
  CircularProgress,
  useTheme
} from '@mui/material';
import { fetchDashboardStats } from '../../store/slices/adminSlice';
import moment from 'moment';
import 'moment/locale/tr';
moment.locale('tr');

const StatCard = ({ label, value, helpText }) => {
  const theme = useTheme();
  
  return (
    <Card>
      <CardContent>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          {label}
        </Typography>
        <Typography variant="h4" component="div">
          {value}
        </Typography>
        {helpText && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {helpText}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { dashboardStats, loading, error } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(fetchDashboardStats());
  }, [dispatch]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'approved':
        return 'success';
      case 'rejected':
        return 'error';
      default:
        return 'default';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'pending':
        return 'Beklemede';
      case 'approved':
        return 'Onaylandı';
      case 'rejected':
        return 'Reddedildi';
      default:
        return 'Bilinmiyor';
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        {error}
      </Alert>
    );
  }

  if (!dashboardStats) {
    return (
      <Alert severity="warning">
        İstatistikler yüklenemedi
      </Alert>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Admin Paneli
      </Typography>

      <Grid container spacing={3}>
        {/* İstatistik Kartları */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            label="Toplam Kullanıcı"
            value={dashboardStats.totalUsers || 0}
            helpText={
              <Chip
                label={`Aktif: ${dashboardStats.activeUsers || 0}`}
                color="success"
                size="small"
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            label="Toplam Psikolog"
            value={dashboardStats.totalPsychologists || 0}
            helpText={
              <Chip
                label={`Aktif: ${dashboardStats.activePsychologists || 0}`}
                color="success"
                size="small"
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            label="Bekleyen Başvurular"
            value={dashboardStats.pendingApplications || 0}
            helpText={
              <Typography variant="body2" color="text.secondary">
                Son 7 gün: {dashboardStats.recentApplications || 0}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            label="Toplam Randevu"
            value={dashboardStats.totalAppointments || 0}
            helpText={
              <Typography variant="body2" color="text.secondary">
                Bugün: {dashboardStats.todayAppointments || 0}
              </Typography>
            }
          />
        </Grid>

        {/* Sunucu Bilgileri */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Sunucu Bilgileri" />
            <Divider />
            <CardContent>
              <List>
                <ListItem>
                  <Typography variant="subtitle2" fontWeight="bold">
                    İşletim Sistemi:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {dashboardStats.serverInfo?.os || 'Bilinmiyor'}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Node.js Sürümü:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {dashboardStats.serverInfo?.nodeVersion || 'Bilinmiyor'}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="subtitle2" fontWeight="bold">
                    CPU Kullanımı:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {dashboardStats.serverInfo?.cpuUsage
                      ? `${Math.round(dashboardStats.serverInfo.cpuUsage)}%`
                      : 'Bilinmiyor'}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Bellek Kullanımı:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {dashboardStats.serverInfo?.memoryUsage
                      ? `${Math.round(
                          (dashboardStats.serverInfo.memoryUsage.used /
                            dashboardStats.serverInfo.memoryUsage.total) *
                            100
                        )}% (${Math.round(
                          dashboardStats.serverInfo.memoryUsage.used / 1024 / 1024
                        )} MB / ${Math.round(
                          dashboardStats.serverInfo.memoryUsage.total / 1024 / 1024
                        )} MB)`
                      : 'Bilinmiyor'}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Çalışma Süresi:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {dashboardStats.serverInfo?.uptime
                      ? moment
                          .duration(dashboardStats.serverInfo.uptime, 'seconds')
                          .humanize()
                      : 'Bilinmiyor'}
                  </Typography>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Son Aktiviteler */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Son Aktiviteler" />
            <Divider />
            <CardContent>
              <List>
                {dashboardStats.recentActivities?.map((activity, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <Typography variant="subtitle2" fontWeight="bold">
                        {activity.type}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {moment(activity.date).fromNow()} - {activity.description}
                      </Typography>
                    </ListItem>
                    {index < dashboardStats.recentActivities.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
