import React, { forwardRef, useImperativeHandle } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = forwardRef(({ value, onChange, modules, className = '' }, ref) => {
  const quillRef = React.useRef();

  useImperativeHandle(ref, () => ({
    getEditor: () => quillRef.current?.getEditor(),
    focus: () => quillRef.current?.focus(),
  }));

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      value={value}
      onChange={onChange}
      modules={modules}
      className={`${className} bg-white`}
    />
  );
});

Editor.displayName = 'Editor';

export default Editor;
