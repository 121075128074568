import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './slices/authSlice';
import psychologistReducer from './slices/psychologistSlice';
import psychologistApplicationReducer from './slices/psychologistApplicationSlice';
import appointmentReducer from './slices/appointmentSlice';
import specialtyReducer from './slices/specialtySlice';
import notificationReducer from './slices/notificationSlice';
import contentReducer from './slices/contentSlice';
import adminReducer from './slices/adminSlice';

// Auth persist config
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'isAuthenticated', 'user']
};

const psychologistApplicationPersistConfig = {
  key: 'psychologistApplication',
  storage,
  whitelist: ['application']
};

// Create store
const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    psychologist: psychologistReducer,
    psychologistApplication: persistReducer(psychologistApplicationPersistConfig, psychologistApplicationReducer),
    appointments: appointmentReducer,
    specialty: specialtyReducer,
    notifications: notificationReducer,
    content: contentReducer,
    admin: adminReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: process.env.NODE_ENV !== 'production'
});

// Store değişikliklerini izle
store.subscribe(() => {
  const state = store.getState();
  // Gerekli durumlarda state değişikliklerini loglayabilirsiniz
  // console.log('State değişti:', state);
});

// Create persistor
export const persistor = persistStore(store);

export default store;
