import React, { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useChat } from '../contexts/ChatContext';
import ChatContainer from '../components/chat/ChatContainer';

const MessagesPage = () => {
  const { isAuthenticated } = useSelector(state => state.auth);
  const { conversations, setActiveConversation } = useChat();
  const [searchParams] = useSearchParams();
  const conversationId = searchParams.get('conversation');

  useEffect(() => {
    if (conversationId && conversations.length > 0) {
      const conversation = conversations.find(c => c._id === conversationId);
      if (conversation) {
        setActiveConversation(conversation);
      }
    }
  }, [conversationId, conversations, setActiveConversation]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <ChatContainer />;
};

export default MessagesPage;