// API yapılandırması
export const API_URL = import.meta.env.VITE_API_URL || 'https://terapiyo.com:5000';
export const API_TIMEOUT = 10000;

// Socket.IO yapılandırması
export const SOCKET_URL = 'https://terapiyo.com:5000';
export const SOCKET_OPTIONS = {
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 10000,
  autoConnect: true,
  transports: ['websocket', 'polling']
};

// Auth yapılandırması
export const TOKEN_KEY = 'token';
export const TOKEN_PREFIX = 'Bearer';
export const AUTH_HEADER = 'Authorization';

// Route yapılandırması
export const PUBLIC_ROUTES = ['/login', '/register', '/forgot-password', '/reset-password'];
export const DEFAULT_REDIRECT = '/dashboard';
export const LOGIN_REDIRECT = '/login';

// Uygulama yapılandırması
export const APP_NAME = 'Terapiyo';
export const APP_VERSION = '1.0.0';
export const IS_DEVELOPMENT = import.meta.env.MODE === 'development';
export const IS_PRODUCTION = import.meta.env.MODE === 'production';
