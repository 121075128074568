import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import axios from '../utils/axios';
import { useSelector } from 'react-redux';

const ApplicationSuccess = () => {
  const [applicationStatus, setApplicationStatus] = useState(null);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const checkApplicationStatus = async () => {
      try {
        const response = await axios.get(`/api/psychologist-applications/my-application`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        if (response.data && response.data.data) {
          setApplicationStatus(response.data.data.application);
        }
      } catch (error) {
        console.error('Başvuru durumu kontrol edilirken hata:', error);
      }
    };

    if (token) {
      checkApplicationStatus();
    }
  }, [token]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full space-y-8 bg-white p-8 rounded-2xl shadow-xl"
      >
        <div className="text-center">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ 
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: 0.2
            }}
            className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100"
          >
            <CheckCircleIcon className="h-10 w-10 text-green-600" />
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="mt-6 text-3xl font-extrabold text-gray-900"
          >
            Başvurunuz Alındı!
          </motion.h2>
          
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="mt-4 text-lg text-gray-600"
          >
            {applicationStatus ? (
              <>
                Başvurunuzun durumu: <span className="font-semibold">{
                  applicationStatus.status === 'pending' ? 'Değerlendiriliyor' :
                  applicationStatus.status === 'approved' ? 'Onaylandı' :
                  applicationStatus.status === 'rejected' ? 'Reddedildi' :
                  'Beklemede'
                }</span>
                <br />
                <span className="text-sm text-gray-500 mt-2 block">
                  {applicationStatus.status === 'pending' && 'En kısa sürede sizinle iletişime geçeceğiz.'}
                  {applicationStatus.status === 'approved' && 'Tebrikler! Artık ekibimizin bir parçasısınız.'}
                  {applicationStatus.status === 'rejected' && applicationStatus.rejectionReason}
                </span>
              </>
            ) : (
              'Başvurunuz başarıyla alındı ve değerlendirme sürecine alındı. En kısa sürede sizinle iletişime geçeceğiz.'
            )}
          </motion.p>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="mt-8 flex flex-col space-y-4"
        >
          <Link
            to="/dashboard"
            className="w-full flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
          >
            Ana Sayfaya Dön
          </Link>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ApplicationSuccess;
