import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useChat } from '../../contexts/ChatContext';
import ConversationList from './ConversationList';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { Paper, Grid, Box, Typography, Button, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemAvatar, ListItemText, Avatar, TextField, Alert, CircularProgress, Chip, Stack, useTheme, useMediaQuery, IconButton, Drawer } from '@mui/material';
import { Add as AddIcon, Star as StarIcon, Language as LanguageIcon, LocationOn as LocationIcon, Menu as MenuIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import axiosInstance from '../../utils/axios';

const ChatContainer = () => {
  const { activeConversation, startConversation } = useChat();
  const { user } = useSelector((state) => state.auth);
  const [openNewChat, setOpenNewChat] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [allPsychologists, setAllPsychologists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const searchInputRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getProfileImageUrl = (path) => {
    if (!path) return "/default-avatar.png";
    const baseUrl = process.env.REACT_APP_API_URL || window.location.origin;
    console.log("API URL Image Path:", `${baseUrl}/api/uploads/${path}`);
    return `${baseUrl}/api/uploads/${path}`;
  };

  // Fetch psychologists only when dialog is opened
  useEffect(() => {
    const fetchPsychologists = async () => {
      if (!openNewChat) return;
      
      setLoading(true);
      setError(null);

      try {
        const response = await axiosInstance.get('/api/psychologists/search');
        if (!response.data?.data?.length) {
          setError('Şu anda müsait psikolog bulunmamaktadır.');
          setAllPsychologists([]);
          return;
        }
        // Only filter by isActive for now
        const activePsychologists = response.data.data.filter(psych => 
          psych._id && // Has valid ID
          psych.isActive // Is active
        );
        if (!activePsychologists.length) {
          setError('Şu anda aktif psikolog bulunmamaktadır.');
          setAllPsychologists([]);
          return;
        }
        setAllPsychologists(activePsychologists);
      } catch (error) {
        console.error('Psikologlar yüklenirken hata:', error);
        setError(error.response?.data?.message || 'Psikologlar yüklenirken bir hata oluştu. Lütfen tekrar deneyin.');
        setAllPsychologists([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPsychologists();
  }, [openNewChat]);

  // Client-side arama - tüm ilgili alanlarda ara
  const filteredPsychologists = searchTerm.trim() 
    ? allPsychologists.filter(psych => {
        const searchLower = searchTerm.toLowerCase();
        return (
          psych.name?.toLowerCase().includes(searchLower) ||
          psych.bio?.toLowerCase().includes(searchLower) ||
          psych.expertise?.toLowerCase().includes(searchLower) ||
          psych.about?.toLowerCase().includes(searchLower) ||
          psych.languages?.some(lang => lang.toLowerCase().includes(searchLower)) ||
          psych.specialties?.some(spec => spec.toLowerCase().includes(searchLower))
        );
      })
    : allPsychologists;

  // Search input değişikliği
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Dialog açıldığında input'a fokuslan
  useEffect(() => {
    if (openNewChat && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [openNewChat]);

  // Yeni konuşma başlat
  const handleStartConversation = async (psychologistId) => {
    try {
      // Validate psychologist exists in our list first
      const psychologist = allPsychologists.find(p => p._id === psychologistId);
      if (!psychologist) {
        setError('Seçilen psikolog artık müsait değil. Lütfen sayfayı yenileyip tekrar deneyin.');
        return;
      }

      setError(null);
      setLoading(true);
      await startConversation(psychologistId);
      handleCloseDialog();
    } catch (error) {
      console.error('Conversation start error:', error);
      if (error.response?.status === 404) {
        setError('Seçilen psikolog sistemde bulunamadı. Lütfen sayfayı yenileyip tekrar deneyin.');
      } else if (error.response?.status === 403) {
        setError(error.response?.data?.message || 'Bu psikologla şu anda konuşma başlatılamıyor.');
      } else {
        setError(error.response?.data?.message || 'Konuşma başlatılırken bir hata oluştu. Lütfen tekrar deneyin.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Dialog'u kapat
  const handleCloseDialog = () => {
    setOpenNewChat(false);
    setSearchTerm('');
    setError(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const conversationsList = (
    <>
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Button
          fullWidth
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenNewChat(true)}
          sx={{ mb: 1 }}
        >
          Yeni Konuşma Başlat
        </Button>
      </Box>
      <ConversationList onConversationSelect={() => isMobile && setMobileOpen(false)} />
    </>
  );

  return (
    <Paper sx={{ height: 'calc(100vh - 80px)', display: 'flex', m: { xs: 1, sm: 2 }, overflow: 'hidden' }}>
      <Grid container sx={{ height: '100%' }}>
        {isMobile ? (
          <>
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              sx={{
                '& .MuiDrawer-paper': { 
                  width: '80%', 
                  maxWidth: 320,
                  height: '100%'
                },
              }}
            >
              {conversationsList}
            </Drawer>
            <Grid item xs={12} sx={{ height: '100%' }}>
              {activeConversation ? (
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton edge="start" onClick={handleDrawerToggle}>
                      <MenuIcon />
                    </IconButton>
                    {activeConversation.participants.find(p => p._id !== user._id)?.name}
                  </Box>
                  <MessageList />
                  <MessageInput />
                </Box>
              ) : (
                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <Button
                    startIcon={<MenuIcon />}
                    onClick={handleDrawerToggle}
                    variant="outlined"
                    sx={{ mb: 2 }}
                  >
                    Konuşmaları Göster
                  </Button>
                  <Typography variant="body1" color="textSecondary">
                    Bir konuşma seçin veya yeni bir konuşma başlatın
                  </Typography>
                </Box>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={4} md={3} sx={{ borderRight: 1, borderColor: 'divider', height: '100%', overflow: 'hidden' }}>
              {conversationsList}
            </Grid>
            <Grid item xs={12} sm={8} md={9} sx={{ height: '100%' }}>
              {activeConversation ? (
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <MessageList />
                  <MessageInput />
                </Box>
              ) : (
                <Box sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: 2,
                  p: 3
                }}>
                  <Typography variant="h6" color="textSecondary" align="center">
                    Bir konuşma seçin veya yeni bir konuşma başlatın
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenNewChat(true)}
                  >
                    Yeni Konuşma Başlat
                  </Button>
                </Box>
              )}
            </Grid>
          </>
        )}
      </Grid>

      <Dialog 
        open={openNewChat} 
        onClose={() => setOpenNewChat(false)}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          {isMobile && (
            <IconButton edge="start" onClick={() => setOpenNewChat(false)}>
              <ArrowBackIcon />
            </IconButton>
          )}
          Yeni Konuşma Başlat
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 2 }}>
            <TextField
              inputRef={searchInputRef}
              fullWidth
              placeholder="Psikolog adı, uzmanlık alanı veya dil ile ara..."
              value={searchTerm}
              onChange={handleSearchChange}
              disabled={loading}
              autoFocus
              autoComplete="off"
            />

            {error && (
              <Alert severity="error" onClose={() => setError(null)}>
                {error}
              </Alert>
            )}

            {loading ? (
              <Box sx={{ textAlign: 'center', py: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <List>
                {filteredPsychologists.length === 0 ? (
                  <Typography color="textSecondary" sx={{ p: 2, textAlign: 'center' }}>
                    {searchTerm.trim() !== '' ? 'Sonuç bulunamadı' : 'Psikolog bulunamadı'}
                  </Typography>
                ) : (
                  filteredPsychologists.map((psych) => (
                    <ListItem
                      key={psych._id}
                      button
                      onClick={() => handleStartConversation(psych._id)}
                      sx={{ 
                        flexDirection: 'column', 
                        alignItems: 'flex-start',
                        gap: 1,
                        p: 2 
                      }}
                    >
                      <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
                        <Avatar
                          src={getProfileImageUrl( psych?.profileImage) ||
                            "/default-avatar.png"}
                          sx={{ width: 56, height: 56 }}
                        >
                          {psych.name?.charAt(0)}
                        </Avatar>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            {psych.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {psych.title}
                          </Typography>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <StarIcon sx={{ fontSize: 16, color: 'warning.main' }} />
                            <Typography variant="body2">
                              {psych.rating?.toFixed(1)} ({psych.experience} yıl deneyim)
                            </Typography>
                          </Stack>
                        </Box>
                      </Box>
                      
                      {psych.bio && (
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          {psych.bio}
                        </Typography>
                      )}

                      <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        {psych.specialties?.map((specialty, index) => (
                          <Chip 
                            key={index}
                            label={specialty}
                            size="small"
                            color="primary"
                            variant="outlined"
                          />
                        ))}
                      </Stack>

                      <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <LanguageIcon sx={{ fontSize: 16 }} />
                          <Typography variant="body2">
                            {psych.languages?.join(', ')}
                          </Typography>
                        </Box>
                        {psych.location && (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <LocationIcon sx={{ fontSize: 16 }} />
                            <Typography variant="body2">
                              {psych.location}
                            </Typography>
                          </Box>
                        )}
                      </Stack>
                    </ListItem>
                  ))
                )}
              </List>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default React.memo(ChatContainer);