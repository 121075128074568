import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const location = useLocation();

  console.log('\n=== Private Route Kontrolü ===');
  console.log('İstek yapılan sayfa:', location.pathname);
  console.log('Kullanıcı kimliği doğrulandı mı:', isAuthenticated);
  console.log('Kullanıcı rolü:', user?.role);
  console.log('====================\n');

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
