import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">
            TerapiyoV5: Türkiye'nin En Çok Tercih Edilen Online Terapi Platformu
          </h1>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-bold text-gray-800 mb-4">TerapiyoV5 Online Terapi</h2>
              <div className="flex flex-col md:flex-row gap-8 items-start">
                <div className="md:w-2/3">
                  <p className="text-gray-600 mb-4">
                    TerapiyoV5, <strong>100.000'den fazla danışanın</strong> tercihi olan online terapi ve psikolojik danışmanlık platformudur. 
                    <strong>500'ü aşkın uzman psikoloğuyla</strong> TerapiyoV5; kaygı, stres, mutsuzluk, cinsel sorunlar, depresyon, 
                    ilişki ve iş sorunları ve diğer çeşitli psikolojik sorunları için online psikoterapi hizmeti sağlar.
                  </p>
                  <p className="text-gray-600">
                    TerapiyoV5 yalnızca bilimsel araştırmalarla etkili olduğu kanıtlanmış psikoterapi ekollerinde hizmet sağlar. 
                    Psikologlarımız Bilişsel Davranışçı Terapi (CBT), Dinamik Terapi, Kişilerarası Terapi, Şema Terapi ve diğer 
                    terapi ekollerinde uzmanlık sahibidir.
                  </p>
                </div>
                <div className="md:w-1/3">
                  <img 
                    src="https://placehold.co/600x400/e9d5ff/394867?text=Online+Terapi" 
                    alt="Online Terapi" 
                    className="w-full rounded-lg shadow-md"
                  />
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Neden Online Terapi?</h2>
              <div className="flex flex-col md:flex-row gap-8 items-start">
                <div className="md:w-2/3">
                  <p className="text-gray-600">
                    Çok sayıda bilimsel araştırma, online terapinin yaşam kalitesini artırmada ve anksiyete, 
                    depresyon, stres ve diğer psikolojik sorunların belirtilerini azaltmada <strong>en az yüz yüze terapi kadar etkili</strong> 
                    olduğunu göstermektedir.
                  </p>
                  <p className="text-gray-600 mt-4">
                    Online terapiye kendinizi rahat hissettiğiniz herhangi bir yerden başlayın, trafikte harcadığınız 
                    zamandan ve paradan tasarruf edin.
                  </p>
                </div>
                <div className="md:w-1/3">
                  <img 
                    src="https://placehold.co/600x400/e9d5ff/394867?text=Online+vs+Yüz+yüze" 
                    alt="Online vs Yüz yüze Terapi" 
                    className="w-full rounded-lg shadow-md"
                  />
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Neden TerapiyoV5?</h2>
              <div className="flex flex-col md:flex-row gap-8 items-start">
                <div className="md:w-2/3">
                  <ul className="space-y-4 text-gray-600">
                    <li className="flex items-start gap-2">
                      <span className="text-primary-600 font-bold">•</span>
                      <span>
                        TerapiyoV5'in online terapist ekibi, Türkiye'de denkliği olan <strong>yüksek lisanslarını tamamlamış</strong> 
                        detaylı inceleme süreçlerinden geçmiş uzman psikologlardan oluşur.
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-primary-600 font-bold">•</span>
                      <span>
                        <strong>Gelişmiş algoritmamız</strong> kayıt olurken cevapladığınız sorulardan yola çıkarak size en uygun 
                        terapistleri listeler.
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-primary-600 font-bold">•</span>
                      <span>
                        <strong>15 dakika ücretsiz görüntülü ön görüşme</strong> ile psikoloğunuzu tanıyabilir ve sorularınızı sorabilirsiniz.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="md:w-1/3">
                  <img 
                    src="https://placehold.co/600x400/e9d5ff/394867?text=Neden+TerapiyoV5" 
                    alt="Neden TerapiyoV5" 
                    className="w-full rounded-lg shadow-md"
                  />
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Hizmet Verdiğimiz Online Terapi Türleri</h2>
              <p className="text-gray-600 mb-4">Online terapi seansları yaklaşık olarak 50 dakika sürmektedir.</p>
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600">
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  Çift ve Aile Terapisi
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  Cinsel Terapi
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  Çocuk ve Ergen Terapisi
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  Yetişkin Terapisi
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  EMDR Terapisi
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Online Terapide Çalışılan Psikolojik Sorunlar</h2>
              <p className="text-gray-600 mb-4">
                Aşağıdaki konular bireylerin online terapiye başvurmalarındaki en önemli sorunların bir listesidir. 
                Ancak online terapiye yalnızca psikolojik sorunları çözmek için değil, aynı zamanda daha huzurlu, 
                mutlu ve dengeli bir yaşama varmak için de başlanabilir.
              </p>
              <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 text-gray-600">
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  Anksiyete (Kaygı)
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  Stres
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  Depresyon
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  Cinsellik sorunları
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  İlişki sorunları
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  İş sorunları
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  Sosyal kaygı
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  Takıntılar
                </li>
                <li className="flex items-center gap-2">
                  <span className="text-primary-600 font-bold">•</span>
                  Beden algısı
                </li>
              </ul>
            </section>

            <section className="bg-gradient-to-r from-primary-50 to-primary-100 rounded-xl p-8 mt-12">
              <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                <div className="md:w-2/3">
                  <h2 className="text-2xl font-bold text-gray-800 mb-4">
                    Daha iyi hissetmeye bugün başlayın
                  </h2>
                  <p className="text-gray-600 mb-6">
                    Size en uygun psikologları inceleyin. Uzman psikoloğunuzla uyumunuzu görmek için 
                    15 dakikalık ücretsiz ön görüşme yaparak terapi yolculuğunuza başlayın.
                  </p>
                  <Link
                    to="/register"
                    className="inline-block bg-primary-600 text-white px-8 py-3 rounded-lg hover:bg-primary-700 transition-colors"
                  >
                    Hemen Başlayın
                  </Link>
                </div>
                <div className="md:w-1/3">
                  <img 
                    src="https://placehold.co/300x600/e9d5ff/394867?text=Mobil+Uygulama" 
                    alt="TerapiyoV5 Uygulama" 
                    className="w-full max-w-[250px] mx-auto rounded-lg shadow-md"
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default About;
