import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

// Bildirimleri getir
export const fetchNotifications = createAsyncThunk(
  'notifications/fetch',
  async (_, { rejectWithValue }) => {
    try {
      console.log('\n=== Bildirimler Yükleniyor ===');
      const response = await axiosInstance.get('/api/notifications');
      console.log('API Yanıtı:', response.data);
      console.log('====================\n');
      return response.data;
    } catch (error) {
      console.error('\n=== Bildirim Yükleme Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('====================\n');
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Bildirimi okundu olarak işaretle
export const markNotificationAsRead = createAsyncThunk(
  'notifications/markAsRead',
  async (notificationId, { rejectWithValue }) => {
    try {
      console.log('\n=== Bildirim Okundu İşaretleniyor ===');
      console.log('Bildirim ID:', notificationId);
      const response = await axiosInstance.put(`/api/notifications/${notificationId}/read`);
      console.log('API Yanıtı:', response.data);
      console.log('====================\n');
      return response.data;
    } catch (error) {
      console.error('\n=== Bildirim Güncelleme Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('====================\n');
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

// Tüm bildirimleri okundu olarak işaretle
export const markAllNotificationsAsRead = createAsyncThunk(
  'notifications/markAllAsRead',
  async (_, { rejectWithValue }) => {
    try {
      console.log('\n=== Tüm Bildirimler Okundu İşaretleniyor ===');
      const response = await axiosInstance.put('/api/notifications/read-all');
      console.log('API Yanıtı:', response.data);
      console.log('====================\n');
      return response.data;
    } catch (error) {
      console.error('\n=== Bildirim Güncelleme Hatası ===');
      console.error('Hata:', error.response?.data || error.message);
      console.error('====================\n');
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

const initialState = {
  notifications: [],
  unreadCount: 0,
  loading: false,
  error: null,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notifications.unshift(action.payload);
      if (!action.payload.read) {
        state.unreadCount += 1;
      }
    },
    clearNotifications: (state) => {
      state.notifications = [];
      state.unreadCount = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      // Bildirimleri getir
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
        state.unreadCount = action.payload.filter(n => !n.read).length;
        state.error = null;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Bildirimler yüklenirken bir hata oluştu';
      })

      // Bildirimi okundu olarak işaretle
      .addCase(markNotificationAsRead.fulfilled, (state, action) => {
        const notification = state.notifications.find(n => n._id === action.payload._id);
        if (notification && !notification.read) {
          notification.read = true;
          state.unreadCount -= 1;
        }
      })

      // Tüm bildirimleri okundu olarak işaretle
      .addCase(markAllNotificationsAsRead.fulfilled, (state) => {
        state.notifications.forEach(notification => {
          notification.read = true;
        });
        state.unreadCount = 0;
      });
  }
});

export const { addNotification, clearNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
