import React, { useRef, useEffect } from 'react';
import Draggable from 'react-draggable';
import { Box } from '@mui/material';

const DraggableVideo = ({ stream, isLandscape, isMobile }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  // Calculate initial position based on screen size and orientation
  const getInitialPosition = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    
    return {
      x: screenWidth - (isLandscape ? 280 : 180),
      y: isMobile ? 100 : 120
    };
  };

  return (
    <Draggable
      bounds="parent"
      defaultPosition={getInitialPosition()}
    >
      <Box
        sx={{
          position: 'absolute',
          width: {
            xs: isLandscape ? '180px' : '140px',
            sm: '220px',
            md: '260px'
          },
          height: {
            xs: isLandscape ? '101.25px' : '78.75px',
            sm: '123.75px',
            md: '146.25px'
          },
          borderRadius: '12px',
          overflow: 'hidden',
          bgcolor: '#1a1a1a',
          border: '2px solid rgba(255,255,255,0.2)',
          boxShadow: '0 4px 12px rgba(0,0,0,0.4)',
          touchAction: 'none',
          cursor: 'move',
          zIndex: 1200,
          '&:hover': {
            boxShadow: '0 8px 24px rgba(0,0,0,0.6)',
          },
        }}
      >
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transform: 'scaleX(-1)'
          }}
        />
      </Box>
    </Draggable>
  );
};

export default DraggableVideo;