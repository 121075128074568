import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import {
  fetchUserAppointments,
  fetchPsychologistAppointments,
  cancelAppointment,
  updateAppointment,
  acceptAlternativeDate
} from '../store/slices/appointmentSlice';
import { useNavigate } from 'react-router-dom';

const getStatusBadgeClass = (status) => {
  switch (status) {
    case 'pending': return 'bg-yellow-100 text-yellow-800';
    case 'confirmed': return 'bg-green-100 text-green-800';
    case 'cancelled': return 'bg-red-100 text-red-800';
    case 'completed': return 'bg-blue-100 text-blue-800';
    default: return 'bg-gray-100 text-gray-800';
  }
};

const getStatusText = (status) => {
  switch (status) {
    case 'pending': return 'Beklemede';
    case 'confirmed': return 'Onaylandı';
    case 'cancelled': return 'İptal Edildi';
    case 'completed': return 'Tamamlandı';
    default: return 'Bilinmiyor';
  }
};

const formatDate = (dateString) => {
  try {
    if (!dateString) return 'Tarih belirtilmemiş';
    const date = new Date(dateString);
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Europe/Istanbul'
    };
    return date.toLocaleString('tr-TR', options);
  } catch (error) {
    console.error('Tarih formatlanırken hata:', error);
    return 'Geçersiz tarih';
  }
};

const AppointmentCard = ({ appointment, onCancel, onStatusChange, userRole, onAcceptAlternativeDate }) => {
  const navigate = useNavigate();

  if (!appointment) {
    return null;
  }

  // Görüntülenecek ismi belirle
  const displayName = userRole === 'psychologist' 
    ? appointment.user?.name || 'İsimsiz Danışan'
    : appointment.psychologist?.user?.name || 'İsimsiz Psikolog';

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="bg-white rounded-lg shadow-md p-6 mb-4"
    >
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-semibold">{displayName}</h3>
          <p className="text-gray-600">{formatDate(appointment.date)}</p>
        </div>
        <span className={`px-3 py-1 rounded-full text-sm ${getStatusBadgeClass(appointment.status)}`}>
          {getStatusText(appointment.status)}
        </span>
      </div>
      
      <div className="space-y-2">
        <p className="text-gray-700">
          <span className="font-medium">Notlar:</span> {appointment.notes || 'Not bulunmuyor'}
        </p>
      </div>

      {/* Alternatif Tarihler */}
      {userRole === 'user' && appointment.alternativeDates?.length > 0 && (
        <div className="mt-4 p-4 bg-yellow-50 rounded-lg">
          <h4 className="font-medium text-yellow-800 mb-2">
            Önerilen Alternatif Tarihler
          </h4>
          <div className="space-y-2">
            {appointment.alternativeDates.map((date, index) => (
              <div key={index} className="flex items-center justify-between">
                <span className="text-sm text-gray-600">
                  {formatDate(date)}
                </span>
                <button
                  onClick={() => onAcceptAlternativeDate(appointment._id, date)}
                  className="px-3 py-1 text-sm bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Bu Tarihi Kabul Et
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mt-4 flex justify-end space-x-2">
        {appointment.status !== 'cancelled' && appointment.status !== 'completed' && (
          <button
            onClick={() => onCancel(appointment._id)}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
          >
            İptal Et
          </button>
        )}
        
        {userRole === 'psychologist' && appointment.status === 'pending' && (
          <button
            onClick={() => onStatusChange(appointment._id, 'confirmed')}
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors"
          >
            Onayla
          </button>
        )}
        
        {userRole === 'psychologist' && appointment.status === 'confirmed' && (
          <>
            <button
              onClick={() => onStatusChange(appointment._id, 'completed')}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            >
              Tamamlandı
            </button>
            <button
              onClick={() => navigate(`/video-call/${appointment._id}`)}
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors flex items-center space-x-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zm12.553 1.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
              </svg>
              <span>Görüşmeye Başla</span>
            </button>
          </>
        )}
        
        {userRole === 'user' && appointment.status === 'confirmed' && (
          <button
            onClick={() => {
              const appointmentTime = new Date(appointment.date);
              const now = new Date();
              const fiveMinutesBefore = new Date(appointmentTime.getTime() - 5 * 60000);
              const thirtyMinutesAfter = new Date(appointmentTime.getTime() + 30 * 60000);

              if (now < fiveMinutesBefore) {
                toast.warning('Görüşmeye katılmak için henüz erken. Randevu saatinden 5 dakika önce katılabilirsiniz.');
                return;
              }

              if (now > thirtyMinutesAfter) {
                toast.error('Görüşmeye katılmak için çok geç. Randevu saatinden en fazla 30 dakika sonrasına kadar katılabilirsiniz.');
                return;
              }

              navigate(`/video-call/${appointment._id}`);
            }}
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors flex items-center space-x-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zm12.553 1.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
            </svg>
            <span>Görüşmeye Katıl</span>
          </button>
        )}
      </div>
    </motion.div>
  );
};

const Appointments = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { appointments, loading, error } = useSelector((state) => state.appointments);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    console.log('\n=== Randevular Yükleniyor ===');
    console.log('Kullanıcı rolü:', user?.role);
    
    if (user?.role === 'psychologist') {
      dispatch(fetchPsychologistAppointments());
    } else {
      dispatch(fetchUserAppointments());
    }
  }, [dispatch, user?.role]);

  const handleCancel = async (appointmentId) => {
    try {
      await dispatch(cancelAppointment(appointmentId)).unwrap();
      // Randevuları yenile
      if (user?.role === 'psychologist') {
        dispatch(fetchPsychologistAppointments());
      } else {
        dispatch(fetchUserAppointments());
      }
    } catch (error) {
      console.error('Randevu iptal edilirken hata:', error);
    }
  };

  const handleStatusChange = async (appointmentId, newStatus) => {
    try {
      await dispatch(updateAppointment({ id: appointmentId, appointmentData: { status: newStatus } })).unwrap();
      // Randevuları yenile
      if (user?.role === 'psychologist') {
        dispatch(fetchPsychologistAppointments());
      }
    } catch (error) {
      console.error('Randevu durumu güncellenirken hata:', error);
    }
  };

  const handleAcceptDate = async (appointmentId, selectedDate) => {
    try {
      await dispatch(updateAppointment({ 
        id: appointmentId, 
        appointmentData: { 
          date: selectedDate,
          alternativeDates: [] // Alternatif tarihleri temizle
        } 
      })).unwrap();
      
      // Randevuları yenile
      dispatch(fetchUserAppointments());
    } catch (error) {
      console.error('Alternatif tarih seçilirken hata:', error);
    }
  };

  const handleAcceptAlternativeDate = async (appointmentId, date) => {
    try {
      await dispatch(acceptAlternativeDate({ appointmentId, date })).unwrap();
      toast.success('Alternatif tarih kabul edildi');
      dispatch(fetchUserAppointments());
    } catch (error) {
      console.error('Alternatif tarih kabul hatası:', error);
      toast.error(error?.message || 'Alternatif tarih kabul edilirken bir hata oluştu');
    }
  };

  const renderAlternativeDates = (appointment) => {
    if (appointment.status === 'pending_alternative' && appointment.alternativeDates?.length > 0) {
      return (
        <div className="mt-4 p-4 bg-yellow-50 rounded-lg">
          <h4 className="font-medium text-yellow-800 mb-2">
            Önerilen Alternatif Tarihler
          </h4>
          <div className="space-y-2">
            {appointment.alternativeDates.map((date, index) => (
              <div key={index} className="flex items-center justify-between">
                <span className="text-sm text-gray-600">
                  {formatDate(date)}
                </span>
                <button
                  onClick={() => handleAcceptAlternativeDate(appointment._id, date)}
                  className="px-3 py-1 text-sm bg-green-500 text-white rounded hover:bg-green-600"
                >
                  Bu Tarihi Kabul Et
                </button>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  const filteredAppointments = appointments.filter(appointment => {
    switch (filter) {
      case 'pending':
        return appointment.status === 'pending';
      case 'confirmed':
        return appointment.status === 'confirmed';
      case 'completed':
        return appointment.status === 'completed';
      case 'cancelled':
        return appointment.status === 'cancelled';
      default:
        return true;
    }
  });

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-semibold mb-8">Randevularım</h1>

      {loading ? (
        <div className="text-center py-8">
          <p className="text-gray-600">Randevular yükleniyor...</p>
        </div>
      ) : error ? (
        <div className="text-center py-8">
          <p className="text-red-600">{error}</p>
          <button 
            onClick={() => dispatch(fetchUserAppointments())}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
          >
            Tekrar Dene
          </button>
        </div>
      ) : appointments.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-600">Henüz randevunuz bulunmuyor.</p>
        </div>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {appointments.map((appointment) => (
            <AppointmentCard
              key={appointment._id}
              appointment={appointment}
              onCancel={handleCancel}
              onStatusChange={handleStatusChange}
              onAcceptAlternativeDate={handleAcceptAlternativeDate}
              userRole={user?.role === 'psychologist' ? 'psychologist' : 'user'}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Appointments;
